import { gql, useMutation } from "@apollo/client";
import { forwardRef, memo } from "react";
import { useDialogState } from "swash/Dialog";
import { useLiveRef } from "swash/utils/useLiveRef";

import { Image } from "@/components/icons";
import { useAmplitude } from "@/containers/Amplitude";
import {
  EditorImageFragment,
  ImportImageDialog,
} from "@/containers/routes/live/ImportImageDialog";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { addBlock } from "../modifiers/addBlock";

export const name = "image-control";

export const matchBlock = () => true;

const EditorUpdateImageMutation = gql`
  mutation EditorUpdateImageMutation($input: UpdateImageInput!) {
    updateImage(input: $input) {
      ...EditorImageFragment
    }
  }

  ${EditorImageFragment}
`;

const CreateImageToolbarButton = memo(
  forwardRef(({ onMouseDown, disabled }, ref) => {
    return (
      <RichEditorToolbarButton
        ref={ref}
        onMouseDown={onMouseDown}
        label="Insérer une image"
        disabled={disabled}
      >
        <Image />
      </RichEditorToolbarButton>
    );
  }),
);

const CreateImageControl = memo(({ disabled, refs }) => {
  const dialog = useDialogState();
  const { logEvent } = useAmplitude();
  const [updateImage] = useMutation(EditorUpdateImageMutation);

  const handleSubmit = async (values, form) => {
    logEvent("importImage", {
      source: "live",
      multi: false,
      count: 1,
    });

    await updateImage({
      variables: {
        input: {
          id: values.image.id,
          caption: values.description,
          credit: values.source,
        },
      },
    });

    const { editorState, setEditorState } = refs.current;

    const currentBlockKey = editorState.getSelection().getStartKey();
    const currentBlockIndex = editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq()
      .findIndex((k) => k === currentBlockKey);

    setEditorState(
      addBlock(
        editorState,
        {
          type: "atomic",
        },
        currentBlockIndex + 1,
        {
          type: "IMAGE",
          mutability: "IMMUTABLE",
          data: {
            id: values.image.id,
            media: {
              id: values.image.id,
              url: values.image.url,
              caption: values.image.caption,
            },
          },
        },
      ),
    );
    setTimeout(() => {
      form.reset();
      dialog.hide();
    });
  };
  return (
    <>
      <CreateImageToolbarButton
        onMouseDown={(event) => {
          event.preventDefault();
          dialog.show();
        }}
        disabled={disabled}
      />
      <ImportImageDialog state={dialog} onSubmit={handleSubmit} />
    </>
  );
});

export const BlockControls = ({ hasFocus, editorState, setEditorState }) => {
  const refs = useLiveRef({ editorState, setEditorState });
  const disabled = !hasFocus;
  return <CreateImageControl disabled={disabled} refs={refs} />;
};

BlockControls.group = "media";
