/* eslint-disable react/prop-types */
import { useState } from "react";
import { createTeleporter } from "react-teleporter";
import { Button } from "swash/Button";

import {
  HeaderToolbar as BaseHeaderToolbar,
  HeaderToolbarItem as BaseHeaderToolbarItem,
} from "@/components/Layout";
import { Upload } from "@/components/icons";

const ToolbarTeleporter = createTeleporter({ multiSources: true });

export function HeaderToolbar() {
  return <ToolbarTeleporter.Target as={BaseHeaderToolbar} />;
}

export function HeaderToolbarItem(props) {
  return (
    <ToolbarTeleporter.Source>
      <BaseHeaderToolbarItem {...props} />
    </ToolbarTeleporter.Source>
  );
}

export function ExportToolbarItem({ onExport, disabled }) {
  const [loading, setLoading] = useState(false);

  async function handleExport() {
    setLoading(true);
    await onExport();
    setLoading(false);
  }

  return (
    <HeaderToolbarItem>
      <Button
        type="button"
        disabled={loading || disabled}
        onClick={handleExport}
        scale="sm"
      >
        <Upload />
        {loading ? "Export en cours.." : "Exporter au format CSV"}
      </Button>
    </HeaderToolbarItem>
  );
}
