import { memo, useCallback, useMemo } from "react";
import { Checkbox } from "swash/controls/Checkbox";
import { Radio, RadioGroup, RadioLabel } from "swash/controls/Radio";
import { FormCheckbox } from "swash/form/FormCheckbox";
import { FormLabel } from "swash/form/FormLabel";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { SelectField, useSelectField } from "@/components/fields/SelectField";

export const SelectEnumField = ({
  name,
  required,
  clearable,
  label,
  "aria-label": ariaLabel,
  enum: unsortedItems,
  disabledValues,
  hint,
  placeholder,
  multi,
  scale = "lg",
  labelElementSelector,
  labelSelector = (field) => field.label,
  sortEntries = (itemA, itemB) => itemA.label.localeCompare(itemB.label),
  modal,
  disabled,
  ...others
}) => {
  const emptyValue = useMemo(() => (multi ? [] : null), [multi]);

  const items = useMemo(() => {
    return [...unsortedItems].sort(sortEntries);
  }, [unsortedItems, sortEntries]);

  const format = useCallback(
    (v) => {
      if (multi) {
        return v.map(
          (v) => items.find((item) => item.value === v) || emptyValue,
        );
      }
      return items.find((item) => item.value === v) || emptyValue;
    },
    [multi, items, emptyValue],
  );

  const parse = useCallback(
    (v) => {
      if (multi) {
        return v.map((v) => v.value);
      }
      return v?.value ?? null;
    },
    [multi],
  );

  const field = useSelectField(name, {
    required,
    label: ariaLabel || label,
    format,
    parse,
    ...others,
  });

  const { value, onChange } = field.state.field.input;

  const state = useEnumSelectState({
    title: label,
    value: value && items.length ? value : emptyValue,
    onChange,
    items,
    labelSelector,
    labelElementSelector,
    valueSelector: (field) => field.value?.toString(),
    disabledSelector: (field) =>
      disabledValues?.includes(field.value) ?? disabled,
    required: required || !clearable,
    appearance: multi ? "chip" : "default",
  });

  return (
    <FieldGroup {...field}>
      {label ? <FieldLabel {...field}>{label}</FieldLabel> : null}
      <FieldError {...field} />
      {hint ? (
        <FieldHint {...field} className="pb-1">
          {hint}
        </FieldHint>
      ) : null}
      <EnumSelect
        placeholder={placeholder || `${label}...`}
        aria-label={ariaLabel || label}
        className={others.orientation === "horizontal" ? "flex-1" : null}
        state={state}
        modal={modal}
        disabled={disabled}
        scale={scale === "base" ? "md" : scale}
      />
    </FieldGroup>
  );
};

export function EnumFieldCheckboxControl({
  enum: enumValues,
  sortEntries,
  onBlur,
  onFocus,
  multi,
  onChange,
  name,
  disabled,
  required,
  variant,
  ...props
}) {
  if (multi) {
    return (
      <div className="flex gap-2" data-field-control>
        {Object.entries(enumValues)
          .sort(sortEntries)
          .map(([value, label]) => {
            const id = `${name}-${value}`;
            return (
              <FormCheckbox key={value}>
                <Checkbox
                  id={id}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  checked={props.value?.includes(value) || false}
                  onChange={(event) => {
                    const targetValue = event.currentTarget.value;
                    onChange(
                      props.value
                        ? props.value.includes(targetValue)
                          ? props.value.filter((x) => x !== targetValue)
                          : [...props.value, targetValue]
                        : [targetValue],
                    );
                  }}
                  disabled={disabled}
                  variant={variant}
                />
                <FormLabel htmlFor={id}>{label}</FormLabel>
              </FormCheckbox>
            );
          })}
      </div>
    );
  }
  return (
    <RadioGroup data-field-control>
      {Object.entries(enumValues)
        .sort(sortEntries)
        .map(([value, label]) => (
          <RadioLabel key={value}>
            <Radio
              name={name}
              value={value}
              onBlur={onBlur}
              onFocus={onFocus}
              checked={value === props.value}
              onChange={(event) => {
                onChange(event.currentTarget.value);
              }}
              disabled={disabled}
            />
            {label}
          </RadioLabel>
        ))}
      {!required && (
        <RadioLabel>
          <Radio
            name={name}
            value="toto"
            onBlur={onBlur}
            onFocus={onFocus}
            checked={props.value == null}
            onChange={() => {
              onChange(null);
            }}
          />
          Aucun
        </RadioLabel>
      )}
    </RadioGroup>
  );
}

function CheckboxEnumField({
  name,
  required,
  label,
  "aria-label": ariaLabel,
  enum: enumValues,
  hint,
  renderOption,
  renderChip,
  multi,
  sortEntries,
  appearance,
  disabled,
  ...others
}) {
  const field = useSelectField(name, {
    required,
    label: ariaLabel || label,
    ...others,
  });
  return (
    <FieldGroup {...field}>
      {label ? <FieldLabel {...field}>{label}</FieldLabel> : null}
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <SelectField
        {...field}
        as={EnumFieldCheckboxControl}
        enum={enumValues}
        multi={multi}
        sortEntries={sortEntries}
        disabled={disabled}
      />
    </FieldGroup>
  );
}

export const EnumField = memo(({ appearance = "select", ...others }) => {
  switch (appearance) {
    case "select":
      return <SelectEnumField {...others} />;
    case "checkbox":
      return <CheckboxEnumField {...others} />;
    default:
      return null;
  }
});
