/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { clsx } from "clsx";
import { memo, useLayoutEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { IoCloudDone, IoCloudOffline, IoCloudUpload } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { usePrevious } from "swash/utils/usePrevious";

import { Time } from "@/components/Time";

import { useFormAutoSubmitState } from "./FormAutoSubmit";

const useRecentSaved = (saving) => {
  const [recentSaved, setRecentSaved] = useState(saving);
  const previousSaving = usePrevious(saving);
  const shouldMarkAsRecentSaved = previousSaving === true && saving === false;
  useLayoutEffect(() => {
    if (shouldMarkAsRecentSaved) {
      setRecentSaved(true);
    }
  }, [shouldMarkAsRecentSaved]);
  useLayoutEffect(() => {
    const timeout = setTimeout(() => setRecentSaved(false), 2000);
    return () => clearTimeout(timeout);
  }, [recentSaved]);
  return recentSaved;
};

export const ControlledFormSavingIndicator = memo(
  ({ date, saving, user, className, ...props }) => {
    const form = useForm();
    const { valid } = useFormState({ subscription: { valid: true } });
    const recentSaved = useRecentSaved(saving);
    const invalid = !valid;

    const tooltip = invalid ? (
      "Le formulaire est invalide et ne peut pas être sauvegardé."
    ) : saving ? (
      "Enregistrement en cours..."
    ) : (
      <>
        Enregistré {date && <Time date={date} />}{" "}
        {user && `par ${user.firstNameInitials} ${user.lastName}`}
      </>
    );

    return (
      <div
        className={clsx(
          "text-sm font-normal text-grey-on",
          invalid && "cursor-pointer",
          className,
        )}
        onClick={
          invalid
            ? () => {
                form.submit();
              }
            : null
        }
        {...props}
      >
        <Tooltip tooltip={tooltip}>
          <span
            className="flex items-center transition-all"
            role="status"
            aria-busy={!invalid && saving ? "true" : null}
            aria-label={
              invalid
                ? "Le formulaire est invalide et ne peut pas être sauvegardé."
                : saving
                  ? "Enregistrement en cours..."
                  : "Enregistré"
            }
          >
            {invalid ? (
              <IoCloudOffline className="text-danger-on-light" />
            ) : saving ? (
              <>
                <IoCloudUpload className="mr-1" />
                Enregistrement...
              </>
            ) : (
              <>
                <IoCloudDone />
                {recentSaved && (
                  <span className="ml-1" data-test-hidden="no-display">
                    Enregistré
                  </span>
                )}
              </>
            )}
          </span>
        </Tooltip>
      </div>
    );
  },
);

export const FormSavingIndicator = memo((props) => {
  const { submitting } = useFormAutoSubmitState();
  return <ControlledFormSavingIndicator saving={submitting} {...props} />;
});
