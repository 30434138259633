import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useMemo,
} from "react";

import moment from "../internal/moment";
import {
  Combobox,
  ComboboxItem,
  ComboboxItemCheck,
  ComboboxPopover,
  useComboboxStore,
} from "../v2/Combobox";

// @docs https://gist.github.com/indexzero/6261ad9292c78cf3c5aa69265e2422bf
export const generateSlots = (disablePast: boolean) => {
  const slots = Array.from({ length: 48 }, (_, index) =>
    moment({
      hour: Math.floor(index / 2),
      minutes: index % 2 === 0 ? 0 : 30,
    }).format("HH:mm"),
  );
  if (!disablePast) return slots;
  return slots.filter((slot) => {
    const [hours, minutes] = slot.split(":");
    if (!hours || !minutes) return false;
    return moment().isBefore(
      moment()
        .tz("Europe/Paris")
        .set("hour", parseInt(hours))
        .set("minute", parseInt(minutes)),
    );
  });
};

export const checkIsValidFullTimeValue = (value: string) =>
  value?.length === 8 &&
  /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(value);

export const formatFullTime = (value: string) =>
  value ? value.slice(0, 5) : value;

export const parseFullTime = (value: string) =>
  value?.length === 5 ? `${value}:00` : value;

type TimePickerInputProps = {
  scale?: "sm" | "md" | "lg";
  value: string | null;
  onChange: Dispatch<SetStateAction<string | null>>;
  slots?: string[];
  disablePast?: boolean;
  required?: boolean;
};

export const TimePickerInput = forwardRef<
  HTMLInputElement,
  TimePickerInputProps
>((props, ref) => {
  const {
    scale = "md",
    value,
    onChange,
    slots: slotsProp,
    disablePast = false,
    required,
    ...otherProps
  } = props;
  const slots = useMemo(
    () => slotsProp ?? generateSlots(disablePast),
    [slotsProp, disablePast],
  );

  const combobox = useComboboxStore({
    value: value || "",
    setValue: onChange,
  });

  const comboboxValue = combobox.useState("value");

  useEffect(() => {
    if (comboboxValue && slots.find((slot) => slot === comboboxValue)) {
      combobox.setSelectedValue(comboboxValue);
    }
  }, [comboboxValue, combobox, slots]);

  return (
    <>
      <Combobox
        ref={ref}
        store={combobox}
        scale={scale}
        raw={false}
        required={required}
        showClearButton
        placeholder="HH:mm"
        aria-label="Horaire"
        {...otherProps}
      />
      <ComboboxPopover store={combobox} gutter={8}>
        {slots.map((slot, index) => {
          return (
            <ComboboxItem key={index} value={slot}>
              <ComboboxItemCheck value={slot} store={combobox} />
              {slot}
            </ComboboxItem>
          );
        })}
      </ComboboxPopover>
    </>
  );
});

if (process.env["NODE_ENV"] !== "production") {
  TimePickerInput.displayName = "TimePickerInput";
}
