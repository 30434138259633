import clsx from "clsx";
import React, { forwardRef } from "react";

export type FormLabelProps = React.ComponentProps<"label">;

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(
  ({ className, ...props }, ref) => {
    return (
      <label
        ref={ref}
        className={clsx(
          className,
          "flex-1 font-accent text-base font-semibold",
        )}
        {...props}
      />
    );
  },
);
