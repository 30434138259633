import { gql } from "@apollo/client";
import clsx from "clsx";
import { merge } from "lodash-es";
import { memo, useMemo } from "react";
import { IoArrowForward } from "swash/Icon";

import {
  ActivityItem,
  ActivityTime,
} from "@/containers/admin/CRUD/history/ActivityInfo";
import { UserHoverCardTooltip } from "@/containers/user/UserHoverCard";

/**
 * @typedef {object} FieldInfo
 * @property {string} [label]
 * @property {function} [formatLabel]
 * @property {function} [formatValue]
 */

/**
 * @typedef {import('../').FieldsMap} FieldsMap
 * @typedef {import('../').CRUDTerm} CRUDTerm
 * @typedef {{[key: string]: string}} ResourceLabels
 * @typedef {{_labels: ResourceLabels, [key: string]: AuditTrailValue}} AuditTrailFields
 */

/**
 * @typedef {object} AuditTrailValue
 * @property {any} current
 * @property {any} previous
 * @property {{ added?: object|null, deleted?: object|null, updated?: object|null}} diff
 * @property {string} type
 * @property {boolean} isList
 */

/**
 * @typedef {object} AuditTrail
 * @property {string} globalId
 * @property {user} user
 * @property {string} date
 * @property {"created"|"deleted"|"updated"} action
 * @property {AuditTrailFields} fields
 */

/**
 * Get action label
 * @param {AuditTrailValue} value
 */
const defaultCRUDAction = (value) => {
  const { current, previous } = value;
  if (previous == null && current) return "added";
  if (previous && current == null) return "removed";
  return "updated";
};

/**
 * Get only current or previous value for each modify field
 * @param {AuditTrailFields} auditFields
 * @param {'current' | 'previous'} state
 * @returns
 */
function getAllCurrentOrPreviousAuditFields(auditFields, state) {
  return Object.entries(auditFields).reduce(
    (fields, [key, value]) => ({ ...fields, [key]: value[state] }),
    {},
  );
}

/**
 * Format value
 * @param {object} params
 * @param {AuditTrailValue} params.value
 * @param {FieldInfo} params.fieldInfo
 * @param {ResourceLabels} params.labels
 * @param {AuditTrailFields} params.auditFields
 */
const formatValue = ({ value, fieldInfo, labels, auditFields }) => {
  if (fieldInfo.formatDiff) {
    const customValues = fieldInfo.formatDiff(value, labels, auditFields);

    return { customValues };
  }

  const { current, previous } = value;
  if (fieldInfo.formatValue) {
    const currentFields = getAllCurrentOrPreviousAuditFields(
      auditFields,
      "current",
    );
    const formattedCurrent = current
      ? fieldInfo.formatValue(current, labels, currentFields)
      : null;
    const previousFields = getAllCurrentOrPreviousAuditFields(
      auditFields,
      "previous",
    );
    const formattedPrevious = previous
      ? fieldInfo.formatValue(previous, labels, previousFields)
      : null;
    if (!formattedCurrent && !formattedPrevious) return null;
    return { current: formattedCurrent, previous: formattedPrevious };
  }

  return { current, previous };
};

export function getFieldsMap(descriptor) {
  return {
    ...(descriptor.enableDisable && {
      enabled: { name: "enabled", label: "Activation" },
    }),
    ...(descriptor.operations.CustomFieldsQuery && {
      customFields: {
        name: "customFields",
        formatLabel: (value, { resourceLabels }) => {
          const customFieldLabel = value.current
            ? resourceLabels[`$customField-${value.current.name}`]
            : resourceLabels[`$customField-${value.previous?.name}`];

          return ["Champs personnalisés", customFieldLabel];
        },
        fields: {
          value: {
            formatValue: (value) => {
              const customValue = Object.values(value).filter(
                (value) => value !== null,
              )?.[0];
              if (typeof customValue === "object") return null;
              return customValue === undefined ? "Aucun" : customValue;
            },
          },
        },
      },
    }),
    ...descriptor.activities.fields,
  };
}

/**
 * Get action label
 * @param {string} action
 */
function getActionLabel(action) {
  switch (action) {
    case "deleted":
      return "a archivé";
    case "created":
      return "a créé";
    case "enabled":
      return "a activé";
    case "disabled":
      return "a désactivé";
    case "added":
      return "a ajouté";
    case "removed":
      return "a retiré";
    case "updated":
    default:
      return "a modifié";
  }
}

/**
 * Format label component
 * @param {object} params
 * @param {string[]} params.labels
 * @param {string} params.action
 * @param {CRUDTerm} params.term
 */
const formatLabel = ({ labels = [], action = "updated", term }) => {
  const actionLabel = getActionLabel(action);
  const fieldLabel = (
    <span className="inline-flex items-center gap-1 font-semibold">
      {labels.filter(Boolean).map((label, index) => (
        <span
          key={`label-${index}`}
          className="before:pr-1 before:content-['>'] first:before:content-none"
        >
          {label}
        </span>
      ))}
    </span>
  );

  switch (action) {
    case "created":
    case "deleted":
    case "enabled":
    case "disabled": {
      return term ? (
        <>
          {actionLabel} {term.specificArticle}
          <span className="font-semibold">{term.singular}</span>
        </>
      ) : (
        <>
          {actionLabel} {fieldLabel}
        </>
      );
    }
    case "added":
    case "removed":
    case "updated":
    default: {
      return (
        <>
          {actionLabel} {fieldLabel}
        </>
      );
    }
  }
};

/**
 * Format list field
 * @param {object} params
 * @param {string} params.key
 * @param {AuditTrailValue} params.value
 * @param {FieldInfo} params.fieldInfo
 * @param {ResourceLabels} params.resourceLabels
 * @param {string[]} params.parentLabels
 * @param {AuditTrail} params.audit
 * @returns
 */
const formatList = ({
  key,
  value,
  fieldInfo,
  resourceLabels,
  parentLabels = [],
  audit,
}) => {
  const { updated, added, deleted } = value.diff;
  const getParentLabels = (rank) => {
    if (fieldInfo.formatLabel) {
      const customLabel = fieldInfo.formatLabel(
        {
          current: value.current?.[rank],
          previous: value.previous?.[rank],
        },
        { resourceLabels, parentLabels, rank, audit },
      );

      return Array.isArray(customLabel) ? customLabel : [customLabel];
    }

    const valueLabel =
      value.current?.[rank]?.label || value.previous?.[rank]?.label || rank;

    return [...parentLabels, fieldInfo.label, valueLabel];
  };

  const updatedElements = Object.entries({
    added,
    deleted,
    updated,
  }).flatMap(([action, elements = {}]) => {
    return Object.entries(elements || []).flatMap(([rank, diff]) => {
      if (
        (action === "added" || action === "deleted") &&
        value.current?.length !== value.previous?.length
      ) {
        const elementLabels = getParentLabels(rank);
        const actionLabel = action === "added" ? action : "removed";
        return {
          key: `${key}-${rank}`,
          label: formatLabel({ labels: elementLabels, action: actionLabel }),
        };
      }

      const elementDiff = Object.entries(diff).reduce(
        (elementDiff, [field, fieldDiff]) => {
          const previous = value.previous?.[rank]?.[field];
          const current = value.current?.[rank]?.[field];
          return {
            ...elementDiff,
            [field]: { previous, current, diff: { [action]: fieldDiff } },
          };
        },
        {},
      );
      const parentLabels = getParentLabels(rank);

      const parent = { name: `${key}-${rank}`, labels: parentLabels };
      return formatUpdatedAuditTrailFields({
        fields: { ...elementDiff, _labels: resourceLabels },
        fieldsMap: fieldInfo.fields,
        parent,
        audit,
      });
    });
  });

  return updatedElements;
};

/**
 * Format object field
 * @param {object} params
 * @param {string} params.key
 * @param {AuditTrailValue} params.value
 * @param {FieldInfo} params.fieldInfo
 * @param {ResourceLabels} params.resourceLabels
 * @param {string[]} params.parentLabels
 * @param {AuditTrail} params.audit
 * @returns
 */
const formatObject = ({
  key,
  value,
  fieldInfo,
  resourceLabels,
  parentLabels = [],
  audit,
}) => {
  const { added, deleted, updated } = value.diff;

  if (value.previous === null) {
    return {
      label: formatLabel({
        labels: [...parentLabels, fieldInfo.label],
        action: "added",
      }),
    };
  }

  if (value.current === null) {
    return {
      label: formatLabel({
        labels: [...parentLabels, fieldInfo.label],
        action: "deleted",
      }),
    };
  }

  const updatedKeysDiff = Object.entries({
    added,
    deleted,
    updated,
  }).reduce((keysDiff, [action, actionDiff = {}]) => {
    const keyDiff = Object.entries(actionDiff).reduce(
      (actionKeysDiff, [field, fieldDiff]) => {
        const previous = value.previous?.[field] ?? null;
        const current = value.current?.[field] ?? null;
        return {
          ...actionKeysDiff,
          [field]: {
            previous,
            current,
            diff: { [action]: fieldDiff },
          },
        };
      },
      {},
    );

    return merge(keysDiff, keyDiff);
  }, {});

  const label = fieldInfo.formatLabel
    ? fieldInfo.formatLabel(value, { resourceLabels, audit })
    : fieldInfo.label;

  const parent = {
    name: key,
    labels: [...parentLabels, label],
  };

  const updatedObjectKeys = formatUpdatedAuditTrailFields({
    fields: {
      ...updatedKeysDiff,
      _labels: resourceLabels,
    },
    fieldsMap: fieldInfo.fields,
    parent,
    audit,
  });

  return updatedObjectKeys;
};

/**
 * Format update audit trails
 * @param {object} params
 * @param {AuditTrailFields} params.fields
 * @param {FieldsMap} params.fieldsMap
 * @param {CRUDTerm} params.term
 * @param {{name: string, labels: string[]}} [params.parent]
 * @param {AuditTrail} params.audit
 */
const formatUpdatedAuditTrailFields = ({
  fields,
  fieldsMap,
  term,
  parent,
  audit,
}) => {
  const { _labels: resourceLabels, ...auditFields } = fields;
  return Object.entries(auditFields)
    .flatMap(([key, value]) => {
      const fieldInfo = fieldsMap[key];
      if (!fieldInfo) return parent ? null : { key };

      if (fieldInfo.fields) {
        if (value.isList || fieldInfo.isList) {
          return formatList({
            key,
            value,
            fieldInfo,
            resourceLabels,
            parentLabels: parent?.labels,
            audit,
          });
        }
        return formatObject({
          key,
          value,
          fieldInfo,
          resourceLabels,
          parentLabels: parent?.labels,
          audit,
        });
      }

      // enabled exception
      if (key === "enabled") {
        const label = formatLabel({
          action: value.current ? "enabled" : "disabled",
          labels: parent?.labels,
          term,
        });

        return { key, label };
      }

      const formattedValue = formatValue({
        value,
        fieldInfo,
        labels: resourceLabels,
        auditFields,
      });

      const parentLabels = parent?.labels || [];
      const label = fieldInfo.formatLabel
        ? fieldInfo.formatLabel(value, { resourceLabels, parentLabels, audit })
        : fieldInfo.label;

      const action = fieldInfo.formatAction
        ? fieldInfo.formatAction(value)
        : defaultCRUDAction(value);

      const formattedLabel = formatLabel({
        labels: [...parentLabels, label],
        action,
        term,
      });

      return {
        key: parent?.name ? `${parent.name}-${key}` : key,
        value: formattedValue,
        label: formattedLabel,
      };
    })
    .filter(Boolean);
};

/**
 * @param {object} params
 * @param {AuditTrail} params.auditTrail
 * @param {FieldsMap} params.fieldsMap
 * @param {CRUDTerm} params.term
 * @returns
 */
const formatAuditTrails = ({ auditTrail, fieldsMap, term }) => {
  switch (auditTrail.action) {
    case "deleted":
    case "created": {
      const label = formatLabel({ action: auditTrail.action, term });
      return [
        {
          key: auditTrail.globalId,
          user: auditTrail.user,
          date: auditTrail.date,
          label,
        },
      ];
    }
    case "updated": {
      const fields = formatUpdatedAuditTrailFields({
        fields: auditTrail.fields,
        fieldsMap,
        term,
        audit: auditTrail,
      });
      return fields.map((field, index) => {
        const { label, value, key } = field;
        return {
          key: `${auditTrail.globalId}-${key}-${index}`,
          user: auditTrail.user,
          date: auditTrail.date,
          label,
          value,
        };
      });
    }
    default:
      return null;
  }
};

const AuditTrailActivityValue = memo(({ value, type }) => {
  const formatText = (value) => {
    if (typeof value === "boolean") {
      return value ? "Activé" : "Désactivé";
    }

    return value || "Aucun";
  };

  const text = formatText(value, type);
  return (
    <span className={clsx("break-normal", !text ? "text-dusk-500" : null)}>
      {text}
    </span>
  );
});

const AuditTrailActivity = ({ user, date, label, value }) => {
  const valueComponent = useMemo(() => {
    if (!value) return null;
    const { previous, current, customValues, type } = value;
    if (customValues) return customValues;

    return (
      <div className="flex items-center gap-2 text-sm">
        <AuditTrailActivityValue value={previous} type={type} />
        <IoArrowForward />
        <AuditTrailActivityValue value={current} type={type} />
      </div>
    );
  }, [value]);

  return (
    <div className="flex flex-col gap-1 self-center">
      <div className="text-sm leading-4">
        <UserHoverCardTooltip user={user}>
          <span className="font-semibold">
            {user.firstNameInitials} {user.lastName}
          </span>
        </UserHoverCardTooltip>{" "}
        {label || "a fait une modification"}
        <ActivityTime date={date} />
      </div>
      {valueComponent}
    </div>
  );
};

const defaultTerm = {
  specificArticle: "la ",
  singular: "ressource",
};

export const AuditTrail = ({ auditTrail, fieldsMap, term = defaultTerm }) => {
  const auditTrails = formatAuditTrails({
    auditTrail,
    fieldsMap,
    term,
  });

  return auditTrails.map((auditTrail) => {
    const { key, user, date, label, value } = auditTrail;
    return (
      <ActivityItem key={key} user={user}>
        <AuditTrailActivity
          user={user}
          date={date}
          label={label}
          value={value}
        />
      </ActivityItem>
    );
  });
};

AuditTrail.fragments = {
  auditTrail: gql`
    fragment AuditTrailFragment on ActivityItem {
      user {
        id
        firstNameInitials
        lastName
      }
      resource
      date
      ... on AuditTrail {
        action
        fields
      }
    }
  `,
};
