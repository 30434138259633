/* eslint-disable react/prop-types */
import {
  NavLink as ReactRouterLink,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import { createTeleporter } from "react-teleporter";
import { Link as SwashLink } from "swash/Link";

import { BreadcrumbNav as BaseBreadcrumbNav } from "@/components/Layout";

const BreadcrumbTeleporter = createTeleporter({ multiSources: true });

export function BreadcrumbNav() {
  return (
    <BaseBreadcrumbNav>
      <BreadcrumbTeleporter.Target as="ol" />
    </BaseBreadcrumbNav>
  );
}

export function BreadcrumbLink({ to, end, children }) {
  const path = useResolvedPath(to);
  const match = useMatch({ path: path.pathname, end });

  return (
    <BreadcrumbTeleporter.Source>
      <li aria-current={match ? "page" : undefined}>
        {match ? (
          children
        ) : (
          <SwashLink asChild>
            <ReactRouterLink to={to}>{children}</ReactRouterLink>
          </SwashLink>
        )}
      </li>
    </BreadcrumbTeleporter.Source>
  );
}
