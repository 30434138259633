import { memo } from "react";

import {
  CheckboxField,
  useCheckboxField,
} from "@/components/fields/CheckboxField";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { SwitchField, useSwitchField } from "@/components/fields/SwitchField";

import { EnumField } from "./EnumField";

function CheckboxBooleanField({ name, label, hint, variant, ...options }) {
  const field = useCheckboxField(name, {
    orientation: "vertical",
    ...options,
  });

  return (
    <FieldGroup {...field}>
      <CheckboxField variant={variant} hint={hint} {...field}>
        {label}
      </CheckboxField>
    </FieldGroup>
  );
}

const enumValues = [
  { value: true, label: "Oui" },
  { value: false, label: "Non" },
];

function SelectBooleanField(props) {
  return <EnumField {...props} enum={enumValues} />;
}

const SwitchLeftSide = ({ tint, label, hint, ...field }) => {
  return (
    <div className="items-start">
      <FieldGroup {...field} display="flex" gap="3">
        <SwitchField {...field} tint={tint} />
        <div className="flex flex-col">
          {label && <FieldLabel {...field}>{label}</FieldLabel>}
          {hint && <FieldHint {...field}>{hint}</FieldHint>}
        </div>
        <FieldError {...field} />
      </FieldGroup>
    </div>
  );
};

const SwitchRightSide = ({ tint, label, hint, ...field }) => {
  return hint ? (
    <div className="flex grow flex-col gap-2">
      <FieldGroup {...field} display="flex" justifyContent="space-between">
        {label && <FieldLabel {...field}>{label}</FieldLabel>}
        <FieldError {...field} />
        <SwitchField {...field} tint={tint} />
      </FieldGroup>
      <div>
        <FieldHint {...field}>{hint}</FieldHint>
      </div>
    </div>
  ) : (
    <FieldGroup {...field} display="flex" justifyContent="space-between">
      {label && <FieldLabel {...field}>{label}</FieldLabel>}
      <FieldError {...field} />
      <SwitchField {...field} tint={tint} />
    </FieldGroup>
  );
};

function SwitchBooleanField({
  name,
  label,
  tint,
  hint,
  placement = "right",
  ...options
}) {
  const field = useSwitchField(name, options);

  return placement === "left" ? (
    <SwitchLeftSide {...field} label={label} tint={tint} hint={hint} />
  ) : (
    <SwitchRightSide {...field} label={label} tint={tint} hint={hint} />
  );
}

/**
 * @type {React.FC<{ appearance?: 'select' | 'checkbox' | 'switch' } & any>}
 */
export const BooleanField = memo(({ appearance = "checkbox", ...others }) => {
  switch (appearance) {
    case "select":
      return <SelectBooleanField {...others} />;
    case "checkbox":
      return <CheckboxBooleanField {...others} />;
    case "switch":
      return <SwitchBooleanField {...others} />;
    default:
      return null;
  }
});
