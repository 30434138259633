import clsx from "clsx";
import { memo } from "react";

const MAX_ELASTIC_SEARCH_RESULT_COUNT = 10000;
const numberFormatter = new Intl.NumberFormat("fr");

function formatCount(totalCount: number) {
  const result = `résultat${totalCount > 1 ? "s" : ""}`;

  return totalCount >= MAX_ELASTIC_SEARCH_RESULT_COUNT
    ? `Plus de ${numberFormatter.format(
        MAX_ELASTIC_SEARCH_RESULT_COUNT,
      )} ${result}`
    : `${numberFormatter.format(totalCount)} ${result}`;
}

export type ListTotalCountProps = {
  totalCount: number;
  className?: string;
};

export const ListTotalCount = memo<ListTotalCountProps>(
  ({ totalCount, className }) => {
    return (
      <div className={clsx("py-2 font-accent text-xs text-grey-on", className)}>
        {formatCount(totalCount)}
      </div>
    );
  },
);
