import {
  Tab as AriakitTab,
  TabList as AriakitTabList,
  TabListProps as AriakitTabListProps,
  TabPanel as AriakitTabPanel,
  TabPanelProps as AriakitTabPanelProps,
  TabProps as AriakitTabProps,
  TabStoreProps as AriakitTabStoreProps,
  TabStore,
  useTabStore as useAriakitTabStore,
} from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";
import {
  Children,
  ReactElement,
  ReactNode,
  forwardRef,
  isValidElement,
} from "react";

import { EnumSelect, useEnumSelectState } from "./v2/EnumSelect";

export type TabVariant = "bar" | "default";
export type TabSize = "md" | "sm" | "lg";
export type TabTheme = "light" | "dark";

interface TabStateProps extends AriakitTabStoreProps {
  /**
   * The variant of the tab system.
   * @default "default"
   */
  variant?: TabVariant;

  /**
   * The size of the tab system.
   * @default "md"
   * */
  size?: TabSize;

  /**
   * The theme of the tab system.
   * @default "light"
   */
  theme?: "light" | "dark";
}

export interface TabState {
  variant: TabVariant;
  size: TabSize;
  theme: TabTheme;
  store: TabStore;
}

export const useTabState = ({
  variant = "default",
  size = "md",
  theme = "light",
  ...props
}: TabStateProps = {}): TabState => {
  const store = useAriakitTabStore(props);
  return React.useMemo(
    () => ({
      store,
      variant,
      size,
      theme,
    }),
    [store, variant, size, theme],
  );
};

export interface TabListProps extends AriakitTabListProps {
  state: TabState;
}

export const TabList = forwardRef<HTMLDivElement, TabListProps>(
  ({ state: { variant, size, theme, store }, className, ...props }, ref) => {
    const TabListBarSize: Record<TabSize, string> = {
      lg: "py-1",
      md: "py-1",
      sm: "py-0.5",
    };
    const TabListVariants: Record<TabVariant, string> = {
      default: "",
      bar: clsx("rounded justify-around", TabListBarSize[size]),
    };
    const TabListVariantsThemes: Record<
      TabVariant,
      Record<TabTheme, string>
    > = {
      default: {
        light: "",
        dark: "",
      },
      bar: {
        light: /* tw */ `bg-grey-bg-light`,
        dark: /* tw */ `bg-dusk-700`,
      },
    };
    return (
      <AriakitTabList
        ref={ref}
        store={store}
        className={clsx(
          className,
          "flex select-none",
          TabListVariants[variant],
          TabListVariantsThemes[variant][theme],
        )}
        {...props}
      />
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  TabList.displayName = "TabList";
}

export interface TabSelectListProps extends TabListProps {
  state: TabState;
  children: ReactNode;
}

export const TabSelectList = forwardRef<HTMLDivElement, TabSelectListProps>(
  ({ state, children, className, ...props }, ref) => {
    const tabs = Children.toArray(children).filter(
      (child) => isValidElement(child) && child.type === Tab,
    ) as ReactElement<TabProps>[];

    const items = tabs.map((tab) => {
      const { id: value, label: optionLabel, children } = tab.props;
      return { value, label: children as string, optionLabel };
    });

    const { setSelectedId } = state.store;
    const selectedId = state.store.useState("selectedId");

    const select = useEnumSelectState({
      value: items.find(({ value }) => value === selectedId) ?? null,
      onChange: (item) => setSelectedId(item?.value),
      items,
      labelSelector: ({ label }) => label,
      labelElementSelector: ({ optionLabel, label }) => optionLabel ?? label,
      required: true,
    });

    return (
      <AriakitTabList
        ref={ref}
        store={state.store}
        className={clsx(className, "flex select-none self-end")}
        {...props}
      >
        <EnumSelect state={select} />
      </AriakitTabList>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  TabSelectList.displayName = "TabSelectList";
}

export interface TabProps extends AriakitTabProps {
  state: TabState;
  label?: string;
}

export const Tab = forwardRef<HTMLButtonElement, TabProps>(
  (
    { state: { variant, size, theme, store }, label, className, ...props },
    ref,
  ) => {
    const TabBarSizes: Record<TabSize, string> = {
      lg: "px-2 py-1 text-md mx-1",
      md: "px-2 py-1 text-sm mx-1",
      sm: "text-xs py-0.5 px-2 mx-0.5",
    };

    const TabVariants: Record<TabVariant, string> = {
      default: clsx(
        "relative flex items-center gap-2 rounded-tl rounded-tr border-l border-t border-r border-transparent bg-transparent py-1 px-4 text-grey-on transition-colors",
        "hover:text-grey-on-hover",
        "disabled:opacity-disabled",
        "after:absolute after:-bottom-px after:left-0 after:right-0 after:block after:h-px after:transition",
        "aria-selected:border-grey-border-light aria-selected:bg-white aria-selected:text-dusk-on aria-selected:hover:text-dusk-on",
        "aria-selected:after:bg-white",
      ),
      bar: clsx(
        "bg-transparent rounded flex gap-2 items-center transition w-full justify-center",
        TabBarSizes[size],
      ),
    };

    const TabVariantsTheme: Record<TabVariant, Record<TabTheme, string>> = {
      default: {
        light: "",
        dark: "",
      },
      bar: {
        light: clsx(
          "text-grey-on-light",
          "hover:text-grey-on-hover",
          "aria-selected:text-dusk-on aria-selected:hover:text-dusk-on aria-selected:shadow-sm aria-selected:bg-white",
        ),
        dark: clsx(
          "text-dusk-300",
          "hover:text-dusk-200",
          "aria-selected:text-white aria-selected:hover:text-white aria-selected:shadow-sm aria-selected:bg-dusk-900",
        ),
      },
    };

    return (
      <AriakitTab
        ref={ref}
        store={store}
        className={clsx(
          className,
          "font-accent font-bold aria-selected:cursor-default",
          TabVariants[variant],
          TabVariantsTheme[variant][theme],
        )}
        aria-label={label}
        {...props}
      />
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Tab.displayName = "Tab";
}

export interface TabPanelProps extends AriakitTabPanelProps {
  state: TabState;
  /**
   * Make the panel nude, no style are applied.
   * @default false
   */
  nude?: boolean;

  /**
   * Lazy load the panel content.
   * @default false
   */
  lazy?: boolean;
}

export const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>(
  (
    {
      state: { variant, store },
      className,
      nude = false,
      lazy = true,
      ...props
    },
    ref,
  ) => {
    const selectedId = store.useState("selectedId");
    const selected = selectedId === props.tabId;
    const TabPanelVariants: Record<TabVariant, string> = {
      default: clsx(
        !nude &&
          "rounded rounded-tl-none border bg-white border-grey-border-light",
      ),
      bar: "",
    };
    return (
      <AriakitTabPanel
        ref={ref}
        store={store}
        focusable={false}
        className={clsx(className, TabPanelVariants[variant])}
        {...props}
      >
        {lazy && selected ? props.children : null}
      </AriakitTabPanel>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  TabPanel.displayName = "TabPanel";
}
