import { gql } from "@apollo/client";
import clsx from "clsx";
import { memo, useCallback } from "react";
import { IoExpand, IoImage } from "swash/Icon";
import {
  PreviewLink,
  PreviewLinkContent,
  PreviewLinkHovering,
} from "swash/PreviewLink";
import { Checkbox } from "swash/controls/Checkbox";

import {
  CardListItem,
  CardListItemEditDisclosure,
  CardListItemThumbnail,
} from "@/components/CardListItem";
import { ImageFixedFragment, ImageFluidFragment } from "@/components/Image";
import { RemoteEditImageDialogDisclosure } from "@/containers/image/ImageEditDialog";
import {
  WithLayoutMode,
  useLayoutMode,
} from "@/containers/search/LayoutModeContext";

import { useDragUrl } from "../Dnd";
import {
  ImagePreviewDialog,
  ImagePreviewDialogDisclosure,
  useImagePreviewDialogStore,
} from "./ImagePreview";
import { ImageSummary } from "./ImageSummary";
import { useImageEditMultiple } from "./image-edit-multiple/ImageEditMultipleContext";

export const ImageCard = ({ image, imageIds }) => {
  const imageId = image.id;
  const [, dragRef] = useDragUrl(image.siriusUrl);
  const context = useImageEditMultiple();
  const checked = context?.imageIds.includes(imageId) ?? false;
  const layoutMode = useLayoutMode();

  return (
    <CardListItem
      ref={dragRef}
      variant={image.expired && "danger"}
      layoutMode={layoutMode ?? "grid"}
    >
      <ImageEditMultipleCheckbox
        imageId={imageId}
        className="absolute left-2 top-2 z-30"
        listImageIds={imageIds}
        context={context}
        checked={checked}
      />
      <CardListItemEditDisclosure
        title="Éditer l’image"
        imageId={imageId}
        as={RemoteEditImageDialogDisclosure}
      />
      <WithLayoutMode mode="list">
        <IoImage className="shrink-0" />
      </WithLayoutMode>
      <ImagePreview image={image} />
      <ImageSummary image={image} />
    </CardListItem>
  );
};

const ImageEditMultipleCheckbox = memo(
  ({ imageId, listImageIds, context, checked, className, ...props }) => {
    const handleChange = useCallback(
      (event) => {
        if (!context?.handleMultiSelect) return null;

        const { handleMultiSelect } = context;

        handleMultiSelect({
          listImageIds,
          imageId,
          select: event.currentTarget.checked,
        });
      },
      [imageId, listImageIds, context],
    );

    if (!context?.handleMultiSelect) return null;

    return (
      <Checkbox
        checked={checked}
        onChange={handleChange}
        className={clsx(
          checked ? "opacity-100" : "opacity-0 group-hover:opacity-100",
          className,
        )}
        {...props}
      />
    );
  },
);

const ImagePreview = ({ image }) => {
  const layoutMode = useLayoutMode();
  const previewState = useImagePreviewDialogStore({ imageId: image.id });
  return (
    <>
      <ImagePreviewDialogDisclosure
        title="Prévisualiser l’image"
        {...previewState}
        render={
          <PreviewLink>
            <PreviewLinkContent>
              <CardListItemThumbnail
                alt="image"
                {...(layoutMode === "list" ? image.fixed : image.fluid)}
              />
            </PreviewLinkContent>
            <PreviewLinkHovering>
              <IoExpand />
            </PreviewLinkHovering>
          </PreviewLink>
        }
      />
      <ImagePreviewDialog {...previewState} />
    </>
  );
};

ImagePreview.fragments = {
  image: gql`
    fragment ImagePreview_image on Image {
      id
      fluid(maxHeight: 160) @include(if: $isGrid) {
        ...ImageFluidFragment
      }
      fixed(width: 72, height: 52) @include(if: $isList) {
        ...ImageFixedFragment
      }
    }
    ${ImageFluidFragment}
    ${ImageFixedFragment}
  `,
};

ImageCard.fragments = {
  image: gql`
    fragment ImageCard_image on Image {
      id
      expired
      siriusUrl
      ...ImagePreview_image
      ...ImageSummary_image
    }
    ${ImageFluidFragment}
    ${ImageSummary.fragments.image}
    ${ImagePreview.fragments.image}
  `,
};
