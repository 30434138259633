import styled from "@xstyled/styled-components";
import deepEqual from "deep-equal";
import { useField } from "react-final-form";

import { composeValidators, mustBeFilled } from "@/services/forms/validators";

import { FieldControl } from "./FieldControl";
import { useFieldState } from "./FieldState";

export const selectFieldIntegerFormatters = {
  format: (v) => (v ? String(v) : v),
  parse: (v) => (v ? Number(v) : v),
  isEqual: Object.is,
};

export const FieldOptionDescription = styled.box`
  font-size: xs;
  color: on-light;

  [aria-selected="true"] & {
    color: white;
  }

  [tabindex="0"] & {
    color: white;
  }

  [data-select-option]:focus & {
    color: white;
  }

  [data-select-option]:hover & {
    color: white;
  }

  [aria-checked="true"] & {
    color: white;
  }
`;

export function useSelectField(
  name,
  {
    required = false,
    disabled,
    validate,
    id,
    label,
    orientation = "vertical",
    ...options
  } = {},
) {
  const validators = [];
  if (validate) {
    validators.push(validate);
  }
  if (required) {
    validators.push(mustBeFilled);
  }

  const field = useField(name, {
    validate: validators.length ? composeValidators(...validators) : undefined,
    format: (v) => v,
    parse: (v) => v,
    isEqual: deepEqual,
    allowNull: true,
    disabled,
    ...options,
  });

  return useFieldState({ field, required, orientation, label });
}

export const SelectField = FieldControl;
