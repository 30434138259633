import styled, { css } from "@xstyled/styled-components";

const InnerDescriptionList = styled.dlBox`
  &[data-layout="fixed"] {
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr);
    ${(p) => css`
      gap: ${p.$ySpace};
    `}
  }

  dt {
    opacity: 0.75;
  }

  &[data-layout="fluid"] {
    margin-bottom: 0;

    dt {
      display: inline;

      &:nth-last-child(2) {
        padding-bottom: 0;
      }
    }

    dd {
      display: inline;
      margin-left: 1;

      &:last-child {
        padding-bottom: 0;
      }
    }

    dd:after {
      content: " ";
      display: block;
      ${(p) => css`
        padding-bottom: ${p.$ySpace};
      `}
    }
  }
`;

export function DescriptionList({ layout = "fixed", ySpace = 3, ...props }) {
  return (
    <InnerDescriptionList data-layout={layout} $ySpace={ySpace} {...props} />
  );
}
