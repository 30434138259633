import { forwardRef } from "react";
import { useField } from "react-final-form";
import { Checkbox } from "swash/controls/Checkbox";
import { FormCheckbox } from "swash/form/FormCheckbox";
import { FormLabel } from "swash/form/FormLabel";

import { FieldHint } from "@/components/fields/FieldHint";

import { FieldControl } from "./FieldControl";
import { useFieldState } from "./FieldState";

export function useCheckboxField(
  name,
  { id, orientation = "horizontal", disabled, ...options } = {},
) {
  const field = useField(name, {
    type: "checkbox",
    ...options,
  });

  return useFieldState({ field, id, orientation, disabled });
}

export const CheckboxField = forwardRef(({ children, hint, ...props }, ref) => {
  return (
    <FieldControl ref={ref} {...props}>
      {({ "data-field-control": dataFieldControl, ...fieldControlProps }) => (
        <div data-field-control={dataFieldControl} className="flex">
          <FormCheckbox>
            <Checkbox {...fieldControlProps} />
            <FormLabel
              id={fieldControlProps["aria-labelledby"]}
              aria-disabled={fieldControlProps.disabled}
              htmlFor={fieldControlProps.id}
            >
              {children}
            </FormLabel>
            {hint && <FieldHint {...props}>{hint}</FieldHint>}
          </FormCheckbox>
        </div>
      )}
    </FieldControl>
  );
});
