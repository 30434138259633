import { gql } from "@apollo/client";
import clsx from "clsx";
import { memo } from "react";
import { Tooltip } from "swash/Tooltip";

import {
  ArticleLayoutIcon,
  ArticleLayoutIconProps,
  SpreadArticleLayoutIcon,
} from "./ArticleLayoutIcon";

export type ArticleLayoutPricingIndicatorProps = {
  className?: string;
  tooltip?: React.ReactNode;
  article: {
    layout: {
      id: number;
      label: string;
    };
    pricingTier: {
      id: number;
      label: string;
      color: string;
    } | null;
  } & ArticleLayoutIconProps["article"];
};

type SpreadArticleLayoutPricingIndicatorProps =
  ArticleLayoutPricingIndicatorProps["article"] & {
    className?: string;
    tooltip?: React.ReactNode;
  };

export const SpreadArticleLayoutPricingIndicator =
  memo<SpreadArticleLayoutPricingIndicatorProps>(
    ({ layout, pricingTier, className, tooltip }) => {
      return (
        <Tooltip
          tooltip={
            tooltip ??
            [layout.label, pricingTier?.label].filter(Boolean).join(" - ")
          }
        >
          <div
            className={clsx("flex text-grey-on", className)}
            style={{
              color: pricingTier?.color,
            }}
          >
            <SpreadArticleLayoutIcon layout={layout} />
          </div>
        </Tooltip>
      );
    },
  );

export const ArticleLayoutPricingIndicator = (
  props: ArticleLayoutPricingIndicatorProps,
) => {
  return (
    <SpreadArticleLayoutPricingIndicator
      layout={props.article.layout}
      pricingTier={props.article.pricingTier}
      className={props.className}
      tooltip={props.tooltip}
    />
  );
};

ArticleLayoutPricingIndicator.fragments = {
  article: gql`
    fragment ArticleLayoutPricingIndicator_article on Article {
      layout {
        id
        label
      }
      pricingTier {
        id
        label
        color
      }

      ...ArticleLayoutIcon_article
    }

    ${ArticleLayoutIcon.fragments.article}
  `,
};
