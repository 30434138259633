const abstractSetItem = (key: string, value: string): void => {
  if (localStorage) return localStorage.setItem(key, value);
};

const abstractGetItem = (key: string): string | null => {
  if (localStorage) return localStorage.getItem(key);
  return null;
};

export const setItem = <T>(key: string, value: T): void => {
  abstractSetItem(key, JSON.stringify(value));
};

export const getItem = <T>(key: string): T | null => {
  try {
    const item = abstractGetItem(key);
    if (item) {
      return JSON.parse(item) as T;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const setItemList = <T extends Record<string, any>>({
  key,
  value,
}: {
  key: string;
  value: T;
}): void => {
  Object.keys(value).forEach((subKey) => {
    const keyName = `${key}.${subKey}`;
    abstractSetItem(keyName, JSON.stringify(value[subKey]));
  });
};
