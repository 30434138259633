/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DocumentNode, gql, useMutation } from "@apollo/client";
import clsx from "clsx";
import React, {
  NamedExoticComponent,
  forwardRef,
  memo,
  useEffect,
  useState,
} from "react";
import { Clickable, ClickableProps } from "swash/Clickable";
import {
  Popover,
  PopoverDisclosure,
  PopoverStore,
  usePopoverStore,
} from "swash/Popover";
import { useToaster } from "swash/Toast";
import { Tooltip } from "swash/Tooltip";
import { useEventCallback } from "swash/utils/useEventCallback";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";

import { useArticleAuditTrailTooltip } from "@/components/ArticleAuditTrail";
import {
  ArticlePublishDate,
  ArticlePublishDateProps,
} from "@/containers/article/ArticlePublishDate";
import {
  ArticlePublishStatusIcon,
  AuthorDisplay,
  DateDisplay,
  Event,
} from "@/containers/article/ArticlePublishStatus";
import {
  ArticlePublishTime,
  ArticlePublishTimeProps,
} from "@/containers/article/ArticlePublishTime";
import { useListReadOnly } from "@/containers/list/List";

import { ArticleDateForm, ArticleDateFormFields } from "./ArticleDateForm";

type DateFieldsEditPopoverProps = {
  store: PopoverStore;
  open: boolean;
  title: string;
};

const DateFieldsEditPopover = ({
  store,
  open,
  title,
}: DateFieldsEditPopoverProps) => {
  return (
    <Popover
      store={store}
      className="overflow-hidden"
      modal
      lazy={false}
      aria-label={title}
    >
      {open ? <ArticleDateFormFields /> : null}
    </Popover>
  );
};

type ArticleDateFormValues = {
  planning: {
    date: string | null;
    range: string | null;
  };
  isUrgent: boolean;
  isEmbargo: boolean;
};

type DateEditPopoverProps = {
  store: PopoverStore;
  open: boolean;
  article: ActiveDateButtonProps["article"];
  onChange: (values: ArticleDateFormValues) => void;
  title: string;
};

const DateEditPopover = ({
  article,
  onChange,
  store,
  open,
  title,
}: DateEditPopoverProps) => {
  return (
    <Popover
      store={store}
      className="overflow-hidden"
      modal
      lazy={false}
      aria-label={title}
    >
      {open ? <ArticleDateForm article={article} onChange={onChange} /> : null}
    </Popover>
  );
};

type PublishDateTimeProps = ActiveDateButtonProps & {
  className?: string;
};

const ArticlePublishStatus = ({ article }: ActiveDateButtonProps) => {
  if (article.initialFirstPublished) {
    return <ArticlePublishStatusIcon status="published" size={12} />;
  }
  if (article.scheduled) {
    return <ArticlePublishStatusIcon status="scheduled" size={12} />;
  }
  if (article.planning?.date) {
    return <ArticlePublishStatusIcon status="pending" size={12} />;
  }
  return null;
};

const PublishDateTime = ({
  article,
  verbose,
  className,
}: PublishDateTimeProps) => {
  return (
    <div
      className={clsx(
        className,
        "block select-none text-start font-accent text-sm",
        verbose && "flex flex-row items-center gap-1",
      )}
    >
      {verbose && (
        <div>
          <ArticlePublishStatus article={article} />
        </div>
      )}

      <div className={clsx(verbose && "font-bold")}>
        <ArticlePublishDate article={article} />
      </div>
      {verbose && <div className="h-3 w-[1px] bg-dusk-on" />}
      <div className={clsx(verbose && "font-bold")}>
        <ArticlePublishTime article={article} />
      </div>
    </div>
  );
};

type DateButtonProps = ActiveDateButtonProps & ClickableProps;

const DateButton = forwardRef<HTMLButtonElement, DateButtonProps>(
  ({ className, verbose, article, ...props }, ref) => {
    return (
      <Clickable
        ref={ref}
        className={clsx(className, "flex h-full flex-wrap gap-2 p-1 text-sm")}
        {...props}
      >
        <PublishDateTime verbose={verbose} article={article} />
      </Clickable>
    );
  },
);

const Mutation = gql`
  mutation UpdateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      id
      ...ArticleDateForm_article
    }
  }
  ${ArticleDateForm.fragments.article}
`;

type ActiveDateButtonProps = {
  article: { id: number };
  verbose?: boolean;
} & ArticlePublishDateProps &
  ArticlePublishTimeProps;

const ActiveDateButton = ({ article, verbose }: ActiveDateButtonProps) => {
  const title = "Éditer la date de publication souhaitée";
  const tooltip = useArticleAuditTrailTooltip("publicationDate");
  const [values, setValues] = useState<ArticleDateFormValues>();

  const toaster = useToaster();
  const [updateArticle] = useMutation(Mutation, {
    onError: () => {
      toaster.danger(
        "La date de publication de l’article n’a pas été mise à jour",
      );
    },
  });
  const popover = usePopoverStore({ placement: "right-start" });
  const open = popover.useState("open");
  const previousOpen = usePrevious(open);
  const refs = useLiveRef({ article, values, updateArticle });

  useEffect(() => {
    const beenClosed = previousOpen && !open;
    if (beenClosed) {
      const { article, values, updateArticle } = refs.current;

      updateArticle({
        variables: {
          input: {
            id: article.id,
            ...values,
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateArticle: {
            __typename: "Article",
            id: article.id,
            ...values,
          },
        },
      });
    }
  }, [open, previousOpen, refs]);

  return (
    <span className="contents" onClick={(event) => event.stopPropagation()}>
      <Tooltip tooltip={open ? null : tooltip ?? title}>
        <PopoverDisclosure
          store={popover}
          render={
            <DateButton
              verbose={verbose}
              article={
                open
                  ? {
                      ...article,
                      ...values,
                    }
                  : article
              }
            />
          }
        />
      </Tooltip>
      <DateEditPopover
        store={popover}
        open={open}
        article={article}
        onChange={setValues}
        title={title}
      />
    </span>
  );
};

export type ArticleDateEditorProps = {
  verbose?: boolean;
  article: {
    id: number;
    initialFirstPublished: string | null;
    published: boolean;
    scheduled: boolean;
    scheduledAt: string | null;
    scheduledBy: {
      id: string;
      fullName: string;
    } | null;
    publishInfos: ArticlePublishedDateTooltipProps["publishInfos"];
  } & ArticlePublishDateProps["article"] &
    ArticlePublishTimeProps["article"];
};

type ArticlePublishedDateTooltipProps = {
  children: React.ReactElement;
  publishInfos: {
    firstPublishedIn: Event;
  };
};

const ArticlePublishedDateTooltip = memo(({ children, publishInfos }) => {
  const { firstPublishedIn } = publishInfos;
  return (
    <Tooltip
      tooltip={
        <>
          <div className="font-semibold">Première publication</div>
          <DateDisplay date={firstPublishedIn?.date ?? null} />
          <AuthorDisplay user={firstPublishedIn?.actor ?? null} />
        </>
      }
    >
      {children}
    </Tooltip>
  );
}) as NamedExoticComponent<ArticlePublishedDateTooltipProps> & {
  fragments: { article: DocumentNode };
};

ArticlePublishedDateTooltip.fragments = {
  article: gql`
    fragment ArticlePublishedDateTooltip_article on Article {
      publishInfos {
        firstPublishedIn {
          id
          date
          actor {
            id
            ...AuthorDisplay_user
          }
        }
      }
    }
    ${AuthorDisplay.fragments.user}
  `,
};

type ArticleScheduledDateTooltipProps = {
  children: React.ReactElement;
  scheduledAt: string | null;
  scheduledBy: {
    fullName: string;
  } | null;
};

const ArticleScheduledDateTooltip = memo(
  ({
    children,
    scheduledAt,
    scheduledBy,
  }: ArticleScheduledDateTooltipProps) => {
    return (
      <Tooltip
        tooltip={
          <>
            <div className="font-semibold">Programmé</div>
            <DateDisplay date={scheduledAt} />
            <AuthorDisplay user={scheduledBy} />
          </>
        }
      >
        {children}
      </Tooltip>
    );
  },
);

export const ArticleDateEditor = ({
  article,
  verbose,
}: ArticleDateEditorProps) => {
  const readOnly = useListReadOnly();
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));

  if (article.initialFirstPublished) {
    return (
      <ArticlePublishedDateTooltip publishInfos={article.publishInfos}>
        <div>
          <PublishDateTime
            verbose={verbose}
            article={article}
            className="p-1"
          />
        </div>
      </ArticlePublishedDateTooltip>
    );
  }

  if (article.scheduled) {
    return (
      <ArticleScheduledDateTooltip
        scheduledAt={article.scheduledAt}
        scheduledBy={article.scheduledBy}
      >
        <div>
          <PublishDateTime
            verbose={verbose}
            article={article}
            className="p-1"
          />
        </div>
      </ArticleScheduledDateTooltip>
    );
  }

  if (!readOnly && active) {
    return <ActiveDateButton verbose={verbose} article={article} />;
  }

  return (
    <DateButton
      onFocus={activate}
      onMouseEnter={activate}
      article={article}
      verbose={verbose}
      disabled={readOnly}
    />
  );
};

ArticleDateEditor.fragments = {
  article: gql`
    fragment ArticleDateEditor_article on Article {
      id
      initialFirstPublished
      scheduled
      scheduledAt
      scheduledBy {
        id
        fullName
      }
      ...ArticlePublishedDateTooltip_article
      ...ArticlePublishDate_article
      ...ArticlePublishTime_article
      ...ArticleDateForm_article
    }
    ${ArticlePublishedDateTooltip.fragments.article}
    ${ArticlePublishDate.fragments.article}
    ${ArticlePublishTime.fragments.article}
    ${ArticleDateForm.fragments.article}
  `,
};

export type ArticleDateSelectorProps = {
  tooltip?: string;
} & {
  article: {
    id: number;
  };
} & ArticlePublishDateProps &
  ArticlePublishTimeProps;

export const ArticleDateSelector = ({
  tooltip,
  article,
}: ArticleDateSelectorProps) => {
  const popover = usePopoverStore({ placement: "bottom-start" });
  const open = popover.useState("open");
  const title = "Éditer la date de publication souhaitée";

  return (
    <>
      <Tooltip tooltip={open ? null : tooltip ?? title}>
        <PopoverDisclosure
          store={popover}
          render={<DateButton article={article} />}
        />
      </Tooltip>
      <DateFieldsEditPopover store={popover} title={title} open={open} />
    </>
  );
};
