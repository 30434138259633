import { useId } from "ariakit-react-utils/hooks";

export function useFieldState({ field, id: idOption, ...options }) {
  const id = useId(idOption || null);
  const labelId = useId();

  const state = { field, id, ...options };
  if (!state.label || !state["aria-label"]) {
    state.labelId = labelId;
  }

  return { state };
}
