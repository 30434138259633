import clsx from "clsx";
import React, { forwardRef } from "react";

export type FormHintProps = React.ComponentProps<"div">;

export const FormHint = forwardRef<HTMLDivElement, FormHintProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(className, "text-sm text-grey-on")}
        {...props}
      />
    );
  },
);
