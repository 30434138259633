import {
  Combobox as AriakitCombobox,
  ComboboxList as AriakitComboboxList,
  ComboboxListProps as AriakitComboboxListProps,
  ComboboxProps as AriakitComboboxProps,
} from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

export interface MenuComboboxListProps extends AriakitComboboxListProps {
  /**
   * Manually set the empty state of the list. This is useful when the list is wrapped in a virtualized list.
   */
  empty?: boolean;
}

export const MenuComboboxList = forwardRef<
  HTMLDivElement,
  MenuComboboxListProps
>(({ className, empty, ...props }, ref) => {
  return (
    <AriakitComboboxList
      ref={ref}
      className={clsx(
        className,
        "scrollbar-light flex-1 overflow-y-auto overflow-x-hidden",
        !empty && "border-t border-t-grey-border-light p-1",
        "empty:border-t-0 empty:p-0",
      )}
      {...props}
    />
  );
});

export type ComboboxProps = AriakitComboboxProps<"input">;

export const MenuCombobox = forwardRef<HTMLInputElement, ComboboxProps>(
  ({ className, ...props }, ref) => {
    return (
      <AriakitCombobox
        ref={ref}
        className={clsx(
          className,
          "shrink-0 px-4 py-2 font-accent placeholder:text-dusk-on-light/70 focus:outline-none",
        )}
        autoComplete="none"
        {...props}
      />
    );
  },
);
