import { forwardRef } from "react";
import { createTeleporter } from "react-teleporter";

import { PageTitle as BasePageTitle } from "@/components/Layout";

const Teleporter = createTeleporter();

export const PageTitle = forwardRef((props, ref) => {
  return (
    <Teleporter.Source>
      <BasePageTitle ref={ref} {...props} />
    </Teleporter.Source>
  );
});

export function PageTitleTarget() {
  return <Teleporter.Target />;
}
