import { gql } from "@apollo/client";

import {
  ArticleEditorialLevel,
  ArticleEditorialLevelProps,
} from "./ArticleEditorialLevel";

export type ArticleDescriptionEditorialLevelProps = {
  article: ArticleEditorialLevelProps["article"];
  disabled?: boolean;
};

const ArticleFragment = gql`
  fragment ArticleDescriptionEditorialLevel_article on Article {
    ...ArticleEditorialLevel_article
  }

  ${ArticleEditorialLevel.fragments.article}
`;

export const ArticleDescriptionEditorialLevel = (
  props: ArticleDescriptionEditorialLevelProps,
) => {
  return (
    <ArticleEditorialLevel
      article={props.article}
      disabled={props.disabled}
      scale="sm"
    />
  );
};

ArticleDescriptionEditorialLevel.fragments = {
  article: ArticleFragment,
};
