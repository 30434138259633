import styled, { css, x } from "@xstyled/styled-components";
import { forwardRef } from "react";

const variant = ({ light, dark }) => {
  return css`
    color: ${dark};
    background-color: ${light};
  `;
};

export const BannerBody = styled.box`
  height: 42;
  padding: 2 3;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const InnerBanner = styled.box`
  position: relative;
  font-family: accent;
  overflow: hidden;
  font-size: sm;
  color: primary-darkest;
  height: 42;

  &[data-variant="success"] {
    ${variant({
      dark: "success-darkest",
      light: "success-lighter",
    })}
  }

  &[data-variant="danger"] {
    ${variant({
      dark: "danger-darkest",
      light: "danger-lighter",
    })}
  }

  &[data-variant="warning"] {
    ${variant({
      dark: "warning-darkest",
      light: "warning-lighter",
    })}
  }

  &[data-variant="neutral"] {
    ${variant({
      dark: "text",
      light: "primary-lighter",
    })}
  }

  &[data-animated] {
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: ease;
    height: 0;
    opacity: 0.5;

    &[data-enter] {
      height: 42;
      opacity: 1;
    }

    &[data-leave] {
      transition-duration: 200ms;
    }
  }
`;

export const BannerIcon = forwardRef((props, ref) => {
  return (
    <x.div
      ref={ref}
      display="inline-flex"
      mr={2}
      verticalAlign="-15%"
      {...props}
    />
  );
});

export const Banner = forwardRef(
  ({ variant = "warning", animated, ...props }, ref) => {
    return (
      <InnerBanner
        ref={ref}
        role="alert"
        data-animated={animated}
        data-variant={variant}
        {...props}
      />
    );
  },
);
