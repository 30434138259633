import { gql } from "@apollo/client";
import axios from "axios";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "swash/Button";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { useSafeQuery } from "@/containers/Apollo";
import { createServiceForm } from "@/containers/admin/Integration";

import mailchimpLogo from "./logo.png";

export { default as logo } from "./logo.png";

const MailchimpConfigQuery = gql`
  query MailchimpConfig {
    config {
      clientId
      mailchimp {
        clientId
        clientSecret
        redirectUri
      }
    }
  }
`;

function translateError(error) {
  switch (error) {
    case "account_data_not_found":
      return "Nous n’avons pas réussi à récupérer les données de votre compte";
    case "default":
    default:
      return "Une erreur s’est produite, merci de réessayer";
  }
}

export const IntegrationConfigFragment = gql`
  fragment MailchimpIntegrationConfigFragment on MailchimpIntegrationConfig {
    server
    mAccessToken: accessToken
  }
`;

function parseValues(values) {
  const { mAccessToken, ...config } = values;
  return {
    ...config,
    accessToken: mAccessToken,
  };
}

function Fields({ initialValues }) {
  const { data } = useSafeQuery(MailchimpConfigQuery);

  const [{ isLoading, error, pageInfo }, setDataState] = useState({
    isLoading: false,
    error: null,
    pageInfo: null,
  });

  const location = useLocation();
  const locationSearchRef = useRef(location.search);

  useEffect(() => {
    const searchParams = new URLSearchParams(locationSearchRef.current);
    if (searchParams.get("error")) {
      setDataState((prevState) => ({
        ...prevState,
        error: translateError(searchParams.get("error")),
      }));
    }
  }, [locationSearchRef]);

  const integrationIsConfigured = initialValues.mAccessToken;

  // Getting account info when page mounts
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userResponse = await axios.get("/api/mailchimp/user");
        return setDataState((prevState) => ({
          ...prevState,
          pageInfo: userResponse.data,
        }));
      } catch (error) {
        return setDataState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: translateError(error.response.data.error),
        }));
      }
    };

    if (integrationIsConfigured) {
      getUserInfo();
    }
  }, [integrationIsConfigured]);

  if (!data) return null;

  const { config } = data;

  const handleLinkAccount = async () => {
    setDataState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const params = queryString.stringify({
      client_id: config.mailchimp.clientId,
      redirect_uri: config.mailchimp.redirectUri,
      response_type: "code",
      state: config.clientId,
    });
    window.location.href = `https://login.mailchimp.com/oauth2/authorize?${params}`;
  };

  const handleUnlinkAccount = async () => {
    setDataState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      await axios.delete("/api/mailchimp/user");
      return window.location.reload();
    } catch (error) {
      return setDataState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: translateError(error.response.data.error),
      }));
    }
  };

  return (
    <Fieldset>
      <FieldsetField>
        <div className="mb-2 flex items-center justify-between rounded-md border-2 border-grey-border-light p-4">
          <img src={mailchimpLogo} alt="Mailchimp" className="h-10 w-10" />
          <div className="ml-2 grow">
            <div className="font-semibold">Mailchimp</div>
            {integrationIsConfigured ? (
              pageInfo && (
                <div>
                  {pageInfo.login.login_name} ({pageInfo.login.login_email})
                </div>
              )
            ) : (
              <div>
                Vous n’avez pas encore lié votre compte vous permettant
                d’envoyer des campagnes Mailchimp.
              </div>
            )}
          </div>
          <Button
            onClick={
              integrationIsConfigured ? handleUnlinkAccount : handleLinkAccount
            }
            disabled={isLoading}
          >
            {integrationIsConfigured ? "Désinstaller" : "Lier un compte"}
          </Button>
        </div>
        {error && <div className="mb-2 text-danger-on-light">{error}</div>}
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields, parseValues });
