// @ts-check

const SERVICE_DOMAINS = {
  youtube: ["www.youtube.com", "youtu.be"],
  dailymotion: ["www.dailymotion.com", "dai.ly"],
  digiteka: ["www.digiteka.net", "www.ultimedia.com"],
};

/**
 * @param {string[]} services
 */
const getDomainsFromServices = (services) => {
  return services.reduce((domains, service) => {
    const serviceDomains = /** @type {Record<string, string[]>} */ (
      SERVICE_DOMAINS
    )[service];
    if (!serviceDomains) return domains;
    return [...domains, ...serviceDomains];
  }, /** @type {string[]} */ ([]));
};

export const VIDEO_PROVIDERS_DOMAINS = [
  ...SERVICE_DOMAINS["youtube"],
  ...SERVICE_DOMAINS["dailymotion"],
  ...SERVICE_DOMAINS["digiteka"],
];

const type = "videos";

/** @type {import('.').MediaProvider["getData"]} */
function getData({ url, services }) {
  const domains = services
    ? getDomainsFromServices(services)
    : VIDEO_PROVIDERS_DOMAINS;
  try {
    const urlObj = new URL(url);
    const match = domains.some((domain) => urlObj.hostname === domain);
    if (match) return { url };
    return null;
  } catch (error) {
    return null;
  }
}

/** @type {import('.').MediaProvider} */
export const provider = { type, getData };
