import {
  Button as AriakitButton,
  ButtonProps as AriakitButtonProps,
} from "ariakit/button";
import { clsx } from "clsx";
import * as React from "react";

export type ButtonScale = "xs" | "sm" | "md" | "lg";

export type ButtonAppearance = "fill" | "fill-light" | "text";

export type ButtonVariant =
  | "primary"
  | "success"
  | "secondary"
  | "danger"
  | "warning"
  | "ai";

export interface ButtonProps extends AriakitButtonProps {
  /**
   * The scale of the button.
   * Context dependent.
   * @default "md"
   */
  scale?: ButtonScale;

  /**
   * The appearance of the button.
   * Context dependent.
   * @default "fill"
   */
  appearance?: ButtonAppearance;

  /**
   * The variant of the button.
   * Purpose dependent, it will change the color.
   * @default "primary"
   */
  variant?: ButtonVariant;

  /**
   * Use this prop to make the button a square.
   * When it contains only an icon.
   */
  iconOnly?: boolean;

  /**
   * Use this prop to use the child as the button.
   */
  asChild?: boolean;
}

const scales: Record<ButtonScale, string> = {
  lg: /* tw */ `text-base gap-2`,
  md: /* tw */ `text-base gap-2`,
  sm: /* tw */ `text-sm gap-1.5`,
  xs: /* tw */ `text-xs gap-1.5`,
};

const defaultScales: Record<ButtonScale, string> = {
  lg: /* tw */ `px-4 py-2`,
  md: /* tw */ `px-4 py-1`,
  sm: /* tw */ `px-2 py-[0.1875rem]`,
  xs: /* tw */ `px-2 py-[0.1875rem]`,
};

const iconOnlyScales: Record<ButtonScale, string> = {
  lg: /* tw */ `p-3`,
  md: /* tw */ `p-2`,
  sm: /* tw */ `p-[0.375rem]`,
  xs: /* tw */ `p-[0.3125rem]`,
};

const appearances: Record<ButtonAppearance, Record<ButtonVariant, string>> = {
  fill: {
    primary: clsx(
      "text-white bg-primary-bg-strong",
      "focus-visible:ring-primary-border",
      "hover:bg-primary-bg-hover-strong",
      "group-hover/button:bg-primary-bg-hover-strong",
      "active:bg-primary-bg-hover-strong",
      "aria-pressed:bg-primary-bg-hover-strong",
      "aria-expanded:bg-primary-bg-hover-strong",
    ),
    success: clsx(
      "text-white bg-success-bg-strong",
      "focus-visible:ring-success-border",
      "hover:bg-success-bg-hover-strong",
      "group-hover/button:bg-success-bg-hover-strong",
      "active:bg-success-bg-hover-strong",
      "aria-pressed:bg-success-bg-hover-strong",
      "aria-expanded:bg-success-bg-hover-strong",
    ),
    danger: clsx(
      "text-white bg-danger-bg-strong",
      "focus-visible:ring-danger-border",
      "hover:bg-danger-bg-hover-strong",
      "group-hover/button:bg-danger-bg-hover-strong",
      "active:bg-danger-bg-hover-strong",
      "aria-pressed:bg-danger-bg-hover-strong",
      "aria-expanded:bg-danger-bg-hover-strong",
    ),
    warning: clsx(
      "text-white bg-warning-bg-strong",
      "focus-visible:ring-warning-border",
      "hover:bg-warning-bg-hover-strong",
      "group-hover/button:bg-warning-bg-hover-strong",
      "active:bg-warning-bg-hover-strong",
      "aria-pressed:bg-warning-bg-hover-strong",
      "aria-expanded:bg-warning-bg-hover-strong",
    ),
    secondary: clsx(
      "text-white bg-secondary-bg-strong",
      "focus-visible:ring-secondary-border",
      "hover:bg-secondary-bg-hover-strong",
      "group-hover/button:bg-secondary-bg-hover-strong",
      "active:bg-secondary-bg-hover-strong",
      "aria-pressed:bg-secondary-bg-hover-strong",
      "aria-expanded:bg-secondary-bg-hover-strong",
    ),
    ai: clsx(
      "text-white bg-purple-bg-strong",
      "focus-visible:ring-purple-border",
      "hover:bg-purple-bg-hover-strong",
      "group-hover/button:bg-purple-bg-hover-strong",
      "active:bg-purple-bg-hover-strong",
      "aria-pressed:bg-purple-bg-hover-strong",
      "aria-expanded:bg-purple-bg-hover-strong",
    ),
  },
  "fill-light": {
    primary: clsx(
      "text-primary-on bg-primary-bg-light",
      "focus-visible:ring-primary-border",
      "hover:text-primary-on-hover hover:bg-primary-bg-hover-light",
      "group-hover/button:text-primary-on-hover group-hover/button:bg-primary-bg-hover-light",
      "active:text-primary-on-hover active:bg-primary-bg-hover-light",
      "aria-pressed:text-primary-on-hover aria-pressed:bg-primary-bg-hover-light",
      "aria-expanded:text-primary-on-hover aria-expanded:bg-primary-bg-hover-light",
    ),
    success: clsx(
      "text-success-on bg-success-bg-light",
      "focus-visible:ring-success-border",
      "hover:text-success-on-hover hover:bg-success-bg-hover-light",
      "group-hover/button:text-success-on-hover group-hover/button:bg-success-bg-hover-light",
      "active:text-success-on-hover active:bg-success-bg-hover-light",
      "aria-pressed:text-success-on-hover aria-pressed:bg-success-bg-hover-light",
      "aria-expanded:text-success-on-hover aria-expanded:bg-success-bg-hover-light",
    ),
    danger: clsx(
      "text-danger-on bg-danger-bg-light",
      "focus-visible:ring-danger-border",
      "hover:text-danger-on-hover hover:bg-danger-bg-hover-light",
      "group-hover/button:text-danger-on-hover group-hover/button:bg-danger-bg-hover-light",
      "active:text-danger-on-hover active:bg-danger-bg-hover-light",
      "aria-pressed:text-danger-on-hover aria-pressed:bg-danger-bg-hover-light",
      "aria-expanded:text-danger-on-hover aria-expanded:bg-danger-bg-hover-light",
    ),
    warning: clsx(
      "text-warning-on bg-warning-bg-light",
      "focus-visible:ring-warning-border",
      "hover:text-warning-on-hover hover:bg-warning-bg-hover-light",
      "group-hover/button:text-warning-on-hover group-hover/button:bg-warning-bg-hover-light",
      "active:text-warning-on-hover active:bg-warning-bg-hover-light",
      "aria-pressed:text-warning-on-hover aria-pressed:bg-warning-bg-hover-light",
      "aria-expanded:text-warning-on-hover aria-expanded:bg-warning-bg-hover-light",
    ),
    secondary: clsx(
      "text-secondary-on bg-secondary-bg-light",
      "focus-visible:ring-secondary-border",
      "hover:text-secondary-on-hover hover:bg-secondary-bg-hover-light",
      "group-hover/button:text-secondary-on-hover group-hover/button:bg-secondary-bg-hover-light",
      "active:text-secondary-on-hover active:bg-secondary-bg-hover-light",
      "aria-pressed:text-secondary-on-hover aria-pressed:bg-secondary-bg-hover-light",
      "aria-expanded:text-secondary-on-hover aria-expanded:bg-secondary-bg-hover-light",
    ),
    ai: clsx(
      "text-purple-on bg-purple-bg-light",
      "focus-visible:ring-purple-border",
      "hover:text-purple-on-hover hover:bg-purple-bg-hover-light",
      "group-hover/button:text-purple-on-hover group-hover/button:bg-purple-bg-hover-light",
      "active:text-purple-on-hover active:bg-purple-bg-hover-light",
      "aria-pressed:text-purple-on-hover aria-pressed:bg-purple-bg-hover-light",
      "aria-expanded:text-purple-on-hover aria-expanded:bg-purple-bg-hover-light",
    ),
  },
  text: {
    primary: clsx(
      "bg-transparent text-primary-on",
      "focus-visible:ring-primary-border",
      "hover:bg-primary-bg-hover-transparent hover:text-primary-on-hover",
      "group-hover/button:bg-primary-bg-hover-transparent group-hover/button:text-primary-on-hover",
      "active:bg-primary-bg-hover-transparent active:text-primary-on-hover",
      "aria-pressed:bg-primary-bg-hover-transparent aria-pressed:text-primary-on-hover",
      "aria-expanded:bg-primary-bg-hover-transparent aria-expanded:text-primary-on-hover",
    ),
    success: clsx(
      "bg-transparent text-success-on",
      "focus-visible:ring-success-border",
      "hover:bg-success-bg-hover-transparent hover:text-success-on-hover",
      "group-hover/button:bg-success-bg-hover-transparent group-hover/button:text-success-on-hover",
      "active:bg-success-bg-hover-transparent active:text-success-on-hover",
      "aria-pressed:bg-success-bg-hover-transparent aria-pressed:text-success-on-hover",
      "aria-expanded:bg-success-bg-hover-transparent aria-expanded:text-success-on-hover",
    ),
    danger: clsx(
      "bg-transparent text-danger-on",
      "focus-visible:ring-danger-border",
      "hover:bg-danger-bg-hover-transparent hover:text-danger-on-hover",
      "group-hover/button:bg-danger-bg-hover-transparent group-hover/button:text-danger-on-hover",
      "active:bg-danger-bg-hover-transparent active:text-danger-on-hover",
      "aria-pressed:bg-danger-bg-hover-transparent aria-pressed:text-danger-on-hover",
      "aria-expanded:bg-danger-bg-hover-transparent aria-expanded:text-danger-on-hover",
    ),
    warning: clsx(
      "bg-transparent text-warning-on",
      "focus-visible:ring-warning-border",
      "hover:bg-warning-bg-hover-transparent hover:text-warning-on-hover",
      "group-hover/button:bg-warning-bg-hover-transparent group-hover/button:text-warning-on-hover",
      "active:bg-warning-bg-hover-transparent active:text-warning-on-hover",
      "aria-pressed:bg-warning-bg-hover-transparent aria-pressed:text-warning-on-hover",
      "aria-expanded:bg-warning-bg-hover-transparent aria-expanded:text-warning-on-hover",
    ),
    secondary: clsx(
      "bg-transparent text-secondary-on",
      "focus-visible:ring-secondary-border",
      "hover:bg-secondary-bg-hover-transparent hover:text-secondary-on-hover",
      "group-hover/button:bg-secondary-bg-hover-transparent group-hover/button:text-secondary-on-hover",
      "active:bg-secondary-bg-hover-transparent active:text-secondary-on-hover",
      "aria-pressed:bg-secondary-bg-hover-transparent aria-pressed:text-secondary-on-hover",
      "aria-expanded:bg-secondary-bg-hover-transparent aria-expanded:text-secondary-on-hover",
    ),
    ai: clsx(
      "bg-transparent text-purple-on",
      "focus-visible:ring-purple-border",
      "hover:bg-purple-bg-hover-transparent hover:text-purple-on-hover",
      "group-hover/button:bg-purple-bg-hover-transparent group-hover/button:text-purple-on-hover",
      "active:bg-purple-bg-hover-transparent active:text-purple-on-hover",
      "aria-pressed:bg-purple-bg-hover-transparent aria-pressed:text-purple-on-hover",
      "aria-expanded:bg-purple-bg-hover-transparent aria-expanded:text-purple-on-hover",
    ),
  },
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      scale = "md",
      appearance = "fill",
      variant = "primary",
      iconOnly = false,
      asChild,
      className,
      children,
      as,
      ...rest
    } = props;
    return (
      <AriakitButton
        ref={ref}
        className={clsx(
          className,
          /* Base */
          "inline-flex select-none items-center justify-center rounded font-accent font-semibold no-underline transition",
          /* Focus */
          "focus:outline-none focus-visible:ring",
          /* Disabled */
          "disabled:opacity-disabled",
          /* No hover underline */
          "hover:no-underline",
          appearances[appearance][variant],
          scales[scale],
          iconOnly && iconOnlyScales[scale],
          !iconOnly && defaultScales[scale],
        )}
        {...rest}
      >
        {asChild
          ? (buttonProps) =>
              React.cloneElement(
                React.Children.only(children as any),
                buttonProps,
              )
          : children}
      </AriakitButton>
    );
  },
);

export type ButtonGroupProps = React.HTMLAttributes<HTMLDivElement>;

export const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(
  (props, ref) => {
    const { className, ...rest } = props;
    return (
      <div
        ref={ref}
        className={clsx(
          className,
          "inline-flex [&>button:first-child]:rounded-l [&>button:nth-child(2)]:rounded-r [&>button]:rounded-none",
        )}
        {...rest}
      />
    );
  },
);
