import { DocumentNode, gql } from "@apollo/client";
import clsx from "clsx";
import { HTMLAttributes, NamedExoticComponent, forwardRef, memo } from "react";
import { getBetterContrast } from "swash/utils/colors";

export type ArticleStateIconShape = "circle" | "square";

export type ArticleStateIconProps = {
  state?: {
    color: string;
    acronym: string;
    name: string;
  };
  shape?: ArticleStateIconShape;
} & HTMLAttributes<HTMLDivElement>;

export const ArticleStateIcon = memo<ArticleStateIconProps>(
  forwardRef<HTMLDivElement, ArticleStateIconProps>(
    ({ state, className, shape = "circle", ...props }, ref) => {
      const circle = shape === "circle";
      if (!state) return null;
      return (
        <div
          ref={ref}
          className={clsx("relative inline-flex p-px", className)}
          {...props}
        >
          <div
            className={clsx("absolute inset-0", circle && "rounded-full")}
            style={{ backgroundColor: state.color, filter: "brightness(.9)" }}
          />
          <div
            className={clsx("absolute inset-px", circle && "rounded-full")}
            style={{ backgroundColor: state.color }}
          />
          <svg
            width="1em"
            height="1em"
            viewBox="-50 -70 100 100"
            className="relative select-none font-accent font-semibold"
          >
            <text
              textAnchor="middle"
              fontSize="60"
              fill={getBetterContrast(state.color, "#fff", "#090B0F")}
            >
              {state.acronym}
            </text>
          </svg>
        </div>
      );
    },
  ),
) as NamedExoticComponent<ArticleStateIconProps> & {
  fragments: { state: DocumentNode };
};

ArticleStateIcon.fragments = {
  state: gql`
    fragment ArticleStateIcon_state on AbstractWorkflowState {
      color
      acronym
      name
    }
  `,
};
