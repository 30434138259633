import { gql } from "@apollo/client";
import { memo } from "react";
import { Alert } from "swash/Alert";
import { PageLoader } from "swash/Loader";

import { useSafeQuery } from "@/containers/Apollo";
import { ErrorBoundary } from "@/containers/ErrorBoundary";
import { HasPermission } from "@/containers/User";

import { AnalyticsGroup, AnalyticsRow } from "./Group";

const ArticleQuery = gql`
  query AnalyticsPanelForecast_article($id: Int!) {
    article(id: $id) {
      id
      allMetrics: forecastMetrics {
        uniquePageview
        readingRate
        comment
        shareFb
        shareTw
        conversion
      }
      desktopMetrics: forecastMetrics(query: { device: desktop }) {
        uniquePageview
        conversion
      }
      tabletMetrics: forecastMetrics(query: { device: tablet }) {
        uniquePageview
        conversion
      }
      mobileMetrics: forecastMetrics(query: { device: mobile }) {
        uniquePageview
        conversion
      }
      iosMetrics: forecastMetrics(query: { device: mobile_ios }) {
        uniquePageview
        conversion
      }
      androidMetrics: forecastMetrics(query: { device: mobile_android }) {
        uniquePageview
        conversion
      }
      searchEngineMetrics: forecastMetrics(query: { mediumReferrer: search }) {
        uniquePageview
      }
      socialMetrics: forecastMetrics(query: { mediumReferrer: social }) {
        uniquePageview
      }
      internalMetrics: forecastMetrics(query: { mediumReferrer: internal }) {
        uniquePageview
      }
      directMobileMetrics: forecastMetrics(
        query: { mediumReferrer: direct, device: mobile }
      ) {
        uniquePageview
      }
      directDesktopMetrics: forecastMetrics(
        query: { mediumReferrer: direct, device: desktop }
      ) {
        uniquePageview
      }
      directTabletMetrics: forecastMetrics(
        query: { mediumReferrer: direct, device: tablet }
      ) {
        uniquePageview
      }
    }
  }
`;

function Conversions({
  allConversions,
  iosConversions,
  androidConversions,
  desktopConversions,
  tabletConversions,
  mobileConversions,
}) {
  return (
    <AnalyticsGroup title="Conversions" value={allConversions}>
      <AnalyticsRow
        label="Conversions App"
        value={iosConversions + androidConversions}
        labelTooltip="L’évènement conversion est associé au dernier article réservé aux abonnés consulté par le lecteur"
      />
      <AnalyticsRow
        label="Conversions Web"
        value={desktopConversions + tabletConversions + mobileConversions}
        labelTooltip="L’évènement conversion est associé au dernier article réservé aux abonnés consulté par le lecteur"
      />
    </AnalyticsGroup>
  );
}

function PageViews({
  allPageViews,
  iosPageViews,
  androidPageViews,
  desktopPageViews,
  tabletPageViews,
  mobilePageViews,
}) {
  return (
    <AnalyticsGroup title="Visites" value={allPageViews}>
      <AnalyticsRow label="Visites site mobile" value={mobilePageViews} />
      <AnalyticsRow
        label="Visites desktop"
        value={desktopPageViews + tabletPageViews}
      />
      <AnalyticsRow
        label="Visites application"
        value={iosPageViews + androidPageViews}
      />
    </AnalyticsGroup>
  );
}

function MediumReferrer({
  searchEnginePageViews,
  socialPageViews,
  internalPageViews,
  directPageViews,
  webPageViews,
}) {
  return (
    <AnalyticsGroup title="Sources de trafic">
      <AnalyticsRow
        label="Moteurs"
        value={
          searchEnginePageViews && webPageViews
            ? `${Math.floor((searchEnginePageViews / webPageViews) * 100)}%`
            : null
        }
        labelTooltip="Part du trafic web en provenance des moteurs de recherche (Google...)"
      />
      <AnalyticsRow
        label="Réseaux sociaux"
        value={
          socialPageViews && webPageViews
            ? `${Math.floor((socialPageViews / webPageViews) * 100)}%`
            : null
        }
        labelTooltip="Part du trafic web en provenance des réseaux sociaux (Facebook, Twitter...)"
      />
      <AnalyticsRow
        label="Circulation interne"
        value={
          internalPageViews && webPageViews
            ? `${Math.floor((internalPageViews / webPageViews) * 100)}%`
            : null
        }
        labelTooltip="Part du trafic web en provenance d’une autre page du site (homepage ou autre)"
      />
      <AnalyticsRow
        label="Dark social"
        value={
          directPageViews && webPageViews
            ? `${Math.floor((directPageViews / webPageViews) * 100)}%`
            : null
        }
        labelTooltip="Part du trafic web de provenance indéterminée, souvent lié à des partages par messagerie ou des services de type Apple News"
      />
    </AnalyticsGroup>
  );
}

function Commitment({ readingRate, comment, shareFb, shareTw }) {
  return (
    <AnalyticsGroup title="Engagement">
      <AnalyticsRow
        label="% lu de l’article"
        value={`${readingRate}%`}
        labelTooltip="Indique en moyenne jusqu’où les lecteurs scrollent dans l’article"
      />
      <AnalyticsRow label="Nb commentaires" value={comment} />
      <AnalyticsRow label="Nb partages facebook" value={shareFb} />
      <AnalyticsRow label="Nb de partages twitter" value={shareTw} />
    </AnalyticsGroup>
  );
}

const MissingForecastData = memo(() => {
  return (
    <Alert>
      Les données provenant de Forecast sont incomplètes et ne peuvent être
      affichées.
    </Alert>
  );
});

const ForecastAnalyticsDisplay = ({ articleId }) => {
  const { data } = useSafeQuery(ArticleQuery, {
    variables: { id: articleId },
    fetchPolicy: "network-only",
    pollInterval: 60000, // poll every minute
  });

  if (!data) return <PageLoader />;

  const {
    allMetrics,
    iosMetrics,
    androidMetrics,
    desktopMetrics,
    tabletMetrics,
    mobileMetrics,
    searchEngineMetrics,
    socialMetrics,
    internalMetrics,
    directMobileMetrics,
    directDesktopMetrics,
    directTabletMetrics,
  } = data.article;

  const incompleteData = [
    allMetrics,
    iosMetrics,
    androidMetrics,
    desktopMetrics,
    tabletMetrics,
    mobileMetrics,
    searchEngineMetrics,
    socialMetrics,
    internalMetrics,
    directMobileMetrics,
    directDesktopMetrics,
    directTabletMetrics,
  ].some((data) => data === null);

  if (incompleteData) {
    return <MissingForecastData />;
  }

  return (
    <>
      <Conversions
        allConversions={allMetrics.conversion}
        iosConversions={iosMetrics.conversion}
        androidConversions={androidMetrics.conversion}
        desktopConversions={desktopMetrics.conversion}
        tabletConversions={tabletMetrics.conversion}
        mobileConversions={mobileMetrics.conversion}
      />

      <PageViews
        allPageViews={allMetrics.uniquePageview}
        iosPageViews={iosMetrics.uniquePageview}
        androidPageViews={androidMetrics.uniquePageview}
        desktopPageViews={desktopMetrics.uniquePageview}
        tabletPageViews={tabletMetrics.uniquePageview}
        mobilePageViews={mobileMetrics.uniquePageview}
      />

      <MediumReferrer
        searchEnginePageViews={searchEngineMetrics.uniquePageview}
        socialPageViews={socialMetrics.uniquePageview}
        internalPageViews={internalMetrics.uniquePageview}
        directPageViews={
          directMobileMetrics.uniquePageview +
          directDesktopMetrics.uniquePageview +
          directTabletMetrics.uniquePageview
        }
        webPageViews={
          desktopMetrics.uniquePageview +
          tabletMetrics.uniquePageview +
          mobileMetrics.uniquePageview
        }
      />

      <Commitment
        readingRate={allMetrics.readingRate}
        comment={allMetrics.comment}
        shareFb={allMetrics.shareFb}
        shareTw={allMetrics.shareTw}
      />
    </>
  );
};

const ForecastEnabledQuery = gql`
  query ArticleAnalyticsPanel_thirdPartyService {
    thirdPartyService(key: forecast) {
      enabled
    }
  }
`;

export const ForecastAnalytics = ({ articleId }) => {
  const { data } = useSafeQuery(ForecastEnabledQuery);
  if (!data) return null;
  return (
    <HasPermission permission="stats:full">
      {data.thirdPartyService.enabled && (
        <ErrorBoundary
          render={() => <MissingForecastData />}
          shouldCaptureError={() => false}
        >
          <ForecastAnalyticsDisplay articleId={articleId} />
        </ErrorBoundary>
      )}
    </HasPermission>
  );
};
