import clsx from "clsx";
import type { HTMLAttributes } from "react";

export type ListProps = HTMLAttributes<HTMLUListElement>;

export const List = ({ className, ...props }: ListProps) => (
  <ul
    className={clsx(
      className,
      "flex list-none flex-col gap-px",
      "[&>li]:flex [&>li]:items-center",
    )}
    {...props}
  />
);
