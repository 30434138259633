import { gql } from "@apollo/client";
import { useMemo, useState } from "react";
import { useFormState } from "react-final-form";

import { useSubscribePreviousFormValues } from "@/components/forms/FormSubscribe";
import { EnumField, useNodesToEnumArray } from "@/containers/admin/CRUD";

export function PeriodicalField({
  required,
  disabled,
  periodicals,
  name = "periodicalId",
  label = "Publication",
  placeholder = "Sélectionnez une publication",
  modal,
  selectableDisabled,
}) {
  const {
    values: { periodicalId },
  } = useFormState({ subscription: { values: true } });
  const [initialValue] = useState(periodicalId);
  const values = useMemo(
    () =>
      periodicals?.nodes.filter(
        ({ id, enabled }) =>
          selectableDisabled || enabled || initialValue === id,
      ),
    [initialValue, periodicals?.nodes, selectableDisabled],
  );
  const enumValues = useNodesToEnumArray(values ?? []);
  const clearable = !location.pathname.includes("/scheduler/print");

  useSubscribePreviousFormValues(({ values, previousValues, form }) => {
    if (values.periodicalId !== previousValues.periodicalId) {
      const isPeriodicalEnabled = !!enumValues.find(
        (item) => item.value === values.periodicalId,
      );
      if (!isPeriodicalEnabled) {
        form.change("periodicalId", null);
        form.change("periodicalEditionDate", null);
      }
    }
  });

  return (
    <EnumField
      name={name}
      label={label}
      placeholder={placeholder}
      enum={enumValues}
      clearable={clearable}
      modal={modal}
      disabled={disabled}
      required={required}
      labelSelector={(item) => item.label}
      sortEntries={(itemA, itemB) => itemA.rank - itemB.rank}
    />
  );
}

export const PeriodicalFieldPeriodicalFragment = gql`
  fragment PeriodicalFieldPeriodicalFragment on Periodical {
    id
    label
    enabled
    rank
  }
`;
