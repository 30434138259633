import { gql, useApolloClient, useMutation } from "@apollo/client";
import deepEqual from "deep-equal";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Button } from "swash/Button";
import { Headphone, IoTrash } from "swash/Icon";
import { Loader } from "swash/Loader";
import { useToaster } from "swash/Toast";

import { Ellipsis } from "@/components/Ellipsis";
import {
  usePlayerUrl,
  useSetPlayerUrl,
} from "@/components/audio-player/AudioPlayerContext";
import { calculateTime } from "@/components/audio-player/utils";
import { FileDrop, useFileDropState } from "@/components/controls/FileDrop";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import {
  FileDropField,
  useFileDropField,
} from "@/components/fields/FileDropField";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { Redo } from "@/components/icons";
import { QueueProvider, useQueue } from "@/components/queue/QueueProvider";
import { useSafeQuery } from "@/containers/Apollo";

const MAX_FILE_SIZE = 30; // in Mega bytes

const AudioFieldAudioFragment = gql`
  fragment AudioFieldAudioFragment on Audio {
    name
    url
    encoding
    duration
    size
  }
`;

const CreateAudioMutation = gql`
  mutation AudioFieldCreateAudioMutation($input: CreateAudioInput!) {
    createAudio(input: $input) {
      id
      ...AudioFieldAudioFragment
    }
  }
  ${AudioFieldAudioFragment}
`;

const AudiosQuery = gql`
  query AudiosFieldAudioQuery($ids: [Int!]!) {
    audios(where: { id: { in: $ids } }) {
      nodes {
        id
        ...AudioFieldAudioFragment
      }
    }
  }
  ${AudioFieldAudioFragment}
`;

const Audio = ({ audio, onRemove }) => {
  const setPlayerUrl = useSetPlayerUrl();
  const currentPlayerUrl = usePlayerUrl();
  return (
    <div className="flex items-center justify-center">
      <Ellipsis title={audio.name} w={400} pr={2}>
        {audio.name}
      </Ellipsis>
      <div className="flex items-center justify-center gap-2">
        <div className="text-xs text-grey-on">
          {calculateTime(audio.duration)}
        </div>
        <Button
          type="button"
          aria-label="Écouter l’audio"
          variant="secondary"
          appearance="text"
          scale="sm"
          iconOnly
          onClick={(event) => {
            event.stopPropagation();
            setPlayerUrl(audio.url);
          }}
        >
          <Headphone />
        </Button>
        <Button
          type="button"
          aria-label="Supprimer le ficher"
          variant="danger"
          scale="sm"
          iconOnly
          onClick={(event) => {
            event.stopPropagation();
            if (currentPlayerUrl === audio.url) {
              setPlayerUrl(null);
            }
            onRemove(audio);
          }}
        >
          <IoTrash />
        </Button>
      </div>
    </div>
  );
};

const fetchAudio = async (file) => {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(file);
    const audio = new window.Audio(url);
    audio.addEventListener("loadedmetadata", () => {
      resolve(audio);
    });
  });
};

const AudiosDropControl = ({ disabled, ...props }) => {
  const toaster = useToaster();
  const apolloClient = useApolloClient();
  const drop = useFileDropState(props);
  const { queue } = useQueue();
  const lastDroppedAudiosRef = useRef([]);
  const { name, onChange, placeholder } = drop.state;
  const selectedAudioIds = useSubscribeFormValue(name);

  const { data, loading: queryLoading } = useSafeQuery(AudiosQuery, {
    skip: !selectedAudioIds.length,
    variables: { ids: selectedAudioIds },
  });

  const [createAudio, { loading: mutationLoading }] =
    useMutation(CreateAudioMutation);

  const loading = mutationLoading || queryLoading;

  useEffect(() =>
    queue.listen("end", () => {
      const data = apolloClient.readQuery({
        query: AudiosQuery,
        variables: { ids: selectedAudioIds },
      });
      const cachedAudioNodes = data?.audios.nodes ?? [];
      const audioIds = [
        ...selectedAudioIds,
        ...lastDroppedAudiosRef.current.map(({ id }) => id),
      ];

      // populate next query cache
      apolloClient.writeQuery({
        query: AudiosQuery,
        variables: { ids: audioIds },
        data: {
          audios: {
            nodes: [...cachedAudioNodes, ...lastDroppedAudiosRef.current],
            __typename: "AudioConnection",
          },
        },
      });
      onChange(audioIds);
    }),
  );

  const handleDrop = useCallback(
    async (files) => {
      lastDroppedAudiosRef.current = [];
      const tasks = files.map((file) => async () => {
        try {
          const { size, type: encoding } = file;

          const audio = await fetchAudio(file);
          const duration = Math.round(Number(audio.duration));
          audio.remove();

          const { data, error } = await createAudio({
            variables: {
              input: {
                file,
                duration,
                size,
                encoding,
              },
            },
          });
          if (error) throw error;
          lastDroppedAudiosRef.current.push(data.createAudio);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          toaster.warning(
            `Le fichier "${file.name}" n’a pas pu être importé. Veuillez réessayer.`,
          );
        }
      });
      queue.enqueue(tasks);
      queue.start();
    },
    [createAudio, queue, toaster],
  );

  const handleRemove = useCallback(
    (audio) => {
      const filteredValues = selectedAudioIds.filter((id) => id !== audio.id);

      const data = apolloClient.readQuery({
        query: AudiosQuery,
        variables: { ids: selectedAudioIds },
      });

      // Populate the next cache
      apolloClient.writeQuery({
        query: AudiosQuery,
        variables: { ids: filteredValues },
        data: {
          ...data,
          audios: {
            ...(data?.audios ?? []),
            nodes: data.audios.nodes.filter((a) => a.id !== audio.id),
          },
          __typename: "AudioConnection",
        },
      });

      onChange(filteredValues);
    },
    [apolloClient, onChange, selectedAudioIds],
  );

  const audios = useMemo(() => data?.audios.nodes ?? [], [data]);

  return (
    <FileDrop
      {...drop.state}
      onChange={handleDrop}
      accept={{ "audio/*": [".mp3", ".wav"] }}
      maxFileSize={MAX_FILE_SIZE}
      type="audio"
      disabled={disabled}
      data-field-control
      multiple
    >
      <div>
        {audios.length ? (
          <div className="flex flex-col gap-2 overflow-auto">
            {audios.map((audio) => (
              <Audio key={audio.id} audio={audio} onRemove={handleRemove} />
            ))}
            {loading && <Loader />}
          </div>
        ) : loading ? (
          <Loader />
        ) : (
          <>
            <Redo width="0.8em" height="0.8em" />{" "}
            {placeholder ||
              `Glisser ici un audio MP3 ou WAV de ${MAX_FILE_SIZE} Mo max ou cliquer pour sélectionner un audio`}
          </>
        )}
      </div>
    </FileDrop>
  );
};

export function AudiosField({
  name,
  format,
  parse,
  required,
  label,
  hint,
  placeholder,
  disabled,
}) {
  const field = useFileDropField(name, {
    required,
    format,
    parse,
    label,
    isEqual: deepEqual,
  });

  return (
    <QueueProvider>
      <FieldGroup {...field}>
        <FieldLabel {...field}>{label}</FieldLabel>
        <FieldError {...field} />
        {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
        <FileDropField
          {...field}
          as={AudiosDropControl}
          placeholder={placeholder}
          disabled={disabled}
        />
      </FieldGroup>
    </QueueProvider>
  );
}
