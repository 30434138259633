import { gql } from "@apollo/client";
import { print } from "graphql";
import { createContext, useContext, useMemo } from "react";

type User = {
  id: number;
  firstNameInitials: string;
  lastName: string;
};
type AuditTrail = {
  id: number;
  user: User;
  __typename: string;
};

type ArticleAuditTrail = {
  editorialLevel?: AuditTrail | null;
  editorialFormat?: AuditTrail | null;
  nbCharMax?: AuditTrail | null;
  publicationDate?: AuditTrail | null;
};

type ArticleAuditTrailField = keyof ArticleAuditTrail;

const ArticleAuditTrailsContext = createContext<ArticleAuditTrail>({});

type AuditTrailFieldWithSufix = `${ArticleAuditTrailField}_auditTrail`;

export type Article = {
  [key in AuditTrailFieldWithSufix]: AuditTrail;
} & { __typename: string; creator: User; rawNbCharMax: number };

type ArticleAuditTrailsProviderProps = {
  children: React.ReactNode;
  article: Article;
};

const ARTICLE_AUDIT_TRAIL_FIELDS = [
  "editorialLevel",
  "editorialFormat",
  "nbCharMax",
  "publicationDate",
];

export const ArticleAuditTrailsProvider = ({
  article,
  children,
}: ArticleAuditTrailsProviderProps) => {
  const value = useMemo(() => {
    if (!article) return {};
    return Object.entries(article).reduce((acc, [key, value]) => {
      if (!key.includes("_auditTrail")) return acc;
      const sanitizedKey = key.replace(/_auditTrail/, "");
      const resolvedValue = (() => {
        switch (sanitizedKey) {
          case "editorialLevel":
          case "publicationDate": {
            return value ?? { user: article.creator };
          }
          case "editorialFormat": {
            return (
              value ??
              (article[sanitizedKey as keyof Article]
                ? { user: article.creator }
                : null)
            );
          }
          case "nbCharMax": {
            return (
              value ??
              (article?.rawNbCharMax > 0 ? { user: article.creator } : null)
            );
          }
          default: {
            throw new Error(
              `Unhandled field: available fields are ${ARTICLE_AUDIT_TRAIL_FIELDS.join(
                ", ",
              )}`,
            );
          }
        }
      })();

      return {
        ...acc,
        [sanitizedKey]: resolvedValue,
      };
    }, {});
  }, [article]);

  return (
    <ArticleAuditTrailsContext.Provider value={value}>
      {children}
    </ArticleAuditTrailsContext.Provider>
  );
};

export const useArticleAuditTrail = (field: ArticleAuditTrailField) => {
  const auditTrails = useContext(ArticleAuditTrailsContext);
  if (auditTrails === undefined) return null;
  if (!field) return null;
  return auditTrails[field] ?? null;
};

const UserFragment = gql`
  fragment AuditTrail_user on User {
    firstNameInitials
    lastName
  }
`;

export const AuditTrailFragment = gql`
  fragment ArticleAuditTrailFragment on AuditTrail {
    user {
      id
      ...AuditTrail_user
    }
  }
  ${UserFragment}
`;

export const ArticleAuditTrailFragment = gql`
  fragment AuditTrail_article on Article {
    editorialLevel_auditTrail: lastAuditTrail(
      where: { fields: { eq: "editorialLevel" } }
    ) {
      id
      ...ArticleAuditTrailFragment
    }
    editorialFormat_auditTrail: lastAuditTrail(
      where: { fields: { eq: "editorialFormatId" } }
    ) {
      id
      ...ArticleAuditTrailFragment
    }
    nbCharMax_auditTrail: lastAuditTrail(
      where: { fields: { eq: "nbCharMax" } }
    ) {
      id
      ...ArticleAuditTrailFragment
    }
    publicationDate_auditTrail: lastAuditTrail(
      where: { fields: { in: ["planning.date", "isEmbargo", "isUrgent"] } }
    ) {
      id
      ...ArticleAuditTrailFragment
    }
    creator {
      id
      ...AuditTrail_user
    }
    rawNbCharMax
  }
  ${AuditTrailFragment}
  ${UserFragment}
`;

export const getArticleAuditTrailFragment = (
  fields: ArticleAuditTrailField[],
  seed: string,
) => {
  if (
    !fields ||
    !fields?.length ||
    fields.some((field) => !ARTICLE_AUDIT_TRAIL_FIELDS.includes(field))
  ) {
    throw new Error(
      `fields array is required: available fields are ${ARTICLE_AUDIT_TRAIL_FIELDS.join(
        ", ",
      )}`,
    );
  }

  const [rootDefinition, ...restDefinitions]: any[] = [
    ...ArticleAuditTrailFragment.definitions,
  ];

  const fieldsSelections = rootDefinition.selectionSet.selections.map(
    (selection: any) => {
      if (selection.name.value !== "lastAuditTrail") return selection;
      const alias = selection.alias.value;
      const skip = !fields.some((field) => alias.includes(field));
      const skipDirective = {
        kind: "Directive",
        name: { kind: "Name", value: "skip" },
        arguments: [
          {
            kind: "Argument",
            name: { kind: "Name", value: "if" },
            value: { kind: "BooleanValue", value: skip },
          },
        ],
      };

      return { ...selection, directives: [skipDirective] };
    },
  );

  const fragment = {
    ...ArticleAuditTrailFragment,
    definitions: [
      {
        ...rootDefinition,
        name: {
          kind: "Name",
          value: `AuditTrail_${seed}_article`,
        },
        selectionSet: {
          ...rootDefinition.selectionSet,
          selections: fieldsSelections,
        },
      },
      ...restDefinitions,
    ],
  };

  return print(fragment);
};

export const useArticleAuditTrailTooltip = (field: ArticleAuditTrailField) => {
  const auditTrail = useArticleAuditTrail(field);
  if (!auditTrail) return null;

  const { user } = auditTrail;

  return `Renseigné par ${user.firstNameInitials} ${user.lastName}`;
};
