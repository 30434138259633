import clsx from "clsx";

export const ChannelAccounts = (props) => (
  <ul
    className="ml-2 inline-flex gap-2"
    style={{ verticalAlign: "-10%" }}
    {...props}
  />
);

export const ChannelAccountPicture = ({ className, alt, ...props }) => (
  <img className={clsx(className, "h-4 w-4 rounded-sm")} alt={alt} {...props} />
);
