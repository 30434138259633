import * as Sentry from "@sentry/browser";
import styled, { x } from "@xstyled/styled-components";
import clsx from "clsx";
import { useState } from "react";
import { Button } from "swash/Button";
import { IoCloudOffline } from "swash/Icon";

import { LogAmplitudeErrorDisplay } from "@/containers/Amplitude";

import { ErrorPage, ErrorPageTitle } from "./ErrorPage";

const debug = process.env["NODE_ENV"] !== "production";

const useIsOnlineAtMount = () => {
  const [onLine] = useState(navigator.onLine);
  return onLine;
};

export function DebugMessage({ error }) {
  return (
    debug && (
      <div
        style={{
          backgroundColor: "#eee",
          padding: 16,
          margin: "32px 0",
          overflowX: "auto",
        }}
      >
        <strong>{error.message}</strong>
        <pre style={{ margin: 0, padding: 0, whiteSpace: "pre-wrap" }}>
          {error.stack}
        </pre>
      </div>
    )
  );
}

export function ReportErrorButton({ eventId }) {
  return (
    <Button
      type="button"
      variant="secondary"
      appearance="text"
      onClick={() => {
        Sentry.showReportDialog({
          lang: "fr",
          title: "Un problème est survenu",
          eventId,
        });
      }}
    >
      Signaler le problème
    </Button>
  );
}

export function GenericErrorControls({
  error,
  eventId,
  retry,
  mode = "reload",
  justifyContent,
}) {
  const onLine = useIsOnlineAtMount();
  return (
    <div>
      <x.div display="flex" justifyContent={justifyContent} spaceX={2}>
        {(() => {
          switch (mode) {
            case "retry":
              return (
                <Button
                  type="button"
                  onClick={() => {
                    retry();
                  }}
                >
                  Recharger l’élément
                </Button>
              );
            case "reload":
            default:
              return (
                <Button
                  type="button"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Recharger la page
                </Button>
              );
          }
        })()}
        {onLine && <ReportErrorButton eventId={eventId} />}
      </x.div>
      <DebugMessage error={error} />
    </div>
  );
}

const ErrorMessageParagraph = styled.p`
  margin: 0;
  margin-bottom: 2;
`;

export function ErrorMessage({ as: As = ErrorMessageParagraph }) {
  const onLine = useIsOnlineAtMount();
  return (
    <As>
      {onLine ? (
        <>
          <span role="img" aria-label="Triste">
            😰
          </span>{" "}
          Désolé, une erreur est survenue !
        </>
      ) : (
        <>
          <IoCloudOffline style={{ verticalAlign: "sub" }} /> Vous n’êtes pas
          connecté à internet
        </>
      )}
    </As>
  );
}

export const ErrorBlock = ({ className, ...props }) => (
  <div className={clsx(className, "p-2 text-center")} {...props} />
);

export function GenericErrorBlock({ error, eventId, retry, mode }) {
  return (
    <ErrorBlock>
      <LogAmplitudeErrorDisplay errorType="block" error={error} />
      <ErrorMessage />
      <GenericErrorControls
        error={error}
        eventId={eventId}
        justifyContent="center"
        mode={mode}
        retry={retry}
      />
    </ErrorBlock>
  );
}

export function GenericErrorPage({ error, eventId, mode, retry }) {
  const onLine = useIsOnlineAtMount();
  return (
    <ErrorPage showDragon={onLine}>
      <LogAmplitudeErrorDisplay errorType="page" error={error} />
      <ErrorMessage as={ErrorPageTitle} />
      <GenericErrorControls
        error={error}
        eventId={eventId}
        mode={mode}
        retry={retry}
      />
    </ErrorPage>
  );
}
