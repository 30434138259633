import { gql } from "@apollo/client";
import { memo } from "react";
import {
  IoAtCircle,
  IoCheckmarkCircle,
  IoPersonCircleOutline,
} from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { Image, ImageFixedFragment } from "@/components/Image";

const Avatar = ({ author, size }) => {
  if (author.image) {
    return (
      <Image
        {...author.image.fixed}
        width={size}
        height={size}
        borderRadius="50%"
      />
    );
  }
  return <IoPersonCircleOutline size={size} />;
};

const firstUpper = (s) => s[0].toUpperCase() + s.slice(1);

/** @type {React.FC<{ author: object, scale?: string }> & { fragments: { author: import("graphql").DocumentNode }}} */
export const AuthorAvatar = memo(({ author }) => {
  const tooltip = [
    author.verified ? "signataire vérifié" : null,
    author.user ? "lié à un compte utilisateur" : null,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <Tooltip tooltip={tooltip ? firstUpper(tooltip) : null}>
      <div className="relative flex items-center justify-center">
        <Avatar author={author} size={20} />
        {author.verified && (
          <span className="absolute right-0 top-0 text-primary-on">
            <span className="absolute inset-0 rounded bg-white" />
            <IoCheckmarkCircle size={10} className="relative" />
          </span>
        )}
        {author.user && (
          <span className="absolute bottom-0 right-0 text-primary-on">
            <span className="absolute inset-0 rounded bg-white" />
            <IoAtCircle size={10} className="relative" />
          </span>
        )}
      </div>
    </Tooltip>
  );
});

AuthorAvatar.fragments = {
  author: gql`
    fragment AuthorAvatar_author on Author {
      verified
      user {
        id
      }
      image {
        id
        fixed(width: 24, height: 24) {
          ...ImageFixedFragment
        }
      }
    }

    ${ImageFixedFragment}
  `,
};
