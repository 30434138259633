/* eslint-disable graphql/template-strings */
import { gql, useMutation, useSubscription } from "@apollo/client";
import { useMemo } from "react";
import { PageLoader } from "swash/Loader";

import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { FormSubmittingPrompt } from "@/components/forms/FormPrompt";
import { useSafeQuery } from "@/containers/Apollo";
import {
  formatCustomFields,
  parseCustomFields,
} from "@/containers/custom-fields/formatters";

import { PrintCustomFields } from "./fields/PrintCustomFields";
import { PrintFields, PrintFieldsQueryFragment } from "./fields/PrintFields";

const ArticleFragment = gql`
  fragment PrintMetaForm_article on Article {
    id
    periodicalId
    periodicalSectionId
    periodicalSectionLayoutId
    periodicalReleaseId
    periodicalEditionDate
    customFields {
      customFieldId
      name
      type
      value {
        text
        date
        tag
        customTypeContent
        image
        file
        article
        richText {
          id
          type
          text
          metadata
        }
        boolean
      }
      rank
    }
  }
`;

const ArticleQuery = gql`
  query PrintMetaForm_article($id: Int!, $periodicalWhere: PeriodicalWhere) {
    article(id: $id) {
      ...PrintMetaForm_article
    }
    periodicals(where: $periodicalWhere) {
      nodes {
        id
        ...PrintFieldsQueryFragment
      }
    }
  }

  ${ArticleFragment}
  ${PrintFieldsQueryFragment}
`;
const UpdateArticleMutation = gql`
  mutation PrintMetaForm_updateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      ...PrintMetaForm_article
    }
  }
  ${ArticleFragment}
`;

const ArticleUpdatedSubscription = gql`
  subscription PrintMetaForm_articleUpdated($id: Int!) {
    articleUpdated(where: { id: { eq: $id } }) {
      ...PrintMetaForm_article
    }
  }
  ${ArticleFragment}
`;

const FormBody = ({ data, editorialTypeId, children }) => {
  return children({
    renderFields: ({ disabled }) => (
      <>
        <PrintFields disabled={disabled} data={data} />
        <PrintCustomFields
          disabled={disabled}
          data={data}
          editorialTypeId={editorialTypeId}
        />
      </>
    ),
    periodicalExportDisabledIds: data.periodicals.nodes
      .map((periodical) =>
        periodical.exportDisabled ? periodical.id : undefined,
      )
      .filter(Boolean),
  });
};

export const PrintMetaForm = ({
  articleId,
  editorialTypeId,
  onExport,
  children,
}) => {
  const { data } = useSafeQuery(ArticleQuery, {
    variables: {
      id: articleId,
      periodicalWhere: { digital: false },
    },
    fetchPolicy: "network-only",
  });
  useSubscription(ArticleUpdatedSubscription, {
    variables: { id: articleId },
  });

  const article = data?.article ?? null;
  const [updateArticle] = useMutation(UpdateArticleMutation);

  const initialValues = useMemo(() => {
    if (!article) return {};
    return {
      periodicalId: article.periodicalId,
      periodicalSectionId: article.periodicalSectionId,
      periodicalSectionLayoutId: article.periodicalSectionLayoutId,
      periodicalReleaseId: article.periodicalReleaseId,
      periodicalEditionDate: article.periodicalEditionDate,
      customFields: formatCustomFields(article.customFields),
    };
  }, [article]);

  const handleSubmit = async (values, form, ctx) => {
    const { dirty, submitSucceeded, dirtySinceLastSubmit } = form.getState();
    const shouldUpdate = submitSucceeded ? dirtySinceLastSubmit : dirty;

    if (shouldUpdate) {
      await updateArticle({
        variables: {
          input: {
            id: articleId,
            ...values,
            customFields: parseCustomFields(values.customFields),
          },
        },
      });
    }

    if (ctx.submitter?.name === "export-button") {
      form.reset(values);
      await onExport();
    }
  };

  if (!data) return <PageLoader />;

  return (
    <Form
      collaborative
      onSubmit={handleSubmit}
      initialValues={initialValues}
      className="contents"
    >
      <FormAutoSubmit />
      <FormSubmittingPrompt />
      <FormBody data={data} editorialTypeId={editorialTypeId}>
        {children}
      </FormBody>
    </Form>
  );
};

export const StandalonePrintMetaForm = ({
  articleId,
  editorialTypeId,
  disabled,
}) => {
  return (
    <PrintMetaForm articleId={articleId} editorialTypeId={editorialTypeId}>
      {({ renderFields }) => renderFields({ disabled })}
    </PrintMetaForm>
  );
};
