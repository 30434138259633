/* eslint-disable react/prop-types */
import { forwardRef } from "react";
import { useField, useForm } from "react-final-form";
import { Textbox } from "swash/controls/Textbox";

import { FieldControl } from "@/components/fields/FieldControl";
import {
  composeValidators,
  mustBeEmail,
  mustBeFilled,
  mustBePhoneNumber,
  mustBeURL,
  mustHaveMaxLength,
  mustHaveMinLength,
} from "@/services/forms/validators";

import { useFieldState } from "./FieldState";

export const useTextInputField = (
  name,
  {
    required = false,
    validate: validateOption,
    formatBeforeValidate,
    id,
    orientation = "vertical",
    minLength,
    maxLength,
    format,
    "aria-label": ariaLabel,
    ...options
  } = {},
) => {
  const form = useForm();
  const validators = [];
  if (validateOption) {
    validators.push(validateOption);
  }
  if (required) {
    validators.push(mustBeFilled);
  }
  if (options.type === "email") {
    validators.push(mustBeEmail);
  }
  if (options.type === "phone-number") {
    validators.push(mustBePhoneNumber(options.regionCode));
  }
  if (options.type === "url") {
    validators.push(mustBeURL);
  }
  if (minLength != null) {
    validators.push(mustHaveMinLength(minLength));
  }
  if (maxLength != null) {
    validators.push(mustHaveMaxLength(maxLength));
  }

  const composedValidate = validators.length
    ? composeValidators(...validators)
    : undefined;

  const validate =
    composedValidate && formatBeforeValidate
      ? (value, ...args) => composedValidate(format(value), ...args)
      : composedValidate;

  const field = useField(name, { validate, format, ...options });

  const resetField = (name) => {
    const {
      meta: { initial },
    } = field;
    form.change(name, initial);
  };

  return useFieldState({
    "aria-label": ariaLabel,
    field,
    id,
    orientation,
    required,
    minLength,
    maxLength,
    resetField,
  });
};

export const TextInputField = forwardRef(({ as = Textbox, ...props }, ref) => {
  return <FieldControl ref={ref} as={as} {...props} />;
});
