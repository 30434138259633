/* eslint-disable react/prop-types */
import { forwardRef } from "react";
import { useField } from "react-final-form";
import { Radio, RadioGroup, RadioLabel } from "swash/controls/Radio";

import { composeValidators, mustBeFilled } from "@/services/forms/validators";

import { FieldControl } from "./FieldControl";
import { useFieldState } from "./FieldState";

export function useRadioGroupField(
  name,
  {
    required = false,
    validate,
    id,
    orientation = "horizontal",
    ...options
  } = {},
) {
  const validators = [];
  if (validate) {
    validators.push(validate);
  }
  if (required) {
    validators.push(mustBeFilled);
  }
  const field = useField(name, {
    type: "radio",
    validate: validators.length ? composeValidators(...validators) : undefined,
    ...options,
  });

  return useFieldState({
    field,
    id,
    orientation,
    required,
    fieldOptions: { name, ...options },
  });
}

export function useRadioField(
  {
    state: {
      fieldOptions: { name, ...fieldGroupOptions },
    },
  },
  { disabled, ...fieldOptions } = {},
) {
  const field = useField(name, {
    type: "radio",
    ...fieldGroupOptions,
    ...fieldOptions,
  });

  return useFieldState({ field, disabled });
}

export const RadioGroupField = forwardRef(({ state, ...props }, ref) => {
  return <RadioGroup ref={ref} data-field-control {...props} />;
});

export const RadioField = forwardRef(
  ({ children, size = "base", ...props }, ref) => {
    return (
      <FieldControl ref={ref} {...props}>
        {({ "data-field-control": dataFieldControl, ...fieldControlProps }) => (
          <RadioLabel data-scale={size}>
            <Radio {...fieldControlProps} />
            {children}
          </RadioLabel>
        )}
      </FieldControl>
    );
  },
);
