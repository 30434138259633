import {
  Dialog as AriakitDialog,
  DialogDisclosure,
  DialogProps,
  DialogStateProps,
  useDialogState as useAriakitDialogState,
} from "ariakit/dialog";
import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

import { Panel } from "./Panel";

export const DialogPanelDisclosure = DialogDisclosure;

export function useDialogPanelState(props: DialogStateProps) {
  return useAriakitDialogState({
    animated: true,
    ...props,
  });
}

export type DialogPanelProps = DialogProps & {
  animation?: "from-right" | "from-right-complete";
  fullScreen?: boolean;
};

export const DialogPanel = forwardRef<HTMLDivElement, DialogPanelProps>(
  (
    {
      state,
      className,
      fullScreen = true,
      children,
      animation = "from-right",
      ...props
    },
    ref,
  ) => {
    return (
      <AriakitDialog
        ref={ref}
        className={clsx(
          "swash-dialog-panel",
          fullScreen && "fixed right-0 top-0 z-dialog-panel h-screen",
          className,
        )}
        state={state}
        modal={false}
        hideOnInteractOutside={false}
        autoFocusOnShow={false}
        autoFocusOnHide={false}
        data-animation={animation}
        data-animated={state.animated ? "" : undefined}
        {...props}
      >
        {(dialogProps) => <Panel {...dialogProps}>{children as any}</Panel>}
      </AriakitDialog>
    );
  },
);
