/* eslint-disable react/prop-types */
import styled from "@xstyled/styled-components";
import { forwardRef } from "react";

const SignCounter = styled.divBox`
  font-size: sm;
  color: on-light;

  strong {
    color: danger;
  }
`;

function frSign(count) {
  return count > 1 ? `signes` : "signe";
}

export const FieldSignCounter = forwardRef(
  ({ state, limit: propLimit, ...props }, ref) => {
    const signCount = state.field.input.value.length;
    const limit = propLimit ?? state.maxLength ?? null;
    return (
      <SignCounter ref={ref} {...props}>
        {limit ? (
          <>
            {signCount > limit ? <strong>{signCount}</strong> : signCount} /{" "}
            {limit} {frSign(limit)}
          </>
        ) : (
          <>
            {signCount} {frSign(signCount)}
          </>
        )}
      </SignCounter>
    );
  },
);
