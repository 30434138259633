import { gql } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { Fragment, memo, useEffect, useState } from "react";

import { AuthorAvatar } from "@/components/AuthorAvatar";
import { useSafeQuery } from "@/containers/Apollo";
import {
  useArticleRevisionId,
  usePinnedRevisionId,
  useShowRevision,
} from "@/containers/article/ArticleRevision";
import {
  useCompare,
  useCompareVersionsValuesContext,
  useSetCompare,
} from "@/containers/article/CompareVersions";

import { useArticleId, useArticleSelector } from "./ArticleContext";

const AuthorFragment = gql`
  fragment ArticleStatusInfo_authors on Author {
    id
    longName
    origin
    ...AuthorAvatar_author
  }

  ${AuthorAvatar.fragments.author}
`;

const SignaturesFragment = gql`
  fragment ArticleStatusInfo_articleRevisionSignatures on ArticleRevision {
    freeAuthors {
      longName
      origin
    }
    signatures {
      id
      author {
        id
        ...ArticleStatusInfo_authors
      }
      origin
    }
  }

  ${AuthorFragment}
`;

const ArticleStatusInfoQuery = gql`
  query ArticleStatusInfoQuery($id: Int!) {
    article(id: $id) {
      id
      revisions(limit: 1) {
        nodes {
          id
          ...ArticleStatusInfo_articleRevisionSignatures
        }
      }
    }
  }
  ${SignaturesFragment}
`;

const ArticleRevisionStatusInfoQuery = gql`
  query ArticleRevisionStatusInfoQuery($id: Int!) {
    articleRevision(id: $id) {
      id
      ...ArticleStatusInfo_articleRevisionSignatures
    }
  }
  ${SignaturesFragment}
`;

export const formatSignatures = (signatures) => {
  return (
    signatures?.map((signature) => ({
      longName: signature.author.longName,
      origin: signature.origin,
      authorId: signature.author.id,
      author: signature.author,
    })) ?? []
  );
};

const DeletedSpan = (props) => (
  <span
    className="bg-danger-bg-light text-danger-on-strong line-through"
    {...props}
  />
);

const AddedSpan = (props) => (
  <span className="bg-success-bg-light text-success-on-strong" {...props} />
);

const retrieveSignatures = (revision) => {
  return [
    ...formatSignatures(revision?.signatures),
    ...(revision?.freeAuthors ?? []),
  ].filter(Boolean);
};

const mergeSignatures = ({
  previousSignatures,
  addedSignatures,
  deletedSignatures,
  currentSignatures,
}) => {
  let diffCount = addedSignatures.length;

  const mergedSignatures = [
    ...previousSignatures.map((signature) => {
      if (deletedSignatures.some((s) => s.longName === signature.longName)) {
        diffCount++;
        return {
          ...signature,
          deleted: true,
        };
      }

      const origin = signature.origin;
      const currentOrigin = currentSignatures.find(
        (s) => s.authorId === signature.authorId,
      )?.origin;

      if (origin !== currentOrigin) {
        if (!origin || !currentOrigin) {
          diffCount++;
        } else {
          diffCount += 2;
        }

        signature.diffOrigin = {
          previousOrigin: origin,
          origin: currentOrigin,
        };
        return signature;
      }
      return signature;
    }),
    ...addedSignatures.map((signature) => ({ ...signature, added: true })),
  ];

  return {
    diffCount,
    mergedSignatures,
  };
};

const useDiffSignatures = () => {
  const [diffedSignatures, setDiffedSignatures] = useState();
  const { featureOpened, enabled } = useCompare();
  const setCompare = useSetCompare();

  const pinnedRevisionId = usePinnedRevisionId();

  const { selectedRevision, pinnedRevision } =
    useCompareVersionsValuesContext();

  useEffect(() => {
    if (
      !featureOpened ||
      !enabled ||
      selectedRevision == null ||
      (pinnedRevisionId && (pinnedRevision == null || selectedRevision == null))
    ) {
      return;
    }

    const currentRevision = pinnedRevisionId
      ? pinnedRevision
      : selectedRevision;
    const previousRevision = pinnedRevisionId
      ? selectedRevision
      : selectedRevision.previousArticleRevision;

    const currentSignatures = retrieveSignatures(currentRevision);
    const previousSignatures = retrieveSignatures(previousRevision);
    const addedSignatures = currentSignatures.filter(
      (signature) =>
        !previousSignatures.some(
          (previousSignature) =>
            previousSignature.longName === signature.longName,
        ),
    );
    const deletedSignatures = previousSignatures.filter(
      (signature) =>
        !currentSignatures.some(
          (previousSignature) =>
            previousSignature.longName === signature.longName,
        ),
    );

    const { diffCount, mergedSignatures } = mergeSignatures({
      previousSignatures,
      addedSignatures,
      deletedSignatures,
      currentSignatures,
    });

    setCompare((compare) => ({
      ...compare,
      count: compare.count + diffCount,
    }));
    setDiffedSignatures(mergedSignatures);
  }, [
    enabled,
    featureOpened,
    selectedRevision,
    setCompare,
    pinnedRevisionId,
    pinnedRevision,
  ]);

  return diffedSignatures;
};

const useLastArticleRevision = () => {
  const articleId = useArticleId();
  const { data } = useSafeQuery(ArticleStatusInfoQuery, {
    variables: { id: articleId },
    fetchPolicy: "network-only",
  });
  return data?.article.revisions.nodes[0];
};

const useArticleRevision = ({ id }) => {
  const { data } = useSafeQuery(ArticleRevisionStatusInfoQuery, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return data?.articleRevision;
};

const useCurrentDiffSignatures = () => {
  const [diffedSignatures, setDiffedSignatures] = useState();
  const { featureOpened, enabled } = useCompare();
  const revisionId = useArticleRevisionId();
  const pinnedRevisionId = usePinnedRevisionId();
  const currentSignatures = useArticleSelector((article) =>
    retrieveSignatures(article),
  );
  const articleRevision = useArticleRevision({ id: revisionId });

  const setCompare = useSetCompare();

  const lastArticleRevision = useLastArticleRevision();

  const compareRevision =
    pinnedRevisionId === 0 ? articleRevision : lastArticleRevision;

  useEffect(() => {
    if (!featureOpened || !enabled || compareRevision === undefined) return;

    const previousSignatures = retrieveSignatures(compareRevision);

    const addedSignatures = currentSignatures.filter(
      (signature) =>
        !previousSignatures.some(
          (previousSignature) =>
            previousSignature.longName === signature.longName,
        ),
    );
    const deletedSignatures = previousSignatures.filter(
      (signature) =>
        !currentSignatures.some(
          (previousSignature) =>
            previousSignature.longName === signature.longName,
        ),
    );

    const { diffCount, mergedSignatures } = mergeSignatures({
      previousSignatures,
      addedSignatures,
      deletedSignatures,
      currentSignatures,
    });

    setCompare((compare) => ({
      ...compare,
      count: compare.count + diffCount,
    }));
    setDiffedSignatures(mergedSignatures);
  }, [compareRevision, featureOpened, enabled, setCompare, currentSignatures]);

  return diffedSignatures;
};

const DiffSignatures = ({ children }) => {
  const showRevision = useShowRevision();

  return showRevision ? (
    <CompareRevisionSignatures>{children}</CompareRevisionSignatures>
  ) : (
    <CompareCurrentSignatures>{children}</CompareCurrentSignatures>
  );
};

const CompareRevisionSignatures = ({ children }) => {
  const signatures = useDiffSignatures();
  return <>{children(signatures)}</>;
};

const CompareCurrentSignatures = ({ children }) => {
  const signatures = useCurrentDiffSignatures();
  return <>{children(signatures)}</>;
};

const DiffSignatureWithOrigin = ({ signature }) => {
  const { longName, origin, diffOrigin, author } = signature;
  return (
    <span className="inline-flex gap-1">
      {author && <AuthorAvatar author={author} />}
      <span className="text-no-wrap font-bold">{longName}</span>
      {diffOrigin ? (
        <span className="text-no-wrap">
          (<DeletedSpan>{diffOrigin.previousOrigin}</DeletedSpan>
          {diffOrigin.origin && diffOrigin.previousOrigin && ", "}
          <AddedSpan>{diffOrigin.origin}</AddedSpan>)
        </span>
      ) : (
        origin && <span>({origin})</span>
      )}
    </span>
  );
};

const CompareSignatures = () => {
  return (
    <div>
      <DiffSignatures>
        {(signatures) => (
          <>
            {signatures?.map((signature, i) => {
              if (signature.added)
                return (
                  <Fragment key={i}>
                    <AddedSpan>
                      <Signature signature={signature} />
                    </AddedSpan>{" "}
                  </Fragment>
                );
              if (signature.deleted)
                return (
                  <Fragment key={i}>
                    <DeletedSpan>
                      <Signature signature={signature} />
                    </DeletedSpan>{" "}
                  </Fragment>
                );
              return (
                <Fragment key={i}>
                  <DiffSignatureWithOrigin signature={signature} />{" "}
                </Fragment>
              );
            })}
          </>
        )}
      </DiffSignatures>
    </div>
  );
};

const Signature = ({ signature, separator }) => {
  const { longName, origin, author } = signature;
  return (
    <span className="inline-flex gap-1">
      {author && <AuthorAvatar author={author} />}
      <span className="text-no-wrap font-bold">{longName}</span>
      {origin && <span>({origin})</span>}
      {separator && separator}
    </span>
  );
};

export const InnerSignatures = ({ signatures, className }) => {
  if (!signatures?.length) return null;

  return (
    <div className={clsx("flex flex-wrap items-start gap-2", className)}>
      <span>Par</span>
      {signatures.map((signature, index) => {
        const separator = (() => {
          if (index === signatures.length - 1) return "";
          if (index === signatures.length - 2) return <span>et</span>;
          return <span className="-ml-1">,</span>;
        })();
        return (
          <Signature signature={signature} separator={separator} key={index} />
        );
      })}
    </div>
  );
};

const Signatures = memo(({ displayMode }) => {
  const signatures = useArticleSelector((article) => [
    ...formatSignatures(article.signatures),
    ...article.freeAuthors,
  ]);

  if (displayMode === "compare") {
    return <CompareSignatures />;
  }

  return <InnerSignatures signatures={signatures} />;
});
const getArticleLastUpdateDate = (article) => {
  if (article.__typename === "ArticleRevision") return article.date;
  return article.lastUserUpdate ?? article.updatedAt ?? article.createdAt;
};
export const ArticleStatusInfo = memo(({ displayMode }) => {
  const { source, date, initialFirstPublished } = useArticleSelector(
    (article) => ({
      source: article.metadata?.huit?.source ?? null,
      initialFirstPublished: article.initialFirstPublished,
      date: getArticleLastUpdateDate(article),
    }),
  );

  return (
    <div className="flex justify-between text-grey-on">
      <Signatures displayMode={displayMode} />
      <div data-test-hidden>
        {[
          source && `${source} |`,
          initialFirstPublished &&
            `${moment(initialFirstPublished).format("DD.MM.YYYY à HH[h]mm")} •`,
          "Mis à jour le",
          moment(date).format("DD.MM.YYYY à HH[h]mm"),
        ]
          .filter(Boolean)
          .join(" ")}
      </div>
    </div>
  );
});

ArticleStatusInfo.fragments = {
  article: gql`
    fragment ArticleStatusInfo_article on ArticleBase {
      createdAt
      updatedAt
      metadata
      ... on Article {
        lastUserUpdate
        initialFirstPublished
        freeAuthors {
          longName
          origin
        }
        signatures {
          id
          author {
            id
            ...ArticleStatusInfo_authors
          }
          origin
        }
      }
    }

    ${AuthorFragment}
  `,
};
