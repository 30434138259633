/* eslint-disable camelcase */
import styled from "@xstyled/styled-components";

export const Backdrop = styled.box`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: dusk-darker;
  opacity: 0.85;
  z-index: dialog;
`;
