import styled, { x } from "@xstyled/styled-components";
import { forwardRef } from "react";
import { IoCheckmarkCircle } from "swash/Icon";

import {
  TextInputField,
  useTextInputField,
} from "@/components/fields/TextInputField";

const TwoFactorInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3;
  padding-top: 3;
  padding-bottom: 3;

  & svg {
    display: none;
    font-size: lg;
    position: absolute;
    color: success-dark;
    top: 8px;
    right: 10px;
  }

  &[data-valid="true"] {
    color: success-dark;
    transition: color 0.25s;

    & [data-active="true"] {
      border-color: success-light;
    }

    & svg {
      display: block;
    }
  }
`;

const TwoFactorInput = styled.input`
  font-family: monospace;
  border: none;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 25px;
  width: 270px;
  margin-left: 22px;
`;

const TwoFactorInputDashes = styled.div`
  display: flex;
  column-gap: 12px;
  width: 260px;

  & > div {
    height: 1px;
    width: 30px;
    border-bottom: 6px solid;
    border-color: blue-lighter;

    &[data-active="true"] {
      border-color: blue;
      transition: border-color 0.25s;
    }
  }
`;

function parseCode(v) {
  if (!v) return null;
  return v
    .split("")
    .filter((char) => /[0-9]/.test(char))
    .join("");
}

export const TwoFactorField = forwardRef(
  ({ name, valid = false, disabled = false }, ref) => {
    const field = useTextInputField(name, {
      validate: (v) => (v && v.length === 6 ? undefined : "requis"),
      parse: parseCode,
    });
    const code = field.state.field.input.value;
    const checkIsRankActive = (rank) => rank <= (code?.length || 0);

    return (
      <TwoFactorInnerContainer data-valid={valid}>
        <x.div position="relative">
          <TextInputField
            as={TwoFactorInput}
            ref={ref}
            maxLength="6"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            disabled={disabled}
            autoFocus
            {...field}
          />
          <IoCheckmarkCircle />
        </x.div>
        <TwoFactorInputDashes>
          <div data-active={checkIsRankActive(0)} />
          <div data-active={checkIsRankActive(1)} />
          <div data-active={checkIsRankActive(2)} />
          <div data-active={checkIsRankActive(3)} />
          <div data-active={checkIsRankActive(4)} />
          <div data-active={checkIsRankActive(5)} />
        </TwoFactorInputDashes>
      </TwoFactorInnerContainer>
    );
  },
);
