import { gql } from "@apollo/client";
import { RefObject, memo } from "react";
import { Chip } from "swash/Chip";
import { useElementsOverflow } from "swash/utils/useElementsOverflow";

import { HasPermission } from "@/containers/User";

import {
  ArticlePredicateAlert,
  ArticlePredicateAlertProps,
} from "./ArticlePredicateAlert";

type ArticlePredicateConclusion = {
  id: number;
} & ArticlePredicateAlertProps["predicateConclusion"];

type PredicateConclusionListProps = {
  conclusions: ArticlePredicateConclusion[];
};

const PredicateConclusionList = memo<PredicateConclusionListProps>((props) => {
  const { containerRef, listRef, listStyle, items, overflowItems } =
    useElementsOverflow({
      items: props.conclusions,
    });

  return (
    <div
      ref={containerRef as RefObject<HTMLDivElement>}
      className="grow select-none overflow-hidden"
    >
      <div
        ref={listRef as RefObject<HTMLDivElement>}
        style={listStyle}
        className="flex gap-2"
      >
        {items.map((conclusion) => (
          <ArticlePredicateAlert
            key={conclusion.id}
            predicateConclusion={conclusion}
          />
        ))}
        {overflowItems.length > 0 && (
          <Chip scale="sm">+{overflowItems.length}</Chip>
        )}
      </div>
    </div>
  );
});

export type ArticlePredicatesProps = {
  article: {
    predicateConclusions: ArticlePredicateConclusion[];
  };
};

export const ArticlePredicates = (props: ArticlePredicatesProps) => {
  const {
    article: { predicateConclusions },
  } = props;
  if (!predicateConclusions.length) return null;
  return (
    <HasPermission permission={["stats:full", "stats:exposures"]} method="some">
      <PredicateConclusionList conclusions={predicateConclusions} />
    </HasPermission>
  );
};

ArticlePredicates.fragments = {
  article: gql`
    fragment ArticlePredicates_article on Article {
      predicateConclusions {
        id
        ...ArticlePredicateAlert_predicateConclusion
      }
    }

    ${ArticlePredicateAlert.fragments.predicateConclusion}
  `,
};
