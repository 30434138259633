import styled from "@xstyled/styled-components";
import { useEffect, useRef } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import Close from "@/assets/imgs/inline-svg/close.svg";

const pendingActions = [];

const runTwitterAction = (action) => {
  if (window.twttr) action(window.twttr);
  else pendingActions.push(action);
};

async function injectTwitterScript() {
  const id = "twitter-script";
  const existingScript = document.getElementById(id);
  if (existingScript) return null;

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.id = id;
    script.onload = () => {
      try {
        pendingActions.forEach((action) => {
          action(window.twttr);
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    };
    script.onerror = reject;
    script.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(script);
  });
}

const TweetContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  .twitter-tweet {
    margin: 0 !important;
  }

  [data-change="deleted"] & {
    padding: 2 0;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-deleted-media;
      opacity: 0.25;
    }
    &:after {
      content: "";
      mask-image: url(${Close});
      position: absolute;
      inset: 0 0 0 0;
      background-color: white;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 48px;
    }
  }

  [data-change="added"] & {
    padding: 2 0;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-added-media;
      opacity: 0.25;
    }
  }
`;

export function TwitterEmbed({ tweetId, onLoad, onError }) {
  const tweetRef = useRef();
  const onLoadRef = useLiveRef(onLoad);
  const onErrorRef = useLiveRef(onError);

  useEffect(() => {
    let cancelled;
    runTwitterAction((twttr) => {
      twttr.widgets
        .createTweet(tweetId, tweetRef.current, { lang: "fr" })
        .then(() => {
          if (!cancelled && onLoadRef.current) {
            onLoadRef.current();
          }
        })
        .catch((error) => {
          if (!cancelled && onErrorRef.current) {
            onErrorRef.current(error);
          }
        });
    });
    injectTwitterScript().catch((error) => {
      if (!cancelled && onErrorRef.current) {
        onErrorRef.current(error);
      }
    });
    return () => {
      cancelled = true;
    };
  }, [tweetId, onLoadRef, onErrorRef]);

  return <TweetContainer ref={tweetRef} />;
}
