import moment from "moment";

/*
 *  move element in a list/array
 */
export const moveItems = (fromIndex, toIndex, list) => {
  const movedElement = list[fromIndex];
  const newList = Array.from(list);
  newList.splice(fromIndex, 1);
  newList.splice(toIndex, 0, movedElement);
  return newList;
};

/*
 * Reset rank property for a list
 */
export const cleanRanks = (values) =>
  values.map((resource, index) => ({
    ...resource,
    rank: index,
  }));

/*
 * Remove element from a list based on id
 */
export const removeFromListById = (id, list) =>
  list.filter((item) => item.id !== id);

/*
 * Find element in a list based on an id
 */
export const findById = (list, id) => list.find((item) => item.id === id);

/*
 * Split Dnd ID 'type:id' -> ['type', id, tagTemplateId]
 */
export const splitDndId = (value) => {
  const parts = value.split(":");
  return [
    parts[0],
    parseInt(parts[1], 10),
    ...(parts[2] ? [Number(parts[2])] : []),
  ];
};

export const shortenSentence = (sentence, length, separator = " ") => {
  if (sentence.length <= length) return sentence;
  return `${sentence.substr(
    0,
    sentence.replace(/(\r\n|\n|\r)/gim, " ").lastIndexOf(separator, length),
  )} ...`;
};

export const formatPublicationDate = (date) => {
  const mDate = moment(date);
  return mDate.isSame(moment(), "day")
    ? `à ${mDate.format("H:mm")}`
    : `le ${mDate.format("DD/MM/YYYY")}`;
};

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export const omitTypename = ({ __typename, ...values }) => values;
