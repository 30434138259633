import { gql } from "@apollo/client";
import { createContext, useContext } from "react";

import { ImageFixedFragment } from "@/components/Image";

import { useSafeQuery } from "./Apollo";

const clients = [
  "local",
  "lemonde",
  "lemonde-en",
  "lobs",
  "telerama",
  "courrier",
  "letemps",
  "huffpost",
  "linforme",
  "nicematin",
] as const;

export type Clients = (typeof clients)[number];

// Type is not complete, please add missing one if you need it
export type RemoteConfig = {
  clientId: Clients;
  amplitude: { apiKey: string };
  forecast: { enabled: boolean };
  publicationSlots: Array<number>;
  genai: { enabled: boolean };
  frontBaseUrl: string;
};

export const RemoteConfigContext = createContext<RemoteConfig | null>(null);

export function useRemoteConfig() {
  const config = useContext(RemoteConfigContext);
  if (!config) {
    throw Error(
      "useRemoteConfig requires RemoteConfigInitializer to be used higher in the component tree",
    );
  }
  return config;
}

const RemoteConfigQuery = gql`
  query RemoteConfig {
    config {
      env
      clientId
      internalDomains
      webNotification
      version
      images
      services
      files {
        accept
      }
      clientImage {
        id
        fixed(width: 32, height: 32) {
          ...ImageFixedFragment
        }
      }
      homeTags
      features
      articles
      browserslistWarning
      frontBaseUrl
      brandColor
      zendesk {
        enabled
      }
      statusPage {
        enabled
      }
      amplitude {
        apiKey
      }
      facebook {
        appId
        redirectUri
      }
      publicationSlots
      editorConfig {
        plugins {
          name
        }
      }
      beamer {
        enabled
        productId
      }
      legacyPubSub {
        topic
        subscription
      }
      slack {
        clientId
        redirectUri
        scopes
      }
      textToSpeech {
        enabled
      }
      forecast {
        enabled
      }
      adminPermissions
      defaultLanguage
      hasPrintSystem
      webPush {
        publicKey
      }
      homeTeaserContext {
        id
        key
      }
      genai {
        enabled
      }
      videos {
        searchProvider
      }
    }
  }
  ${ImageFixedFragment}
`;

type RemoteConfigQueryData = {
  config: RemoteConfig;
};

export function RemoteConfigInitializer(props: { children: React.ReactNode }) {
  const { data } = useSafeQuery<RemoteConfigQueryData, never>(
    RemoteConfigQuery,
  );
  if (!data) return null;

  return (
    <RemoteConfigContext.Provider value={data.config}>
      {props.children}
    </RemoteConfigContext.Provider>
  );
}
