import { gql } from "@apollo/client";

// eslint-disable-next-line @lemonde/import/no-illegal-import
import { LivePostFragment } from "@/containers/routes/live/LivePostOperations";

import { UsedInSection } from "../admin/CRUD/usages/UsedInSection";
import { UsedInArticleFragment } from "../admin/CRUD/usages/articles";
import { useUsedInResource } from "../admin/CRUD/usages/useUsedInResource";

const UsedInArticlesQuery = gql`
  query UsedInArticlesQuery($id: Int!, $offset: Int, $limit: Int) {
    node: image(id: $id) {
      id
      usedIn_articles(offset: $offset, limit: $limit) {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          ...UsedInArticleFragment
        }
      }
    }
  }

  ${UsedInArticleFragment}
`;

const UsedInPostsQuery = gql`
  query UsedInPostsQuery($id: Int!, $offset: Int, $limit: Int) {
    node: image(id: $id) {
      id
      usedIn_posts(offset: $offset, limit: $limit) {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          ...LivePostFragment
        }
      }
    }
  }

  ${LivePostFragment}
`;

const UsedInUsersQuery = gql`
  query UsedInUsersQuery($id: Int) {
    node: image(id: $id) {
      id
      usedIn_users {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          label: lastName
          email
          firstName
          lastName
          level
          enabled
          publisher
          validDomain
          twoFactorSmsEnabled
          twoFactorAuthenticatorEnabled
          sections {
            id
            name
          }
          activities(limit: 1, where: { action: { eq: "login" } }) {
            nodes {
              id
              date
            }
          }
        }
      }
    }
  }
`;

const UsedInTagsQuery = gql`
  query UsedInTagsQuery($id: Int) {
    node: image(id: $id) {
      id
      usedIn_tags {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          title
          enabled
          header
          parentId
          url
        }
      }
    }
  }
`;

const UsedInBlogsQuery = gql`
  query UsedInBlogsQuery($id: Int) {
    node: image(id: $id) {
      id
      usedIn_blogs {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          label: title
          title
        }
      }
    }
  }
`;

const UsedInCampaignsQuery = gql`
  query UsedInCampaignsQuery($id: Int) {
    node: image(id: $id) {
      id
      usedIn_campaigns {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          label: title
          title
          partnerName
          url
        }
      }
    }
  }
`;

const UsedInPeriodicalReleasesQuery = gql`
  query UsedInPeriodicalReleasesQuery($id: Int) {
    node: image(id: $id) {
      id
      usedIn_periodicalReleases {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          label
          releaseDate
        }
      }
    }
  }
`;

const UsedInSourcesQuery = gql`
  query UsedInSourcesQuery($id: Int) {
    node: image(id: $id) {
      id
      usedIn_sources {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          label
        }
      }
    }
  }
`;

const UsedInContentTypesQuery = gql`
  query UsedInContentTypesQuery($id: Int) {
    node: image(id: $id) {
      id
      usedIn_contentTypes {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          label
          customType {
            id
            label
          }
        }
      }
    }
  }
`;

const UsedInAuthorsQuery = gql`
  query UsedInAuthorsQuery($id: Int) {
    node: image(id: $id) {
      id
      usedIn_authors {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          longName
          shortName
          origin
          verified
        }
      }
    }
  }
`;

const usedInOperations = {
  UsedInQueries: {
    UsedInArticlesQuery,
    UsedInPostsQuery,
    UsedInUsersQuery,
    UsedInTagsQuery,
    UsedInBlogsQuery,
    UsedInContentTypesQuery,
    UsedInCampaignsQuery,
    UsedInPeriodicalReleasesQuery,
    UsedInSourcesQuery,
    UsedInAuthorsQuery,
  },
};

export const ImageUsedIn = ({ node }) => {
  const usedInResource = useUsedInResource({
    node,
    queries: usedInOperations.UsedInQueries,
  });

  return (
    <UsedInSection
      operations={usedInOperations}
      label={`Image #${node?.id}`}
      usedInResource={usedInResource}
      resource={node}
      title={null}
    />
  );
};
