import {
  gql,
  useApolloClient,
  useMutation,
  useSubscription,
} from "@apollo/client";
import { useCallback, useEffect, useRef } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useSafeQuery } from "@/containers/Apollo";

import { APP_UUID } from "../AppUuid";
import { useRevalidate } from "../Revalidation";
import { useUser } from "../User";
import {
  useEmitRequestCompletion,
  useListenRequestCompletion,
  useTrackLockResource,
} from "./LockTracker";
import { useLockUpdating } from "./LockUpdatingProvider";

/**
 * @typedef Locker
 * @property {string} appUuid
 * @property {{ id: number, firstName: string | null, lastName: string | null }} user
 */

const LockerFragment = gql`
  fragment LockerFragment on ModernLocker {
    appUuid
    user {
      id
      firstName
      lastName
    }
  }
`;

/**
 * @typedef LockInfo
 * @property {string} id
 * @property {Locker | null} locker
 * @property {{ applicant: Locker } | null} request
 */

const LockFragment = gql`
  fragment LockFragment on ModernLock {
    id
    locker {
      ...LockerFragment
    }
    request {
      applicant {
        ...LockerFragment
      }
    }
  }

  ${LockerFragment}
`;

/**
 * @typedef LockRequestCompletion
 * @property {'accepted' | 'rejected' | 'cancelled'} state
 * @property {LockInfo} lock
 * @property {Locker} locker
 * @property {Locker} applicant
 */

const LockRequestCompletionFragment = gql`
  fragment LockRequestCompletionFragment on LockRequestCompletion {
    state
    lock {
      ...LockFragment
    }
    locker {
      ...LockerFragment
    }
    applicant {
      ...LockerFragment
    }
  }

  ${LockFragment}
  ${LockerFragment}
`;

const LockMutation = gql`
  mutation LockMutation($id: String!) {
    lock(input: { id: $id }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const UnlockMutation = gql`
  mutation UnlockMutation($id: String!) {
    unlock(input: { id: $id }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const RequestLockMutation = gql`
  mutation RequestLockMutation($id: String!) {
    modernRequestLock(input: { id: $id }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

/**
 * @typedef LockResult
 * @property {LockInfo} lock
 */

const LockQuery = gql`
  query LockApiLockQuery($id: String!) {
    lock(id: $id) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const LocksQuery = gql`
  query LockApiLocksQuery($ids: [String!]!) {
    modernLocks(where: { id: { in: $ids } }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const LockUpdatedSubscription = gql`
  subscription LockUpdatedSubscription($ids: [String!]!) {
    modernLockUpdated(where: { id: { in: $ids } }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const CompleteLockRequestMutation = gql`
  mutation CompleteLockRequestMutation(
    $id: String!
    $state: LockRequestState!
  ) {
    completeLockRequest(input: { id: $id, state: $state }) {
      ...LockRequestCompletionFragment
    }
  }

  ${LockRequestCompletionFragment}
`;

const LockRequestCompletedSubscription = gql`
  subscription LockRequestCompletedSubscription($ids: [String!]!) {
    lockRequestCompleted(where: { id: { in: $ids } }) {
      ...LockRequestCompletionFragment
    }
  }

  ${LockRequestCompletionFragment}
`;

/**
 * Batch lock polling and subscriptions.
 * @param {object} params
 * @param {string[]} params.ids
 */
export const useLockSubscriptions = ({ ids }) => {
  const client = useApolloClient();
  const skip = ids.length === 0;
  const optionsRef = useLiveRef({ skip, ids });
  const emitRequestCompletion = useEmitRequestCompletion();
  const { lockUpdating } = useLockUpdating();
  useRevalidate(() => {
    const { skip, ids } = optionsRef.current;
    if (skip || lockUpdating) return;
    client.query({
      query: LocksQuery,
      variables: { ids },
      fetchPolicy: "network-only",
    });
  });
  useSubscription(LockRequestCompletedSubscription, {
    variables: { ids },
    skip,
    onData: ({ data: { data } }) => {
      if (!data) return;
      emitRequestCompletion(
        data.lockRequestCompleted.lock.id,
        data.lockRequestCompleted,
      );
    },
  });
  useSubscription(LockUpdatedSubscription, {
    skip,
    variables: { ids },
  });
};

/**
 * @typedef LockApi
 * @property {() => Promise<import('@apollo/client').FetchResult>} lock
 * @property {() => Promise<import('@apollo/client').FetchResult>} unlock
 * @property {() => Promise<import('@apollo/client').FetchResult>} requestLock
 * @property {() => void} acceptLockRequest
 * @property {() => void} rejectLockRequest
 * @property {() => void} cancelLockRequest
 * @property {(listener: RequestCompletionListener) => () => void} listenRequestCompletion
 * @property {(listener: LockForcedListener) => () => void} listenLockForced
 * @property {LockInfo | null} info
 */

/** @typedef {(completion: LockRequestCompletion) => void} RequestCompletionListener */
/** @typedef {(locker: Locker) => void} LockForcedListener */

/**
 * Returns info about lock and lock method.
 * @param {string} id
 * @returns {LockApi}
 */
export function useLockApi(id) {
  const user = useUser();
  useTrackLockResource(id);

  const completingRequestRef = useRef(false);

  const listenRequestCompletion = useListenRequestCompletion();
  const scopedListenRequestCompletion = useCallback(
    (/** @type {RequestCompletionListener} */ listener) =>
      listenRequestCompletion(id, listener),
    [id, listenRequestCompletion],
  );
  const emitRequestCompletion = useEmitRequestCompletion();

  const { data: lockData } = useSafeQuery(LockQuery, { variables: { id } });
  const lockDataRef = useLiveRef(lockData);
  const { setLockUpdating } = useLockUpdating();

  const lockForcedListeners = useRef(/** @type {LockForcedListener[]} */ ([]));

  const listenLockForced = useCallback(
    (/** @type {LockForcedListener} */ listener) => {
      lockForcedListeners.current.push(listener);
      return () => {
        lockForcedListeners.current = lockForcedListeners.current.filter(
          (x) => x !== listener,
        );
      };
    },
    [],
  );

  const previousLockerRef = useRef(/** @type {Locker | null} */ (null));
  const locker = lockData?.lock.locker ?? null;

  useEffect(() => {
    if (completingRequestRef.current) return;
    const previousLocker = previousLockerRef.current;
    if (!locker || !previousLocker) return;
    if (locker.user.id === previousLocker.user.id) return;
    if (previousLocker.user.id !== user.id) return;
    lockForcedListeners.current.forEach((listener) => {
      listener(locker);
    });
  }, [user, locker]);

  useEffect(() => {
    previousLockerRef.current = locker;
  }, [locker]);

  const [lock, { loading: lockLoading }] = useMutation(LockMutation, {
    variables: { id },
    optimisticResponse: {
      lock: {
        __typename: "ModernLock",
        id,
        locker: {
          __typename: "ModernLocker",
          appUuid: APP_UUID,
          user: {
            __typename: "User",
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
          },
        },
        request: null,
      },
    },
  });
  const [unlock, { loading: unlockLoading }] = useMutation(UnlockMutation, {
    variables: { id },
    optimisticResponse: {
      unlock: {
        __typename: "ModernLock",
        id,
        locker: null,
        request: null,
      },
    },
  });
  const lockUpdating = unlockLoading || lockLoading;
  useEffect(() => {
    setLockUpdating(lockUpdating);
  }, [setLockUpdating, lockUpdating]);
  const [requestLock] = useMutation(RequestLockMutation, {
    variables: { id },
    optimisticResponse: {
      modernRequestLock: {
        __typename: "ModernLock",
        id,
        locker: lockData?.lock.locker ?? null,
        request: {
          __typename: "ModernLockRequest",
          applicant: {
            __typename: "ModernLocker",
            appUuid: APP_UUID,
            user: {
              __typename: "User",
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
            },
          },
        },
      },
    },
  });
  const [completeLockRequest] = useMutation(CompleteLockRequestMutation);
  const completeLockRequestWithStatus = useCallback(
    /**
     * @param {'accepted' | 'cancelled' | 'rejected'} state
     */
    (state) => {
      completingRequestRef.current = true;
      const id = lockDataRef.current?.lock.id;
      if (!id) return;
      completeLockRequest({
        variables: { id, state },
      })
        .then(({ data }) => {
          emitRequestCompletion(id, data.completeLockRequest);
        })
        .finally(() => {
          completingRequestRef.current = false;
        });
    },
    [lockDataRef, completeLockRequest, emitRequestCompletion],
  );
  const acceptLockRequest = useCallback(
    () => completeLockRequestWithStatus("accepted"),
    [completeLockRequestWithStatus],
  );
  const rejectLockRequest = useCallback(
    () => completeLockRequestWithStatus("rejected"),
    [completeLockRequestWithStatus],
  );
  const cancelLockRequest = useCallback(
    () => completeLockRequestWithStatus("cancelled"),
    [completeLockRequestWithStatus],
  );
  return {
    lock,
    unlock,
    requestLock,
    acceptLockRequest,
    rejectLockRequest,
    cancelLockRequest,
    listenRequestCompletion: scopedListenRequestCompletion,
    listenLockForced,
    info: lockData?.lock ?? null,
  };
}
