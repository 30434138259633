import { DocumentNode, gql } from "@apollo/client";
import clsx from "clsx";
import { ForwardRefExoticComponent, RefObject, forwardRef } from "react";

import { Image, ImageFixedFragment } from "@/components/Image";

const backgroundRandomColors = [
  "#2a3b4c",
  "#10418e",
  "#4527a0",
  "#8ca1ee",
  "#65b7d7",
  "#65b793",
  "#00796b",
  "#9c1258",
  "#c20006",
  "#ff3d44",
  "#ffb83d",
  "#f58f00",
];

export type UserAvatarUser = {
  id: number;
  fullName: string;
  initials: string;
  image: any;
};

export type UserAvatarProps = {
  user: UserAvatarUser;
  size?: number;
  className?: string;
};

type InitialsAvatarProps = UserAvatarProps;

const InitialsAvatar = forwardRef(
  ({ user, size, className }: InitialsAvatarProps, ref) => {
    const randomIndex = user.id % backgroundRandomColors.length;
    return (
      <div
        ref={ref as RefObject<HTMLDivElement>}
        className={clsx(
          className,
          "flex shrink-0 select-none items-center justify-center rounded-full",
        )}
        style={{
          backgroundColor: backgroundRandomColors[randomIndex],
          width: size,
          height: size,
        }}
      >
        <svg width="100%" height="100%" viewBox="-50 -66 100 100">
          <text fill="white" fontWeight="600" textAnchor="middle" fontSize="50">
            {user.initials}
          </text>
        </svg>
      </div>
    );
  },
);

export const UserAvatar = forwardRef(
  ({ className, user, size = 32 }: UserAvatarProps, ref) => {
    if (!user.image) {
      return (
        <InitialsAvatar
          user={user}
          size={size}
          className={className}
          ref={ref}
        />
      );
    }
    const avatar = size <= 32 ? user.image.avatar : user.image.avatarHD;
    return (
      <Image
        ref={ref}
        borderRadius="50%"
        {...avatar}
        width={size}
        height={size}
        alt={user.fullName}
        className={className}
      />
    );
  },
) as ForwardRefExoticComponent<UserAvatarProps> & {
  fragments: { user: DocumentNode };
};

UserAvatar.fragments = {
  user: gql`
    fragment UserAvatar_user on User {
      id
      fullName
      initials
      image {
        id
        avatar: fixed(width: 32, height: 32) {
          ...ImageFixedFragment
        }
        avatarHD: fixed(width: 128, height: 128) {
          ...ImageFixedFragment
        }
      }
    }

    ${ImageFixedFragment}
  `,
};
