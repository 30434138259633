import { DocumentNode, gql } from "@apollo/client";
import { NamedExoticComponent, memo } from "react";
import { Chip } from "swash/Chip";
import { Tooltip } from "swash/Tooltip";

export type ArticlePredicateAlertProps = {
  predicateConclusion: {
    resolved: boolean;
    predicate: {
      title: string;
      color: string;
      description: string | null;
    };
  };
  className?: string;
};

export const ArticlePredicateAlert = memo<ArticlePredicateAlertProps>(
  ({
    predicateConclusion: {
      resolved,
      predicate: { title, color, description },
    },
    className,
  }) => {
    return (
      <Tooltip tooltip={description} aria-label={title}>
        <Chip
          tint={!resolved ? color : undefined}
          scale="sm"
          className={className}
        >
          {title}
        </Chip>
      </Tooltip>
    );
  },
) as NamedExoticComponent<ArticlePredicateAlertProps> & {
  fragments: { predicateConclusion: DocumentNode };
};

ArticlePredicateAlert.fragments = {
  predicateConclusion: gql`
    fragment ArticlePredicateAlert_predicateConclusion on PredicateConclusion {
      resolved
      predicate {
        title
        color
        description
      }
    }
  `,
};
