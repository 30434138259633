import styled, { x } from "@xstyled/styled-components";
import { memo } from "react";
import CountryFlag from "react-country-flag";
import { useField } from "react-final-form";

import { Ellipsis } from "@/components/Ellipsis";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { EnumField, StringField } from "@/containers/admin/CRUD";

const COUNTRY_CODES = [
  { value: "FR", name: "France", dialCode: "33" },
  { value: "US", name: "États-Unis", dialCode: "1" },
  { value: "CH", name: "Suisse", dialCode: "41" },
  { value: "AF", name: "Afghanistan", dialCode: "93" },
  { value: "ZA", name: "Afrique du Sud", dialCode: "27" },
  { value: "AL", name: "Albanie", dialCode: "355" },
  { value: "DZ", name: "Algérie", dialCode: "213" },
  { value: "DE", name: "Allemagne", dialCode: "49" },
  { value: "AD", name: "Andorre", dialCode: "376" },
  { value: "AO", name: "Angola", dialCode: "244" },
  { value: "AI", name: "Anguilla", dialCode: "1-264" },
  { value: "AQ", name: "Antarctique", dialCode: "672" },
  { value: "AG", name: "Antigua-et-Barbuda", dialCode: "1-268" },
  { value: "SA", name: "Arabie saoudite", dialCode: "966" },
  { value: "AR", name: "Argentine", dialCode: "54" },
  { value: "AM", name: "Arménie", dialCode: "374" },
  { value: "AW", name: "Aruba", dialCode: "297" },
  { value: "AU", name: "Australie", dialCode: "61" },
  { value: "AT", name: "Autriche", dialCode: "43" },
  { value: "AZ", name: "Azerbaïdjan", dialCode: "994" },
  { value: "BS", name: "Bahamas", dialCode: "1-242" },
  { value: "BH", name: "Bahreïn", dialCode: "973" },
  { value: "BD", name: "Bangladesh", dialCode: "880" },
  { value: "BB", name: "Barbade", dialCode: "1-246" },
  { value: "BE", name: "Belgique", dialCode: "32" },
  { value: "BZ", name: "Belize", dialCode: "501" },
  { value: "BJ", name: "Bénin", dialCode: "229" },
  { value: "BM", name: "Bermudes", dialCode: "1-441" },
  { value: "BT", name: "Bhoutan", dialCode: "975" },
  { value: "BY", name: "Biélorussie", dialCode: "375" },
  { value: "MM", name: "Birmanie", dialCode: "95" },
  { value: "BO", name: "Bolivie", dialCode: "591" },
  { value: "BA", name: "Bosnie-Herzégovine", dialCode: "387" },
  { value: "BW", name: "Botswana", dialCode: "267" },
  { value: "BR", name: "Brésil", dialCode: "55" },
  { value: "BN", name: "Brunei", dialCode: "673" },
  { value: "BG", name: "Bulgarie", dialCode: "359" },
  { value: "BF", name: "Burkina Faso", dialCode: "226" },
  { value: "BI", name: "Burundi", dialCode: "257" },
  { value: "KH", name: "Cambodge", dialCode: "855" },
  { value: "CM", name: "Cameroun", dialCode: "237" },
  { value: "CA", name: "Canada", dialCode: "1" },
  { value: "CV", name: "Cap-Vert", dialCode: "238" },
  { value: "CL", name: "Chili", dialCode: "56" },
  { value: "CN", name: "Chine", dialCode: "86" },
  { value: "CY", name: "Chypre", dialCode: "357" },
  { value: "CO", name: "Colombie", dialCode: "57" },
  { value: "KM", name: "Comores", dialCode: "269" },
  { value: "KP", name: "Corée du Nord", dialCode: "850" },
  { value: "KR", name: "Corée du Sud", dialCode: "82" },
  { value: "CR", name: "Costa Rica", dialCode: "506" },
  { value: "CI", name: "Côte d'Ivoire", dialCode: "225" },
  { value: "HR", name: "Croatie", dialCode: "385" },
  { value: "CU", name: "Cuba", dialCode: "53" },
  { value: "CW", name: "Curaçao", dialCode: "599" },
  { value: "DK", name: "Danemark", dialCode: "45" },
  { value: "DJ", name: "Djibouti", dialCode: "253" },
  { value: "DM", name: "Dominique", dialCode: "1-767" },
  { value: "EG", name: "Égypte", dialCode: "20" },
  { value: "AE", name: "Émirats arabes unis", dialCode: "971" },
  { value: "EC", name: "Équateur", dialCode: "593" },
  { value: "ER", name: "Érythrée", dialCode: "291" },
  { value: "ES", name: "Espagne", dialCode: "34" },
  { value: "EE", name: "Estonie", dialCode: "372" },
  { value: "SZ", name: "Eswatini", dialCode: "268" },
  { value: "FM", name: "États fédérés de Micronésie", dialCode: "691" },
  { value: "ET", name: "Éthiopie", dialCode: "251" },
  { value: "FJ", name: "Fidji", dialCode: "679" },
  { value: "FI", name: "Finlande", dialCode: "358" },
  { value: "GA", name: "Gabon", dialCode: "241" },
  { value: "GM", name: "Gambie", dialCode: "220" },
  { value: "GE", name: "Géorgie", dialCode: "995" },
  { value: "GH", name: "Ghana", dialCode: "233" },
  { value: "GI", name: "Gibraltar", dialCode: "350" },
  { value: "GR", name: "Grèce", dialCode: "30" },
  { value: "GD", name: "Grenade", dialCode: "1-473" },
  { value: "GL", name: "Groenland", dialCode: "299" },
  { value: "GU", name: "Guam", dialCode: "1-671" },
  { value: "GT", name: "Guatemala", dialCode: "502" },
  { value: "GG", name: "Guernesey", dialCode: "44-1481" },
  { value: "GN", name: "Guinée", dialCode: "224" },
  { value: "GQ", name: "Guinée équatoriale", dialCode: "240" },
  { value: "GW", name: "Guinée-Bissau", dialCode: "245" },
  { value: "GY", name: "Guyana", dialCode: "592" },
  { value: "HT", name: "Haïti", dialCode: "509" },
  { value: "HN", name: "Honduras", dialCode: "504" },
  { value: "HK", name: "Hong Kong", dialCode: "852" },
  { value: "HU", name: "Hongrie", dialCode: "36" },
  { value: "CX", name: "Île Christmas", dialCode: "61" },
  { value: "IM", name: "Île de Man", dialCode: "44-1624" },
  { value: "KY", name: "Îles Caïmans", dialCode: "1-345" },
  { value: "CC", name: "Îles Cocos", dialCode: "61" },
  { value: "CK", name: "Îles Cook", dialCode: "682" },
  { value: "FO", name: "Îles Féroé", dialCode: "298" },
  { value: "MP", name: "Îles Mariannes du Nord", dialCode: "1-670" },
  { value: "MH", name: "Îles Marshall", dialCode: "692" },
  { value: "PN", name: "Îles Pitcairn", dialCode: "64" },
  { value: "SB", name: "Îles Salomon", dialCode: "677" },
  { value: "TC", name: "Îles Turques-et-Caïques", dialCode: "1-649" },
  { value: "VG", name: "Îles Vierges britanniques", dialCode: "1-284" },
  { value: "VI", name: "Îles Vierges des États-Unis", dialCode: "1-340" },
  { value: "IN", name: "Inde", dialCode: "91" },
  { value: "ID", name: "Indonésie", dialCode: "62" },
  { value: "IQ", name: "Irak", dialCode: "964" },
  { value: "IR", name: "Iran", dialCode: "98" },
  { value: "IE", name: "Irlande", dialCode: "353" },
  { value: "IS", name: "Islande", dialCode: "354" },
  { value: "IL", name: "Israël", dialCode: "972" },
  { value: "IT", name: "Italie", dialCode: "39" },
  { value: "JM", name: "Jamaïque", dialCode: "1-876" },
  { value: "SJ", name: "Jan Mayen", dialCode: "47" },
  { value: "JP", name: "Japon", dialCode: "81" },
  { value: "JE", name: "Jersey", dialCode: "44-1534" },
  { value: "JO", name: "Jordanie", dialCode: "962" },
  { value: "KZ", name: "Kazakhstan", dialCode: "7" },
  { value: "KE", name: "Kenya", dialCode: "254" },
  { value: "KG", name: "Kirghizistan", dialCode: "996" },
  { value: "KI", name: "Kiribati", dialCode: "686" },
  { value: "XK", name: "Kosovo", dialCode: "383" },
  { value: "KW", name: "Koweït", dialCode: "965" },
  { value: "RE", name: "La Réunion", dialCode: "262" },
  { value: "LA", name: "Laos", dialCode: "856" },
  { value: "LS", name: "Lesotho", dialCode: "266" },
  { value: "LV", name: "Lettonie", dialCode: "371" },
  { value: "LB", name: "Liban", dialCode: "961" },
  { value: "LR", name: "Liberia", dialCode: "231" },
  { value: "LY", name: "Libye", dialCode: "218" },
  { value: "LI", name: "Liechtenstein", dialCode: "423" },
  { value: "LT", name: "Lituanie", dialCode: "370" },
  { value: "LU", name: "Luxembourg", dialCode: "352" },
  { value: "MO", name: "Macao", dialCode: "853" },
  { value: "MK", name: "Macédoine du Nord", dialCode: "389" },
  { value: "MG", name: "Madagascar", dialCode: "261" },
  { value: "MY", name: "Malaisie", dialCode: "60" },
  { value: "MW", name: "Malawi", dialCode: "265" },
  { value: "MV", name: "Maldives", dialCode: "960" },
  { value: "ML", name: "Mali", dialCode: "223" },
  { value: "FK", name: "Malouines", dialCode: "500" },
  { value: "MT", name: "Malte", dialCode: "356" },
  { value: "MA", name: "Maroc", dialCode: "212" },
  { value: "MU", name: "Maurice", dialCode: "230" },
  { value: "MR", name: "Mauritanie", dialCode: "222" },
  { value: "YT", name: "Mayotte", dialCode: "262" },
  { value: "MX", name: "Mexique", dialCode: "52" },
  { value: "MD", name: "Moldavie", dialCode: "373" },
  { value: "MC", name: "Monaco", dialCode: "377" },
  { value: "MN", name: "Mongolie", dialCode: "976" },
  { value: "ME", name: "Monténégro", dialCode: "382" },
  { value: "MS", name: "Montserrat", dialCode: "1-664" },
  { value: "MZ", name: "Mozambique", dialCode: "258" },
  { value: "NA", name: "Namibie", dialCode: "264" },
  { value: "NR", name: "Nauru", dialCode: "674" },
  { value: "NP", name: "Népal", dialCode: "977" },
  { value: "NI", name: "Nicaragua", dialCode: "505" },
  { value: "NE", name: "Niger", dialCode: "227" },
  { value: "NG", name: "Nigeria", dialCode: "234" },
  { value: "NU", name: "Niue", dialCode: "683" },
  { value: "NO", name: "Norvège", dialCode: "47" },
  { value: "NC", name: "Nouvelle-Calédonie", dialCode: "687" },
  { value: "NZ", name: "Nouvelle-Zélande", dialCode: "64" },
  { value: "OM", name: "Oman", dialCode: "968" },
  { value: "UG", name: "Ouganda", dialCode: "256" },
  { value: "UZ", name: "Ouzbékistan", dialCode: "998" },
  { value: "PK", name: "Pakistan", dialCode: "92" },
  { value: "PW", name: "Palaos", dialCode: "680" },
  { value: "PS", name: "Palestine", dialCode: "970" },
  { value: "PA", name: "Panama", dialCode: "507" },
  { value: "PG", name: "Papouasie-Nouvelle-Guinée", dialCode: "675" },
  { value: "PY", name: "Paraguay", dialCode: "595" },
  { value: "NL", name: "Pays-Bas", dialCode: "31" },
  { value: "BQ", name: "Pays-Bas caribéens", dialCode: "599" },
  { value: "PE", name: "Pérou", dialCode: "51" },
  { value: "PH", name: "Philippines", dialCode: "63" },
  { value: "PL", name: "Pologne", dialCode: "48" },
  { value: "PF", name: "Polynésie française", dialCode: "689" },
  { value: "PR", name: "Porto Rico", dialCode: "1-787, 1-939" },
  { value: "PT", name: "Portugal", dialCode: "351" },
  { value: "QA", name: "Qatar", dialCode: "974" },
  { value: "CF", name: "République centrafricaine", dialCode: "236" },
  { value: "CD", name: "République démocratique du Congo", dialCode: "243" },
  {
    value: "DO",
    name: "République dominicaine",
    dialCode: "1-809, 1-829, 1-849",
  },
  { value: "CG", name: "République du Congo", dialCode: "242" },
  { value: "CZ", name: "République tchèque", dialCode: "420" },
  { value: "RO", name: "Roumanie", dialCode: "40" },
  { value: "GB", name: "Royaume-Uni", dialCode: "44" },
  { value: "RU", name: "Russie", dialCode: "7" },
  { value: "RW", name: "Rwanda", dialCode: "250" },
  { value: "EH", name: "Sahara occidental", dialCode: "212" },
  { value: "MF", name: "Saint Martin", dialCode: "590" },
  { value: "BL", name: "Saint-Barthélemy", dialCode: "590" },
  { value: "KN", name: "Saint-Christophe-et-Niévès", dialCode: "1-869" },
  { value: "SM", name: "Saint-Marin", dialCode: "378" },
  { value: "SX", name: "Saint-Martin", dialCode: "1-721" },
  { value: "PM", name: "Saint-Pierre-et-Miquelon", dialCode: "508" },
  { value: "VC", name: "Saint-Vincent-et-les-Grenadines", dialCode: "1-784" },
  {
    value: "SH",
    name: "Sainte-Hélène, Ascension et Tristan da Cunha",
    dialCode: "290",
  },
  { value: "LC", name: "Sainte-Lucie", dialCode: "1-758" },
  { value: "SV", name: "Salvador", dialCode: "503" },
  { value: "WS", name: "Samoa", dialCode: "685" },
  { value: "AS", name: "Samoa américaines", dialCode: "1-684" },
  { value: "ST", name: "Sao Tomé-et-Principe", dialCode: "239" },
  { value: "SN", name: "Sénégal", dialCode: "221" },
  { value: "RS", name: "Serbie", dialCode: "381" },
  { value: "SC", name: "Seychelles", dialCode: "248" },
  { value: "SL", name: "Sierra Leone", dialCode: "232" },
  { value: "SG", name: "Singapour", dialCode: "65" },
  { value: "SK", name: "Slovaquie", dialCode: "421" },
  { value: "SI", name: "Slovénie", dialCode: "386" },
  { value: "SO", name: "Somalie", dialCode: "252" },
  { value: "SD", name: "Soudan", dialCode: "249" },
  { value: "SS", name: "Soudan du Sud", dialCode: "211" },
  { value: "LK", name: "Sri Lanka", dialCode: "94" },
  { value: "SE", name: "Suède", dialCode: "46" },
  { value: "SR", name: "Suriname", dialCode: "597" },
  { value: "SY", name: "Syrie", dialCode: "963" },
  { value: "TJ", name: "Tadjikistan", dialCode: "992" },
  { value: "TW", name: "Taiwan", dialCode: "886" },
  { value: "TZ", name: "Tanzanie", dialCode: "255" },
  { value: "TD", name: "Tchad", dialCode: "235" },
  {
    value: "IO",
    name: "Territoire britannique de l'océan Indien",
    dialCode: "246",
  },
  { value: "TH", name: "Thaïlande", dialCode: "66" },
  { value: "TL", name: "Timor oriental", dialCode: "670" },
  { value: "TG", name: "Togo", dialCode: "228" },
  { value: "TK", name: "Tokelau", dialCode: "690" },
  { value: "TO", name: "Tonga", dialCode: "676" },
  { value: "TT", name: "Trinité-et-Tobago", dialCode: "1-868" },
  { value: "TN", name: "Tunisie", dialCode: "216" },
  { value: "TM", name: "Turkménistan", dialCode: "993" },
  { value: "TR", name: "Turquie", dialCode: "90" },
  { value: "TV", name: "Tuvalu", dialCode: "688" },
  { value: "UA", name: "Ukraine", dialCode: "380" },
  { value: "UY", name: "Uruguay", dialCode: "598" },
  { value: "VU", name: "Vanuatu", dialCode: "678" },
  { value: "VA", name: "Vatican", dialCode: "379" },
  { value: "VE", name: "Venezuela", dialCode: "58" },
  { value: "VN", name: "Viêt Nam", dialCode: "84" },
  { value: "WF", name: "Wallis-et-Futuna", dialCode: "681" },
  { value: "YE", name: "Yémen", dialCode: "967" },
  { value: "ZM", name: "Zambie", dialCode: "260" },
  { value: "ZW", name: "Zimbabwe", dialCode: "263" },
];

const PhoneRegionCodeOption = memo(({ value, name, dialCode }) => {
  return (
    <x.span display="flex" alignItems="center" gap={2}>
      <CountryFlag svg countryCode={value} />
      <span>{name}</span>
      <span>+{dialCode}</span>
    </x.span>
  );
});

const PhoneRegionCodeValue = memo(({ value, name, dialCode }) => {
  return (
    <x.span display="flex" alignItems="center" gap={2}>
      <CountryFlag svg countryCode={value} />
      <Ellipsis maxW="120px">{name}</Ellipsis>
      <x.span overflow="hidden" maxW="60px" textOverflow="clip">
        +{dialCode}
      </x.span>
    </x.span>
  );
});

function PhoneRegionCodeField({ name, ...props }) {
  return (
    <EnumField
      name={`${name}.regionCode`}
      enum={COUNTRY_CODES}
      className="w-64"
      sortEntries={() => 1}
      labelSelector={(props) => <PhoneRegionCodeValue {...props} />}
      labelElementSelector={(props) => <PhoneRegionCodeOption {...props} />}
      {...props}
    />
  );
}

function NumberField({ name, ...props }) {
  const regionCode = useSubscribeFormValue(`${name}.regionCode`);
  return (
    <StringField
      name={`${name}.number`}
      type="phone-number"
      regionCode={regionCode}
      {...props}
    />
  );
}

const Label = styled.labelBox`
  font-family: accent;
  font-weight: 600;
`;

const PhoneNumberError = styled.spanBox`
  font-family: accent;
  color: danger-dark;
`;

export function PhoneNumberField({
  name,
  submitting,
  regionCodeLabel = "Choisissez votre pays",
  phoneNumberLabel = "Numéro de téléphone",
}) {
  const field = useField(name);
  const phoneNumberError = field.meta.touched ? field.meta.error?.number : "";

  return (
    <>
      <x.div display="flex" columnGap="24px" pb={4}>
        <x.div minW={260} w={260}>
          <PhoneRegionCodeField
            name={name}
            label={regionCodeLabel}
            disabled={submitting}
            required
          />
        </x.div>
        <x.div flexGrow={1} display="flex" rowGap={2} flexDirection="column">
          <Label htmlFor={name}>
            {phoneNumberLabel}
            <span title="Requis">*</span>
          </Label>
          <NumberField name={name} disabled={submitting} required />
          {phoneNumberError && (
            <PhoneNumberError>{phoneNumberError}</PhoneNumberError>
          )}
        </x.div>
      </x.div>
    </>
  );
}
