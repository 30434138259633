import { memo } from "react";

import { DateFiltersField } from "@/containers/admin/CRUD/filtersFields/DateFiltersField";

const name = "shootingDate";

const Filter = memo(() => {
  return (
    <DateFiltersField name={name} label="Date de prise de vue" scale="base" />
  );
});

export const ShootingDateFilter = {
  name,
  element: <Filter />,
  initialValue: null,
};
