import {
  Button as AriakitButton,
  ButtonProps as AriakitButtonProps,
} from "ariakit/button";
import clsx from "clsx";
import { forwardRef } from "react";

export type CapsuleButtonProps = AriakitButtonProps;

export const CapsuleButton = forwardRef<HTMLButtonElement, CapsuleButtonProps>(
  (props, ref) => {
    return (
      <AriakitButton
        ref={ref}
        className={clsx(
          "flex items-center justify-center rounded-full border border-grey-border-light bg-white p-1 text-xs text-grey-on-light opacity-0",
          "group-hover/capsule:opacity-100",
          "hover:text-primary-on-light",
          "aria-pressed:bg-dusk-600 aria-pressed:text-white aria-pressed:opacity-100",
          "aria-pressed:hover:bg-dusk-300 aria-pressed:hover:text-white",
          "disabled:text-grey-on-light/50",
        )}
        {...props}
      />
    );
  },
);
