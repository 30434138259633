import { x } from "@xstyled/styled-components";
import { Button } from "swash/Button";
import { Tooltip } from "swash/Tooltip";
import { useClipboard } from "use-clipboard-copy";

import { CopyButton } from "./CopyButton";

export function GlobalId({ globalId }) {
  return (
    <x.div display="flex" alignItems="center" gap={1} fontSize="xs" my={1}>
      Global ID: {globalId}{" "}
      <CopyButton value={globalId} title="Copier l’ID" scale="xs" />
    </x.div>
  );
}

export const GlobalIdButton = ({ globalId, ...props }) => {
  const clipboard = useClipboard({ copiedTimeout: 1000 });
  return (
    <Tooltip tooltip={`${globalId} — Cliquer pour copier`} delay={0}>
      <Button
        variant="secondary"
        appearance="text"
        onClick={() => clipboard.copy(globalId)}
        aria-pressed={clipboard.copied}
        {...props}
      >
        {clipboard.copied ? "Copié" : "#ID"}
      </Button>
    </Tooltip>
  );
};
