import { gql } from "@apollo/client";
import { Dialog as AriakitDialog } from "@ariakit/react";
import clsx from "clsx";
import { memo, useEffect, useMemo, useState } from "react";
import { useField } from "react-final-form";
import { Button } from "swash/Button";
import { IoClose } from "swash/Icon";
import { PageLoader } from "swash/Loader";
import { useLiveRef } from "swash/utils/useLiveRef";

import { ImageFluidFragment } from "@/components/Image";
import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { FormSubmittingPrompt } from "@/components/forms/FormPrompt";
import { FormSavingIndicator } from "@/components/forms/FormSavingIndicator";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";
import { ImageCrop } from "@/containers/image/ImageCrop";
import { ImageCropPreview } from "@/containers/image/ImageCropPreview";
import { ImageDescription } from "@/containers/image/ImagePreview";

import { FormSubmit } from "../../components/forms/FormSubmit";
import { AspectRatioField } from "./fields/AspectRatioField";

export const ImageCropDialog = ({
  articleMediaId,
  dialogCloseButtonRef,
  className,
  store,
}) => {
  const open = store.useState("open");
  return (
    <AriakitDialog
      aria-label="Éditer les formats de l’image"
      className={clsx(
        className,
        `fixed inset-0 z-dialog overflow-hidden bg-dusk-900/90 p-3 focus:outline-0`,
      )}
      initialFocusRef={dialogCloseButtonRef}
      store={store}
    >
      {open && (
        <ImageCropDialogContent
          articleMediaId={articleMediaId}
          onClose={store.hide}
        />
      )}
    </AriakitDialog>
  );
};

const ArticleMediaFragment = gql`
  fragment ImageCropDialog_articleMedia on ArticleMedia {
    id
    metadata
    media {
      id
      ... on Image {
        width
        height
        imgProps: fluid(maxHeight: 900) {
          ...ImageFluidFragment
        }
        thumbnailProps: fluid(maxWidth: 120) {
          ...ImageFluidFragment
        }
        ...ImageDescription_image
      }
    }
  }

  ${ImageFluidFragment}
  ${ImageDescription.fragments.image}
`;

const ArticleMediaQuery = gql`
  query ImageCropDialog_articleMedia($id: Int!) {
    articleMedia(id: $id) {
      id
      ...ImageCropDialog_articleMedia
    }
    aspectRatios(where: { enabled: true }) {
      nodes {
        id
        label
        slug
        x
        y
        ...AspectRatioField_aspectRatios
      }
    }
    dbConfig {
      articles {
        defaultAspectRatioId
      }
    }
  }

  ${ArticleMediaFragment}
  ${AspectRatioField.fragments.aspectRatios}
`;

const UpdateArticleMediaMutation = gql`
  mutation ArticleImageEdit_updateArticleMedia(
    $id: Int!
    $metadata: UpdateArticleMediaMetadataInput
  ) {
    updateArticleMedia(input: { id: $id, metadata: $metadata }) {
      id
      ...ImageCropDialog_articleMedia
      # Cache update of edit page
      defaultCropRegion {
        top
        left
        width
        height
      }
    }
  }
  ${ArticleMediaFragment}
`;

const ImageCropDialogContent = ({ articleMediaId, onClose }) => {
  const { data } = useSafeQuery(ArticleMediaQuery, {
    variables: { id: articleMediaId },
  });
  if (!data) return <PageLoader />;
  return (
    <ImageCropDialogForm
      articleMedia={data.articleMedia}
      aspectRatios={data.aspectRatios.nodes}
      defaultAspectRatioId={data.dbConfig.articles.defaultAspectRatioId}
      onClose={onClose}
    />
  );
};

const ThumbnailCropPreview = memo(({ index, imgProps, imgSize }) => {
  const region = useSubscribeFormValue(`crops[${index}]`);
  return (
    <ImageCropPreview imgProps={imgProps} imgSize={imgSize} region={region} />
  );
});

const AspectRatioThumbnail = ({
  selected,
  favorite,
  index,
  onClick,
  aspectRatio,
  imgProps,
  imgSize,
}) => {
  return (
    <div
      role="button"
      aria-label={aspectRatio.label}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
      className={clsx(
        "cursor-pointer rounded-sm border p-2 transition",
        favorite ? "border-success-border" : "border-grey-border",
        selected ? "opacity-100" : "opacity-60",
      )}
    >
      <ThumbnailCropPreview
        index={index}
        imgProps={imgProps}
        imgSize={imgSize}
      />
      <div className="text-center font-semibold text-white">
        {aspectRatio.label}
      </div>
    </div>
  );
};

const CropEditorField = ({ name, ...props }) => {
  const field = useField(name, { format: (x) => x ?? null, parse: (x) => x });
  return (
    <ImageCrop
      {...props}
      region={field.input.value}
      onChange={(value) => field.input.onChange(value)}
    />
  );
};

const formatCrops = (aspectRatios, crops) => {
  return aspectRatios.map(
    (aspectRatio) =>
      crops[aspectRatio.slug] ?? { left: 0, top: 0, width: 0, height: 0 },
  );
};

const parseCrops = (aspectRatios, crops) => {
  return aspectRatios.reduce((obj, aspectRatio, index) => {
    // eslint-disable-next-line no-param-reassign
    obj[aspectRatio.slug] = crops[index];
    return obj;
  }, {});
};

const SpyAspectRatioId = ({ onChange }) => {
  const onChangeRef = useLiveRef(onChange);
  const aspectRatioId = useSubscribeFormValue("aspectRatioId");

  useEffect(() => {
    onChangeRef.current(aspectRatioId);
  }, [onChangeRef, aspectRatioId]);

  return null;
};

const SelectFormatButton = memo(({ editedAspectRatioId }) => {
  const field = useField("aspectRatioId", {
    parse: (v) => v,
    format: (v) => v,
  });
  const active = editedAspectRatioId === field.input.value;
  return (
    <Button
      type="button"
      variant={active ? "success" : undefined}
      disabled={active}
      aria-pressed={active}
      onClick={() => {
        field.input.onChange(editedAspectRatioId);
      }}
    >
      {active
        ? "Sélectionné comme format dans l’article"
        : "Sélectionner comme format dans l’article"}
    </Button>
  );
});

const ImageCropDialogForm = ({
  articleMedia,
  aspectRatios,
  defaultAspectRatioId,
  onClose,
}) => {
  const aspectRatioId = (() => {
    if (articleMedia.metadata?.aspectRatioId) {
      const aspectRatioId = Number(articleMedia.metadata.aspectRatioId);
      if (aspectRatios.find((ratio) => ratio.id === aspectRatioId)) {
        return aspectRatioId;
      }
    }
    return defaultAspectRatioId ?? aspectRatios[0]?.id ?? null;
  })();

  const [updateArticleMedia] = useSafeMutation(UpdateArticleMediaMutation, {
    variables: {
      id: articleMedia.id,
    },
  });
  const [editedAspectRatioId, setEditedAspectRatioId] = useState(aspectRatioId);

  const editedAspectRatio = useMemo(
    () =>
      aspectRatios.find(
        (aspectRatio) => aspectRatio.id === editedAspectRatioId,
      ),
    [aspectRatios, editedAspectRatioId],
  );

  const crops = useMemo(
    () => formatCrops(aspectRatios, articleMedia.metadata.crops),
    [aspectRatios, articleMedia.metadata],
  );
  const initialValues = useMemo(
    () => ({ aspectRatioId, crops }),
    [aspectRatioId, crops],
  );
  const handleSubmit = async (
    { crops, aspectRatioId },
    form,
    { submitter },
  ) => {
    await updateArticleMedia({
      variables: {
        metadata: {
          crops: parseCrops(aspectRatios, crops),
          aspectRatioId,
        },
      },
    });
    if (submitter?.name === "close-button") {
      onClose();
    }
  };

  return (
    <Form
      collaborative
      initialValues={initialValues}
      onSubmit={handleSubmit}
      className="contents"
    >
      <FormAutoSubmit debounceDelay={2000} />
      <FormSubmittingPrompt />
      <SpyAspectRatioId
        onChange={(aspectRatioId) => setEditedAspectRatioId(aspectRatioId)}
      />
      <div className="h-full [&>label]:text-white">
        <div
          className="grid h-full gap-4 overflow-hidden"
          style={{ gridTemplateColumns: "max-content 1fr" }}
        >
          <div className="flex flex-1 flex-col gap-4 overflow-auto">
            {aspectRatios.map((aspectRatio, index) => (
              <AspectRatioThumbnail
                key={aspectRatio.id}
                aspectRatio={aspectRatio}
                selected={editedAspectRatio.id === aspectRatio.id}
                favorite={aspectRatioId === aspectRatio.id}
                imgProps={{
                  ...articleMedia.media.thumbnailProps,
                  style: { maxHeight: "30vh" },
                }}
                imgSize={{
                  width: articleMedia.media.width,
                  height: articleMedia.media.height,
                }}
                index={index}
                onClick={() => setEditedAspectRatioId(aspectRatio.id)}
              />
            ))}
          </div>

          <div className="flex flex-col items-center justify-center gap-4 overflow-hidden">
            <div className="flex h-0 flex-1 items-center justify-center">
              <CropEditorField
                imgProps={{
                  ...articleMedia.media.imgProps,
                  style: {
                    height: "auto",
                    width: "auto",
                    maxHeight: "100%",
                    maxWidth: "100%",
                    aspectRatio: `${articleMedia.media.width} / ${articleMedia.media.height}`,
                  },
                }}
                imgSize={{
                  width: articleMedia.media.width,
                  height: articleMedia.media.height,
                }}
                constraint={{ x: editedAspectRatio.x, y: editedAspectRatio.y }}
                name={`crops[${aspectRatios.indexOf(editedAspectRatio)}]`}
              />
            </div>
            <div className="flex shrink-0 gap-4">
              <SelectFormatButton editedAspectRatioId={editedAspectRatio.id} />
            </div>
          </div>
        </div>
        <div className="absolute right-4 top-4 flex items-center gap-4">
          <FormSavingIndicator />
          <FormSubmit name="close-button" asChild>
            <Button
              appearance="text"
              scale="sm"
              iconOnly
              variant="secondary"
              aria-label="Fermer"
            >
              <IoClose />
            </Button>
          </FormSubmit>
        </div>
      </div>
    </Form>
  );
};
