import clsx from "clsx";
import { useRef } from "react";
import { Tooltip } from "swash/Tooltip";
import { useHasOverflow } from "swash/utils/useHasOverflow";

export const ChangeText: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { rawText?: string }
> = ({ children, rawText, className, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);
  const hasOverflowY = useHasOverflow({ ref, dimension: "y" });
  const hasOverflowX = useHasOverflow({ ref, dimension: "x" });
  const element = (
    <div
      ref={ref}
      className={clsx(
        "data-[change=added]:text-success-on data-[change=deleted]:text-danger-on data-[change=deleted]:line-through",
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );

  if ((rawText && hasOverflowY) || hasOverflowX) {
    return <Tooltip tooltip={rawText}>{element}</Tooltip>;
  }
  return element;
};
