import { exclude } from "./exclude";
import { include } from "./include";
import { sentence } from "./sentence";

export const operators = [include, exclude, sentence];

export const wordOperators = [
  ...operators.filter(({ type, isDefault }) => type === "word" && !isDefault),
  ...operators.filter(({ isDefault }) => isDefault),
];

export const textOperators = operators.filter(({ type }) => type === "text");
