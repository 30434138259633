import {
  Dialog as AriakitDialog,
  DialogProps as AriakitDialogProps,
  DialogDisclosure,
  DialogDisclosureProps,
  DialogStore,
  useDialogStore,
} from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";

import { PanelProvider, usePanelContext } from "../Panel";

export type { DialogStoreProps } from "@ariakit/react";
export type { DialogDisclosureProps, DialogStore };
export { useDialogStore, DialogDisclosure };

export type DialogApi = {
  forceOutsideDialogInteractions: () => () => void;
  disableModal: () => () => void;
};

const GlobalPropsContext = React.createContext<Omit<
  DialogProps,
  "state"
> | null>(null);
const ApiContext = React.createContext<DialogApi | null>(null);

const useGlobalProps = () => {
  return React.useContext(GlobalPropsContext);
};

export const useOptionalDialogApi = () => {
  return React.useContext(ApiContext);
};

export const useDialogApi = () => {
  const api = useOptionalDialogApi();
  if (!api) {
    throw new Error("useDialogApi must be used within a DialogProvider");
  }
  return api;
};

export type DialogProviderProps = {
  children: React.ReactNode;
};

export const DialogProvider = (props: DialogProviderProps) => {
  const [forcedOutsideInteractions, setForcedOutsideInteractions] =
    React.useState(0);
  const [modalDisabled, setModalDisabled] = React.useState(0);
  const forceOutsideDialogInteractions = React.useCallback(() => {
    setForcedOutsideInteractions((prev) => prev + 1);
    return () => {
      setForcedOutsideInteractions((prev) => prev - 1);
    };
  }, []);
  const disableModal = React.useCallback(() => {
    setModalDisabled((prev) => prev + 1);
    return () => {
      setModalDisabled((prev) => prev - 1);
    };
  }, []);
  const api = React.useMemo(() => {
    return { forceOutsideDialogInteractions, disableModal };
  }, [forceOutsideDialogInteractions, disableModal]);
  const globalProps = React.useMemo(() => {
    let props = {};
    if (forcedOutsideInteractions > 0) {
      props = { ...props, hideOnInteractOutside: false, hideOnEscape: false };
    }
    if (modalDisabled > 0) {
      props = { ...props, modal: false, backdrop: true, portal: true };
    }
    return props;
  }, [forcedOutsideInteractions, modalDisabled]);
  return (
    <ApiContext.Provider value={api}>
      <GlobalPropsContext.Provider value={globalProps}>
        {props.children}
      </GlobalPropsContext.Provider>
    </ApiContext.Provider>
  );
};

export const backdropProps = {
  className: "fixed inset-0 bg-dusk-700/[.85] z-dialog",
};

export const DialogBackdrop = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <div ref={ref} className={backdropProps.className} {...props} />;
});

export type DialogProps = AriakitDialogProps;

const WrappedDialog = React.forwardRef<HTMLDivElement, DialogProps>(
  ({ children, store, className, style, ...props }, ref) => {
    const globalProps = useGlobalProps();
    const context = usePanelContext();
    return (
      <AriakitDialog
        ref={ref}
        backdrop={DialogBackdrop}
        store={store}
        className={clsx(
          className,
          "fixed left-1/2 top-1/2 z-dialog flex -translate-x-1/2 -translate-y-1/2 flex-col rounded bg-white shadow-md focus:outline-none",
        )}
        style={{
          ...style,
          maxHeight: style?.maxHeight ?? "calc(100vh - 16px)",
          maxWidth: style?.maxWidth ?? "90%",
          width: style?.width ?? 600,
        }}
        aria-labelledby={context?.titleId}
        {...props}
        {...globalProps}
      >
        {children}
      </AriakitDialog>
    );
  },
);

export const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  (props, ref) => {
    return (
      <PanelProvider>
        <WrappedDialog ref={ref} {...props} />
      </PanelProvider>
    );
  },
);
