import styled, { x } from "@xstyled/styled-components";
import { forwardRef } from "react";

export const InnerEllipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Ellipsis = forwardRef((props, ref) => {
  return <x.div as={InnerEllipsis} ref={ref} {...props} />;
});
