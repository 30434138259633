import { gql } from "@apollo/client";
import { useId } from "ariakit-react-utils/hooks";
import { useField } from "react-final-form";
import { Checkbox } from "swash/controls/Checkbox";

type CorrectedFieldProps = {
  disabled: boolean;
};

export const CorrectedField = ({ disabled }: CorrectedFieldProps) => {
  const field = useField("corrected", { type: "checkbox" });
  const id = useId();

  return (
    <div className="flex items-center gap-2">
      <Checkbox id={id} {...field.input} disabled={disabled} />
      <label className="font-accent text-sm text-dusk-on" htmlFor={id}>
        Corrigé
      </label>
    </div>
  );
};

CorrectedField.fragments = {
  post: gql`
    fragment CorrectedField_post on Post {
      corrected
    }
  `,
};
