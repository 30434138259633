import { gql } from "@apollo/client";
import clsx from "clsx";
import { memo } from "react";

export type ArticleEditorialTypeLabelProps = {
  article: {
    editorialType: {
      id: number;
      label: string;
    };
  };
} & { className?: string };

type SpreadArticleEditorialTypeLabelProps =
  ArticleEditorialTypeLabelProps["article"] & { className?: string };

export const SpreadArticleEditorialTypeLabel =
  memo<SpreadArticleEditorialTypeLabelProps>(({ editorialType, className }) => {
    return (
      <span className={clsx(className, "select-none font-accent uppercase")}>
        {editorialType.label}
      </span>
    );
  });

export const ArticleEditorialTypeLabel = ({
  article,
  ...props
}: ArticleEditorialTypeLabelProps) => {
  return (
    <SpreadArticleEditorialTypeLabel
      editorialType={article.editorialType}
      {...props}
    />
  );
};

ArticleEditorialTypeLabel.fragments = {
  article: gql`
    fragment ArticleEditorialTypeLabel_article on Article {
      editorialType {
        id
        label
      }
    }
  `,
};
