import {
  Select as AriakitSelect,
  SelectItem as AriakitSelectItem,
  SelectItemProps as AriakitSelectItemProps,
  SelectList as AriakitSelectList,
  SelectListProps as AriakitSelectListProps,
  SelectPopover as AriakitSelectPopover,
  SelectPopoverProps as AriakitSelectPopoverProps,
  SelectProps as AriakitSelectProps,
  SelectSeparator as AriakitSelectSeparator,
  SelectSeparatorProps as AriakitSelectSeparatorProps,
  SelectStore,
  SelectStoreProps,
  useSelectStore as useAriakitSelectState,
} from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

import { Button, ButtonProps } from "../Button";
import {
  IoCheckmark,
  IoChevronDown,
  IoChevronUp,
  IoCloseCircleOutline,
} from "../Icon";
import {
  ItemClassNameProps,
  ListSeparatorClassNameProps,
  getItemClassName,
  getListSeparatorClassName,
} from "../Menu";
import { PopoverCard } from "../Popover";
import { InputClassNameProps, getInputClassName } from "./TextInput";

export type { SelectStore };
export { SelectLabel } from "ariakit/select";

export const useSelectStore = ({ ...props }: SelectStoreProps = {}) => {
  return useAriakitSelectState(props);
};

export type SelectListProps = AriakitSelectListProps;

export const SelectList = forwardRef<HTMLDivElement, SelectListProps>(
  (props, ref) => {
    return <AriakitSelectList ref={ref} focusable={false} {...props} />;
  },
);

export interface SelectArrowProps {
  open: boolean;
}

export const SelectArrow: React.FC<SelectArrowProps> = (props) => {
  return (
    <div className="pointer-events-none">
      {props.open ? <IoChevronUp /> : <IoChevronDown />}
    </div>
  );
};

export interface SelectClearProps extends ButtonProps {
  store: SelectStore;
  clearable?: boolean;
  onClear?: (value: any) => void;
}

export const SelectClear = forwardRef<HTMLButtonElement, SelectClearProps>(
  (
    {
      store,
      "aria-label": ariaLabel = "Vider",
      onClear,
      clearable = true,
      ...props
    },
    ref,
  ) => {
    const value = store.useState("value");
    const selectElement = store.useState("selectElement");
    const open = store.useState("open");
    const empty = !value || (Array.isArray(value) && value.length === 0);
    if (empty || !clearable) {
      return <SelectArrow open={open} />;
    }

    return (
      <Button
        ref={ref}
        appearance="text"
        scale="xs"
        iconOnly
        asChild
        onMouseDown={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (onClear) {
            onClear(value);
          } else {
            store.setValue((value) => {
              if (Array.isArray(value)) {
                return [];
              }
              return "";
            });
          }
          // on clear, force focus and blur so the form
          // is considered as touched and errors are displayed
          selectElement?.focus();
          selectElement?.blur();
          store.hide();
        }}
        className="-my-2 -mr-1"
        aria-label={ariaLabel}
        {...props}
      >
        <div>
          <IoCloseCircleOutline />
        </div>
      </Button>
    );
  },
);

export interface SelectProps
  extends AriakitSelectProps<"button">,
    Omit<InputClassNameProps, "placeholder"> {}

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  ({ className, scale, children, ...props }, ref) => {
    const value = props.store?.useState("value");

    const placeholder = value === "" || value?.length === 0;
    const inputClassName = getInputClassName({
      className,
      scale,
      placeholder,
    });
    const funcChildren = typeof children === "function";

    return (
      <AriakitSelect
        ref={ref}
        className={clsx(
          inputClassName,
          !funcChildren &&
            "inline-flex items-center justify-between gap-2 overflow-hidden overflow-ellipsis whitespace-nowrap bg-white",
        )}
        {...props}
      >
        {children}
      </AriakitSelect>
    );
  },
);

export interface SelectButtonProps extends SelectProps {
  /**
   * Use the child as the component.
   */
  asChild?: boolean;
  children?: React.ReactNode;
}

export const SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>(
  ({ children, asChild, ...props }, ref) => {
    return (
      <AriakitSelect
        ref={ref}
        render={asChild ? (children as React.ReactElement) : <AriakitSelect />}
        {...props}
        role="button"
      >
        {asChild ? null : children}
      </AriakitSelect>
    );
  },
);

const SelectItemCheckedContext = React.createContext(false);

export function checkIsSelected(
  stateValue?: string | string[],
  itemValue?: string,
) {
  if (stateValue == null) return false;
  if (itemValue == null) return false;
  if (Array.isArray(stateValue)) {
    return stateValue.includes(itemValue);
  }
  return stateValue === itemValue;
}

export type SelectItemProps = AriakitSelectItemProps & ItemClassNameProps;

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ variant, className, ...props }, ref) => {
    const value = props.store?.useState("value");
    const itemClassName = getItemClassName({ variant, className });
    const checked = checkIsSelected(value, props.value);
    return (
      <SelectItemCheckedContext.Provider value={checked}>
        <AriakitSelectItem ref={ref} className={itemClassName} {...props} />
      </SelectItemCheckedContext.Provider>
    );
  },
);

export const SelectMarker = React.memo(
  ({ multi, checked }: { multi: boolean; checked: boolean }) => {
    if (multi) {
      return (
        <div
          className={clsx(
            "flex shrink-0 items-center justify-center rounded-sm text-white",
            checked
              ? "bg-primary-bg-strong"
              : "bg-white ring-1 ring-grey-border-light",
          )}
          style={{ width: 14, height: 14 }}
        >
          <IoCheckmark size={10} />
        </div>
      );
    }
    return (
      <IoCheckmark className={clsx("shrink-0", !checked && "invisible")} />
    );
  },
);

export const SelectItemCheck = React.memo<{ store?: SelectStore }>(
  ({ store }) => {
    const value = store?.useState("value");
    const multi = Array.isArray(value);
    const singleEmpty = value === "";
    const checked = React.useContext(SelectItemCheckedContext);
    if (singleEmpty) {
      return null;
    }
    return <SelectMarker multi={multi} checked={checked} />;
  },
);

export interface SelectPopoverProps
  extends Omit<AriakitSelectPopoverProps, "children"> {
  children: React.ReactNode;
  /**
   * Whether the select contains a combobox.
   * @default false
   */
  combobox?: boolean;
}

export const SelectPopover = forwardRef<HTMLDivElement, SelectPopoverProps>(
  ({ children, className, style, combobox, gutter = 4, ...props }, ref) => {
    return (
      <AriakitSelectPopover
        ref={ref}
        className={clsx(
          className,
          combobox ? "flex flex-col overflow-hidden" : "overflow-auto p-1",
        )}
        wrapperProps={{
          className: "!z-popover",
        }}
        gutter={gutter}
        style={{
          maxHeight: "min(var(--popover-available-height, 320px), 320px)",
          ...style,
        }}
        composite={!combobox}
        {...props}
        render={(popoverProps) => (
          <PopoverCard {...popoverProps}>{children}</PopoverCard>
        )}
      />
    );
  },
);

export type SelectSeparatorProps = AriakitSelectSeparatorProps &
  ListSeparatorClassNameProps;

export const SelectSeparator = forwardRef<HTMLHRElement, SelectSeparatorProps>(
  ({ className, ...props }, ref) => {
    const listSeparatorClassName = getListSeparatorClassName({ className });
    return (
      <AriakitSelectSeparator
        ref={ref}
        className={listSeparatorClassName}
        {...props}
      />
    );
  },
);
