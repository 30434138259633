import AtlaskitTree, { moveItemOnTree, mutateTree } from "@atlaskit/tree";
import styled from "@xstyled/styled-components";
import { useEffect, useState } from "react";

const TreeNode = styled.box`
  cursor: move;

  [data-row] {
    display: flex;
    align-items: center;
    border-style: solid;
    border-width: 1;
    border-color: layout-border;
    background-color: white;
    border-radius: base;
    padding: 2px 2;
    transition: base;

    &[data-landing-pad="true"] {
      border-style: dashed;

      [data-content] {
        opacity: 0;
      }

      &[data-droppable="true"] {
        background-color: grey-lightest;
      }
      &[data-droppable="false"] {
        background-color: danger-lighter;
        border-color: danger-light;
      }
    }
  }
`;

const TreeNodeConnection = styled.box`
  width: 30px;
  height: 100%;
  position: relative;
  display: inline-block;
  box-sizing: border-box;

  &::before {
    height: 1px;
    top: 50%;
    right: 0;
    width: 50%;
    position: absolute;
    content: "";
    background-color: black;
  }

  &::after {
    top: auto;
    bottom: 0;
    height: 100%;
    width: 1px;
    left: 50%;
    position: absolute;
    content: "";
    background-color: black;
  }

  &[data-variant="first"]::after {
    height: 50%;
  }

  &[data-variant="last"]::after {
    top: 0;
    height: 50%;
  }

  &[data-variant="alone"]::before,
  &[data-variant="alone"]::after {
    display: none;
  }

  &[data-variant="dragging"]::before {
    background-color: transparent !important;
  }

  &[data-variant="dragging"]::after {
    background-color: transparent !important;
  }
`;

const PADDING_PER_LEVEL = 16;

export const Tree = ({ value, onChange }) => {
  const [tree, setTree] = useState(value);

  useEffect(() => {
    setTree(value);
  }, [value]);

  const renderItem = ({ item, provided, snapshot }) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <TreeNode h={36} row alignItems="center">
          <TreeNodeConnection
            data-variant={
              snapshot.isDragging
                ? "dragging"
                : item.first && item.last
                  ? "alone"
                  : item.first
                    ? "first"
                    : item.last
                      ? "last"
                      : ""
            }
          />
          <div data-row>
            <div data-content>{item.content}</div>
          </div>
        </TreeNode>
      </div>
    );
  };

  const onExpandNode = (itemId) => {
    setTree((internalTree) => {
      const newTree = mutateTree(internalTree, itemId, { isExpanded: true });
      onChange(newTree);
      return newTree;
    });
  };

  const onCollapseNode = (itemId) => {
    setTree((internalTree) => {
      const newTree = mutateTree(internalTree, itemId, { isExpanded: false });
      onChange(newTree);
      return newTree;
    });
  };

  const onDragEnd = (source, destination) => {
    if (!destination) {
      return;
    }
    setTree((internalTree) => {
      const newTree = moveItemOnTree(internalTree, source, destination);
      onChange(newTree);
      return newTree;
    });
  };

  if (!tree) return null;
  return (
    <AtlaskitTree
      tree={tree}
      renderItem={renderItem}
      onExpand={onExpandNode}
      onCollapse={onCollapseNode}
      onDragEnd={onDragEnd}
      offsetPerLevel={PADDING_PER_LEVEL}
      isDragEnabled
    />
  );
};
