import type { CompositeState } from "ariakit/composite";
import { useRef } from "react";

const frenchCollator = new Intl.Collator("fr", { sensitivity: "base" });

function findFirstMatchingComposite({
  composite,
  search,
  collator = frenchCollator,
}: {
  composite: CompositeState;
  search: string;
  collator?: Intl.Collator;
}) {
  if (!composite?.items?.length) return null;
  const matchingItemIndex = composite.items.findIndex((item) => {
    if (!item.ref.current) return false;
    const itemText = item.ref.current.innerText.slice(0, search.length);
    return collator.compare(itemText, search) === 0;
  });
  return matchingItemIndex !== -1 ? matchingItemIndex : null;
}

function focusCompositeKey(composite: CompositeState, key: number) {
  const item = composite.items[key];
  if (item) {
    composite.move(item.id);
  }
}

function getCharacter(key: string) {
  // If the key is of length 1, it is an ASCII value.
  // Otherwise, if there are no ASCII characters in the key name,
  // it is a Unicode character.
  // See https://www.w3.org/TR/uievents-key/
  if (key.length === 1 || !/^[A-Z]/i.test(key)) {
    return key;
  }
  return "";
}

interface TypeSelectState {
  search: string;
  timeout: null | NodeJS.Timeout;
}

export function useTypeSelect(composite: CompositeState) {
  const state = useRef<TypeSelectState>({ search: "", timeout: null }).current;

  const onKeyDown = (event: React.KeyboardEvent) => {
    const character = getCharacter(event.key);
    if (!character || event.ctrlKey || event.metaKey) {
      return;
    }

    state.search += character;

    const key = findFirstMatchingComposite({ composite, search: state.search });
    if (key !== null) {
      focusCompositeKey(composite, key);
    }

    if (state.timeout) {
      clearTimeout(state.timeout);
    }
    state.timeout = setTimeout(() => {
      state.search = "";
    }, 500);
  };

  return { onKeyDown };
}
