import { useId } from "ariakit-react-utils";
import {
  Disclosure,
  DisclosureContent,
  useDisclosureState,
} from "ariakit/disclosure";
import clsx from "clsx";
import moment from "moment";
import {
  Children,
  isValidElement,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useField, useForm } from "react-final-form";
import { Checkbox } from "swash/controls/Checkbox";
import { FormCheckbox } from "swash/form/FormCheckbox";
import { shallowEqual } from "swash/utils/shallowEqual";
import { useHovered } from "swash/utils/useHovered";

import {
  SelectDatePickerCaret,
  SelectDatePickerClearButton,
  SelectDatePickerPlaceholder,
  SelectDatePickerValue,
  formatRange,
  parseRange,
  useSelectDatePickerState,
} from "@/components/controls/SelectDatePicker";
import { useSwitchField } from "@/components/fields/SwitchField";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import {
  DatePicker,
  SelectDateFilters,
  formatDate,
  generatePresets,
  parseDate,
} from "@/containers/admin/CRUD/filtersFields/DateFiltersField";

const presets = generatePresets([
  { label: "Aujourd'hui", from: 0 },
  { label: "Les 7 derniers jours", from: -6, to: 0 },
  { label: "Les 7 prochains jours", from: 0, to: 6 },
]);

export const getCurrentWeek = () => {
  const weekRange = parseRange({
    from: new Date(),
    to: moment().add(6, "days").toDate(),
  });
  return weekRange;
};

const getPlannedDateInitialValue = () => {
  return {
    undated: false,
    plannedDate: null,
    isUrgent: false,
    isDailyPlanned: false,
    timeSlots: [],
  };
};

const name = "plannedDate";

const DisclosureButton = ({ select, placeholder, required }) => {
  const field = useField("plannedDate");
  const { plannedDate, undated } = field.input.value;
  const plannedDateFilled = Boolean(plannedDate);
  const undatedFilled = Boolean(undated);

  return (
    <>
      <SelectDatePickerPlaceholder
        state={{ ...select.state, filled: undatedFilled || plannedDateFilled }}
        visible={!undatedFilled && !plannedDateFilled}
      >
        {placeholder}
      </SelectDatePickerPlaceholder>
      {!plannedDate && undated && (
        <div className="mx-1 leading-none">Non daté</div>
      )}
      <SelectDatePickerValue {...select} />
      <SelectDatePickerCaret
        state={{ ...select.state, filled: undated || select.state.filled }}
      />
      {!required && (
        <SelectDatePickerClearButton
          state={{
            clearable: true,
            filled: undated || plannedDate,
            clear: () => {
              field.input.onChange(getPlannedDateInitialValue());
            },
            popover: select.state.popover,
            combobox: select.state.combobox,
          }}
        />
      )}
    </>
  );
};

const SectionWrapper = ({ className, ...props }) => (
  <div
    className={clsx(className, "border-t border-gray-200	first:border-t-0")}
    {...props}
  />
);

const PlannedDateFilters = ({ select, presets, children }) => {
  return (
    <div className="-m-4 flex flex-col">
      <SectionWrapper>
        <DatePickerFilter select={select} presets={presets}>
          {children}
        </DatePickerFilter>
      </SectionWrapper>
      <SectionWrapper>
        <UndatedFilter />
      </SectionWrapper>
    </div>
  );
};

const CheckboxControl = ({ checked, children, ...props }) => {
  const [containerRefHandler, hovered] = useHovered();

  return (
    <div
      ref={containerRefHandler}
      className={clsx("flex w-full items-center gap-2 p-3")}
      {...props}
    >
      <Checkbox
        data-hovered={hovered ? "" : undefined}
        readOnly
        checked={checked}
      />
      {children}
    </div>
  );
};

const label = "Date de publication";

const DatePickerFilter = ({ select, presets, children }) => {
  const timeSlots = useTimeSlots();
  const plannedDateField = useField("plannedDate.plannedDate", {
    parse: parseDate,
    format: formatDate,
  });
  const open = Boolean(plannedDateField.input.value);
  const disclosure = useDisclosureState({
    open,
    setOpen: (open) => {
      plannedDateField.input.onChange(open ? getCurrentWeek() : null);
    },
  });

  return (
    <>
      <Disclosure
        state={disclosure}
        style={{ width: !timeSlots.length ? "452px" : "680px" }}
        className={clsx(open && "bg-blue-bg-light")}
      >
        <CheckboxControl checked={open}>Daté</CheckboxControl>
      </Disclosure>
      <DisclosureContent
        as="div"
        state={disclosure}
        className="overflow-hidden"
      >
        <DatePicker select={select} presets={presets}>
          {children}
        </DatePicker>
      </DisclosureContent>
    </>
  );
};

const UndatedFilter = () => {
  const switchField = useSwitchField("plannedDate.undated");
  const { checked, onChange } = switchField.state.field.input;
  const handleOnClick = () => {
    onChange(!checked);
  };

  return (
    <button
      onClick={handleOnClick}
      className={clsx(checked && "bg-blue-bg-light", "w-full")}
    >
      <CheckboxControl checked={checked}>Non daté</CheckboxControl>
    </button>
  );
};

const isEqual = shallowEqual;

const DateFilter = memo(({ children }) => {
  const initialValue = useMemo(() => getPlannedDateInitialValue(), []);
  const plannedDateField = useField("plannedDate.plannedDate", {
    format: (value) => {
      if (!value) return null;
      return formatRange({
        from: value.gte,
        to: value.lte,
      });
    },
    parse: (value) => {
      if (!value) return null;
      const range = parseRange(value);
      return {
        gte: range.from,
        lte: range.to,
      };
    },
  });
  const select = useSelectDatePickerState({
    ...plannedDateField.input,
    initialValue,
    range: true,
    label,
    isEqual,
    defaultActiveId: null,
  });

  const childrenFilters = Children.toArray(children).filter(
    (child) =>
      isValidElement(child) &&
      (child.type === IsUrgentFilter ||
        child.type === TimeSlotsFilter ||
        child.type === DailyPlannedFilter),
  );

  return (
    <SelectDateFilters
      select={select}
      field={plannedDateField}
      label={label}
      initialValue={initialValue}
      presets={presets}
      disclosureButtonElement={({ select }) => (
        <DisclosureButton select={select} placeholder={label} />
      )}
      disclosureContentElement={({ select, presets }) => (
        <PlannedDateFilters select={select} presets={presets}>
          {childrenFilters}
        </PlannedDateFilters>
      )}
      scale="base"
    />
  );
});

const IsUrgentFilter = () => {
  const field = useField("plannedDate.isUrgent", {
    type: "checkbox",
  });
  const id = useId();

  return (
    <FormCheckbox>
      <Checkbox id={id} {...field.input} />
      <label htmlFor={id} className="flex-1 font-accent text-base">
        ASAP
      </label>
    </FormCheckbox>
  );
};

const TimeSlotField = ({ id, label, value }) => {
  const values = useSubscribeFormValue("plannedDate.timeSlots");
  const timeSlotsValues = useMemo(() => values ?? [], [values]);
  const form = useForm();
  const handleChange = useCallback(
    (event) => {
      if (event.target.checked) {
        form.change(
          "plannedDate.timeSlots",
          [...timeSlotsValues, value].sort(),
        );
      } else {
        form.change(
          "plannedDate.timeSlots",
          timeSlotsValues.filter((slot) => slot !== value),
        );
      }
    },
    [form, value, timeSlotsValues],
  );

  return (
    <FormCheckbox>
      <Checkbox
        id={id}
        onChange={handleChange}
        checked={timeSlotsValues.includes(value)}
        scale="sm"
      />
      <label htmlFor={id} className="flex-1 font-accent text-sm">
        {label}
      </label>
    </FormCheckbox>
  );
};

export const useTimeSlots = () => {
  const { publicationSlots } = useRemoteConfig();

  return useMemo(() => {
    return publicationSlots
      .map((slot, index) => [slot, [...publicationSlots, 24][index + 1]])
      .map(([from, to]) => {
        const start = from.toString().padStart(2, "0");
        const end = to.toString().padStart(2, "0");
        const label = `${start}h ￫ ${end === "24" ? "00" : end}h`;
        const value = `${start}-${end}`;
        return { id: value, value, label };
      });
  }, [publicationSlots]);
};

export const TimeSlotsFilter = () => {
  const id = useId();
  const field = useField("plannedDate.timeSlots");
  const { value, onChange } = field.input;
  const [checked, setChecked] = useState(Boolean(value.length));

  const timeSlots = useTimeSlots();

  const handleChange = useCallback(() => {
    setChecked((checked) => !checked);
    if (checked) {
      onChange([]);
    }
  }, [checked, setChecked, onChange]);

  return (
    <div>
      <FormCheckbox>
        <Checkbox id={id} checked={checked} onChange={handleChange} />
        <label htmlFor={id} className="text-md flex-1 font-accent text-base">
          Créneaux horaires
        </label>
      </FormCheckbox>
      <div className="pl-6">
        {checked
          ? timeSlots.map((slot) => (
              <TimeSlotField key={slot.label} {...slot} />
            ))
          : null}
      </div>
    </div>
  );
};

export const DailyPlannedFilter = () => {
  const field = useField("plannedDate.isDailyPlanned", {
    type: "checkbox",
  });
  const id = useId();

  return (
    <FormCheckbox>
      <Checkbox id={id} {...field.input} />
      <label htmlFor={id} className="flex-1 font-accent text-base">
        Sans horaires
      </label>
    </FormCheckbox>
  );
};

const Filter = () => {
  const timeSlots = useTimeSlots();

  if (!timeSlots.length) return <DateFilter />;

  return (
    <DateFilter>
      <IsUrgentFilter />
      <TimeSlotsFilter />
      <DailyPlannedFilter />
    </DateFilter>
  );
};

export const PlannedDateFilter = {
  name,
  element: <Filter />,
  initialValue: getPlannedDateInitialValue(),
  spread: true,
};
