import { gql } from "@apollo/client";
import { clsx } from "clsx";
import moment from "moment";
import { IoInformationOutline, Paper, PaperCopy } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { ArticlePrintInfos } from "@/containers/article/ArticlePrintInfos";
import { ArticlePrintSummary } from "@/containers/article/ArticlePrintSummary";

function shoulDisplayPriorPrintIndicator(article) {
  if (article.publicationUrl) {
    return false;
  }

  return moment(article.printDate).isBefore(moment(article.schedulerDate));
}

const InnerPrintDueDateIndicator = ({ className }) => {
  return (
    <div
      className={clsx("rounded-full bg-primary-on ring-white", className)}
      data-testid="print-due-date-indicator"
    >
      <IoInformationOutline className="text-white" />
    </div>
  );
};

const PriorPrintDueDateIndicator = ({ article, children }) => {
  return (
    <div className="relative">
      {children}
      {shoulDisplayPriorPrintIndicator(article) && (
        <div className="absolute -bottom-[15%] left-1/2 ">
          <InnerPrintDueDateIndicator className="text-[0.8rem]" />
        </div>
      )}
    </div>
  );
};

PriorPrintDueDateIndicator.fragments = {
  priorPrintDueDateIndicator: gql`
    fragment PriorPrintDueDateIndicator_article on Article {
      dueDate
      printDate
      schedulerDate
      publicationUrl
    }
  `,
};

export function PrintCoreExposure({ article }) {
  // Print exposure is fulfilled
  if (article.printInfos) {
    return (
      <Tooltip
        tooltip={
          <div className="flex flex-col gap-2">
            <div className="font-bold">
              <ArticlePrintSummary {...article} />
            </div>
            <ArticlePrintInfos printInfos={article.printInfos} />
            {shoulDisplayPriorPrintIndicator(article) && (
              <div className="flex items-start gap-2">
                <InnerPrintDueDateIndicator className="mt-1 text-base" />
                <span className="font-semibold text-white">
                  L’article est prévu pour une édition print qui précède la date
                  de publication numérique
                </span>
              </div>
            )}
          </div>
        }
      >
        <div className="flex gap-2">
          <PriorPrintDueDateIndicator article={article}>
            {article.printInfos.decorrelated ? (
              <PaperCopy aria-label="Print" />
            ) : (
              <Paper aria-label="Print" />
            )}
          </PriorPrintDueDateIndicator>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      tooltip={
        <>
          <span className="font-bold">Print</span> (suggéré)
        </>
      }
    >
      <div className="flex text-grey-on">
        <PriorPrintDueDateIndicator article={article}>
          <Paper aria-label="Print" />
        </PriorPrintDueDateIndicator>
      </div>
    </Tooltip>
  );
}

PrintCoreExposure.fragments = {
  article: gql`
    fragment PrintCoreExposure_article on Article {
      id
      printInfos {
        decorrelated
        ...ArticlePrintInfos_printInfos
      }
      ...ArticlePrintSummary_printSummary
      ...PriorPrintDueDateIndicator_article
    }

    ${ArticlePrintInfos.fragments.printInfos}
    ${ArticlePrintSummary.fragments.printSummary}
    ${PriorPrintDueDateIndicator.fragments.priorPrintDueDateIndicator}
  `,
};
