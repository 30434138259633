import clsx from "clsx";
import React, { type ReactElement, useCallback, useEffect } from "react";

import { Button, type ButtonProps } from "../Button";
import { Tooltip, type TooltipProps } from "../Tooltip";
import type { HTMLProps, Options } from "../types";
import { useLiveRef } from "../utils/useLiveRef";

export type SegmentedControlScale = "sm" | "md" | "lg";

export interface SegmentedControlProps<TValue extends string | number>
  extends Omit<HTMLProps<Options<"div">>, "onChange"> {
  className?: string;
  /** The value of the selected item id button has an id return id else return index */
  value?: TValue;
  onChange?: (value: TValue | number) => void;
  scale?: SegmentedControlScale;
  children: React.ReactNode;
}

const parseChildren = (
  rawChildren: React.ReactNode,
): [string[], React.ReactElement<ButtonProps | TooltipProps>[]] => {
  const items = [],
    children = [];
  const childrenArray = React.Children.toArray(rawChildren);
  for (let index = 0; index < childrenArray.length; index++) {
    const child = childrenArray[index];
    if (!React.isValidElement(child)) continue;
    switch (child.type) {
      case Button:
        items.push(child.props.id || index);
        children.push(child as React.ReactElement<ButtonProps>);
        break;
      case Tooltip: {
        const button = React.Children.only(child.props.children);
        if (React.isValidElement(button) && button.type !== Button) break;
        items.push(button.props.id || index);
        children.push(child as React.ReactElement<TooltipProps>);
        break;
      }
      default:
        console.error(
          `SegmentedControl only accepts Button or Tooltip as children. Found ${child.type} instead.`,
        );
        break;
    }
  }
  return [items, children];
};

export function SegmentedControl<TValue extends string | number>({
  onChange,
  value,
  className,
  scale,
  children: rawChildren,
  ...props
}: SegmentedControlProps<TValue>) {
  const [items, children] = parseChildren(rawChildren);

  const findIndex = useCallback(
    (value?: string | number) => {
      if (!value) return 0;
      return items.findIndex((item) => item === value);
    },
    [items],
  );

  const [activeItem, setActiveItem] = React.useState(findIndex(value));

  const handleChange = useCallback(
    (index: number, value?: TValue) => {
      if (index === activeItem) {
        return;
      }
      onChange?.(value || index);
      setActiveItem(index);
    },
    [activeItem, onChange],
  );

  const refs = useLiveRef({ items, scale, findIndex });

  useEffect(() => {
    if (!value) {
      return;
    }
    const { findIndex } = refs.current;
    setActiveItem(findIndex(value));
  }, [refs, value]);

  const cloneButton = useCallback(
    (button: React.ReactElement<ButtonProps>, index: number) => {
      const { id, className, ...props } = button.props;
      const { scale } = refs.current;
      return React.cloneElement(button, {
        scale: scale,
        variant: "secondary",
        appearance: "text",
        className: clsx(
          activeItem === index &&
            "[&]:text-dusk-on bg-white shadow ring-1 ring-offset-1 ring-grey-border-light hover:ring-offset-white",
          scale === "sm" && "text-xs",
          className,
        ),
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          button.props.onClick?.(event);
          handleChange(index, id as TValue);
        },
        ...props, // props could be overridden by the parent
      });
    },
    [activeItem, handleChange, refs],
  );

  return (
    <div
      className={clsx(
        "group/segmented-controls inline-flex items-baseline",
        "justify-start rounded border bg-grey-bg-light",
        "border-grey-border-light",
        className,
      )}
      {...props}
    >
      {children.map((child, index) => {
        switch (child.type) {
          case Button: {
            return cloneButton(child as ReactElement<ButtonProps>, index);
          }
          case Tooltip: {
            return React.cloneElement(child, {
              placement: "top",
              ...child.props,
              children: cloneButton(
                React.Children.only(
                  child.props.children,
                ) as ReactElement<ButtonProps>,
                index,
              ),
            });
          }
          default:
            return null;
        }
      })}
    </div>
  );
}
