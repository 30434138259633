import { Separator as AriakitSeparator } from "ariakit/separator";
import clsx from "clsx";
import { forwardRef } from "react";

const InnerSeparator = ({ className }) => {
  return (
    <hr
      className={clsx("m-0 border-t border-t-grey-border-light", className)}
    />
  );
};

export const Separator = forwardRef((props, ref) => {
  return <AriakitSeparator ref={ref} as={InnerSeparator} {...props} />;
});
