import styled from "@xstyled/styled-components";
import { get } from "lodash-es";
import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { VscGripper } from "react-icons/vsc";

import { Draggable } from "@/components/Draggable";
import { ListBody, ListRow } from "@/components/List";
import { DragContextProvider } from "@/containers/routes/home-edition/DragContext";
import dndManager from "@/services/dndManager";

export function DragInitializer({ children }) {
  const [dragContext, setDragContext] = useState(null);

  return (
    <DragDropContext
      onDragStart={dndManager.onDragStart}
      onDragEnd={(...args) => {
        setDragContext(null);
        dndManager.onDragEnd(...args);
      }}
      onDragUpdate={(update, ...args) => {
        setDragContext(update);
        dndManager.onDragUpdate(update, ...args);
      }}
    >
      <DragContextProvider value={dragContext}>{children}</DragContextProvider>
    </DragDropContext>
  );
}

const StyledListRow = styled(ListRow)`
  outline-width: 0px;
  &[data-dragging="true"] {
    box-shadow: glow;
    border-radius: 0px;
  }
`;

const InnerGripper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 2;
  [data-gripper] {
    opacity: 0.25;
    transition: base;
  }

  div[role="row"]:hover & [data-gripper] {
    opacity: 1;
  }

  div[role="row"][data-dragging="true"] & [data-gripper] {
    opacity: 1;
  }
`;

export function Gripper({ children }) {
  return (
    <>
      <InnerGripper>
        <VscGripper data-gripper />
      </InnerGripper>
      {children}
    </>
  );
}

export function DraggableListRow({
  droppableId,
  index,
  node,
  children,
  ...props
}) {
  const draggableId = `${droppableId}:${node.id}`;

  return (
    <Draggable
      display="table"
      draggableId={draggableId}
      index={index}
      type="listRow"
    >
      {(provided, { isDragging }) => (
        <StyledListRow
          ref={provided.innerRef}
          data-dragging={isDragging}
          {...props}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          role="row"
        >
          {children}
        </StyledListRow>
      )}
    </Draggable>
  );
}

export function DroppableListBody({ children, droppableId, ...props }) {
  return (
    <Droppable droppableId={droppableId} type="listRow">
      {(provided) => (
        <ListBody ref={provided.innerRef} {...props} overflow="hidden">
          {children}
          {provided.placeholder}
        </ListBody>
      )}
    </Droppable>
  );
}

export const DraggableList = ({
  droppableId,
  draggableId = "globalId",
  items,
  onDragEnd,
  children,
  ...props
}) => {
  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="outline-0"
          >
            {items.map((item, index) => {
              const id = `${droppableId}:${get(item, draggableId)}`;
              return (
                <DraggableItem
                  key={id}
                  draggableId={id}
                  item={item}
                  index={index}
                  {...props}
                >
                  {children}
                </DraggableItem>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const DraggableItem = ({ item, index, draggableId, disabled, children }) => {
  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      isDragDisabled={disabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="outline-0"
        >
          {typeof children === "function" ? children({ item }) : children}
        </div>
      )}
    </Draggable>
  );
};
