/* eslint-disable react/prop-types */
import { forwardRef } from "react";
import { Switch } from "swash/controls/Switch";

import { useCheckboxField } from "./CheckboxField";
import { FieldControl } from "./FieldControl";

export const useSwitchField = useCheckboxField;

export const SwitchField = forwardRef((props, ref) => {
  return <FieldControl ref={ref} as={Switch} {...props} />;
});
