import { gql } from "@apollo/client";
import { omit } from "lodash-es";
import { capitalize } from "underscore.string";

import {
  formatCustomFields,
  parseCustomFields,
} from "@/containers/custom-fields/formatters";
import { EventsHistory } from "@/containers/events/EventsHistory";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";
import { omitTypename } from "@/services/utils";

import { createCRUDCreate } from "./Create";
import { createCRUDEdit } from "./Edit";
import { createCRUDFilters } from "./Filters";
import { createCRUDForm } from "./Form";
import { createCRUDList } from "./List";
import { createCRUDRoot } from "./Root";
import { createCRUDSettings } from "./Settings";
import { createCRUDTabs } from "./Tabs";

/**
 * @typedef Column
 * @property {string} id
 * @property {import('react').FC<{}>} Header
 * @property {import('react').FC<{node: object}>} Value
 */

/**
 * @typedef {() => { filters: object, setFilters: (filters: object) => void, initialFilters: object }} UseFiltersState
 * @typedef {{[key: string]: { label?: string, formatLabel?: function, formatValue?: function}}} FieldsMap
 */

/**
 * @typedef {'list' | 'create' | 'edit'} CRUDOperation
 */

/**
 * @typedef {object} CRUDTerm
 * @property {string} article
 * @property {string} specificArticle
 * @property {string} singular
 * @property {string} plural
 */

/**
 * @typedef CRUDConfig
 * @property {string} slug
 * @property {string} [baseUrl]
 * @property {CRUDTerm} term
 * @property {FieldsMap} [fieldsMap]
 * @property {CRUDOperation[]} [crudOperations]
 * @property {object} operations
 * @property {object} operations.ConnectionQuery
 * @property {object} [operations.NodeQuery]
 * @property {object} [operations.nodeQueryVariables]
 * @property {object} [operations.CustomFieldsQuery]
 * @property {object[]} [operations.NodeSubscriptions]
 * @property {object} [operations.CsvSubscription]
 * @property {object} [operations.UpdateNodeMutation]
 * @property {object} [operations.CreateNodeMutation]
 * @property {object} [operations.DeleteNodeMutation]
 * @property {object} [operations.MoveNodeMutation]
 * @property {object} [operations.AuditTrailConnectionQuery]
 * @property {object} [operations.SettingsQuery]
 * @property {object} [operations.SettingsUpdateMutation]
 * @property {object} [operations.operationVariables]
 * @property {(node: object, ctx: { user: object }) => object} [formatValues]
 * @property {(values: object, node: undefined | object) => object} [parseValues]
 * @property {(node: object) => object} [getInitialFilters]
 * @property {object} components
 * @property {import('react').FC<{}>} [components.Fields]
 * @property {import('react').FC<{}>} [components.FiltersFields]
 * @property {Column[]} components.columns
 * @property {import('react').FC<{}>} [components.List]
 * @property {import('react').FC<{}>} [components.Create]
 * @property {import('react').FC<{}>} [components.Edit]
 * @property {import('react').FC<{}>} [components.Form]
 * @property {import('react').FC<{}>} [components.Filters]
 * @property {import('react').FC<{}>} [components.SettingsButton]
 * @property {import('react').FC<{}>} [components.SettingsFields]
 * @property {UseFiltersState} [components.useFiltersState]
 * @property {(options: { nodes: object[] }) => { columns: string[], rows: string[][] }} [formatCSV]
 */

/**
 * @typedef CRUDDescriptor
 * @property {string} slug
 * @property {string} baseUrl
 * @property {object} term
 * @property {string} term.article
 * @property {string} term.specificArticle
 * @property {string} term.singular
 * @property {string} term.plural
 * @property {object} [fieldsMap]
 * @property {CRUDOperation[]} crudOperations
 * @property {object} operations
 * @property {object} [operations.ConnectionQuery]
 * @property {object} [operations.NodeQuery]
 * @property {object} [operations.nodeQueryVariables]
 * @property {object} [operations.CustomFieldsQuery]
 * @property {object[]} [operations.NodeSubscriptions]
 * @property {object} [operations.CsvSubscription]
 * @property {object} [operations.UpdateNodeMutation]
 * @property {object} [operations.CreateNodeMutation]
 * @property {object} [operations.DeleteNodeMutation]
 * @property {object} [operations.MoveNodeMutation]
 * @property {object} [operations.AuditTrailConnectionQuery]
 * @property {object} [operations.SettingsQuery]
 * @property {object} [operations.SettingsUpdateMutation]
 * @property {object} [operations.operationVariables]
 * @property {(node: object, ctx: { user: object }) => object} [formatValues]
 * @property {(values: object, node: undefined | object) => object} [parseValues]
 * @property {(node: object) => object} getInitialFilters
 * @property {object} components
 * @property {import('react').FC<{}>} components.Fields
 * @property {import('react').FC<{}>} [components.FiltersFields]
 * @property {Column[]} components.columns
 * @property {import('react').FC<{}>} components.List
 * @property {import('react').FC<{}>} components.Filters
 * @property {UseFiltersState} components.useFiltersState
 * @property {import('react').FC<{}>} [components.Create]
 * @property {import('react').FC<{}>} [components.Edit]
 * @property {import('react').FC<{}>} [components.Form]
 * @property {import('react').FC<{}>} [components.SettingsButton]
 * @property {(options: { nodes: object[] }) => { columns: string[], rows: string[][] }} formatCSV
 */

/**
 * Format CSV.
 * @param {object} options
 * @param {object[]} options.nodes
 */
function defaultFormatCSV({ nodes }) {
  if (!nodes.length) return { columns: [], rows: [] };
  const columns = Object.keys(omitTypename(nodes[0]));
  const rows = nodes.map((node) => Object.values(omitTypename(node)));
  return { columns, rows };
}

/**
 * Get CRUD descriptor from crud configuration.
 * @param {CRUDConfig} config
 */
function getCrudContext(config) {
  const descriptor = { ...config };
  const ctx = {
    get descriptor() {
      return descriptor;
    },
  };

  if (!descriptor.title) {
    descriptor.title = capitalize(descriptor.term.plural);
  }

  if (!descriptor.wrapPageElement) {
    descriptor.wrapPageElement = ({ element }) => element;
  }

  if (!descriptor.crudOperations) {
    const crudOperations = ["list", "edit"];
    if (descriptor.operations.CreateNodeMutation) {
      crudOperations.push("create");
    }
    if (descriptor.operations.DeleteNodeMutation) {
      crudOperations.push("delete");
    }

    descriptor.crudOperations = crudOperations;
  }

  if (!descriptor.operations.operationVariables) {
    descriptor.operations.operationVariables = {};
  }

  descriptor.parseValues = (values, node) => {
    const result = config.parseValues
      ? config.parseValues({ ...values }, node)
      : { ...values };
    if (descriptor.operations.CustomFieldsQuery && result.customFields) {
      result.customFields = parseCustomFields(result.customFields);
    }
    return result;
  };
  descriptor.formatValues = (node, ctx) => {
    const result = config.formatValues
      ? omit(config.formatValues(node, ctx), ["__typename", "id"])
      : {};
    if (descriptor.operations.CustomFieldsQuery) {
      result.customFields = formatCustomFields(result.customFields);
    }
    return result;
  };
  if (!descriptor.baseUrl) {
    descriptor.baseUrl = "/admin";
  }
  if (!descriptor.formatCSV) {
    descriptor.formatCSV = defaultFormatCSV;
  }
  if (!descriptor.getInitialFilters) {
    descriptor.getInitialFilters = () => ({});
  }
  if (!descriptor.components.SettingsButton) {
    const { SettingsButton } = createCRUDSettings(ctx);
    descriptor.components.SettingsButton = SettingsButton;
  }
  if (
    !descriptor.components.Filters &&
    !descriptor.components.useFiltersState
  ) {
    const { Filters, useFiltersState } = createCRUDFilters(ctx);
    descriptor.components.Filters = Filters;
    descriptor.components.useFiltersState = useFiltersState;
  }
  if (!descriptor.components.Form) {
    const { Form } = createCRUDForm(ctx);
    descriptor.components.Form = Form;
  }
  if (!descriptor.components.Tabs) {
    const { Tabs } = createCRUDTabs(ctx);
    descriptor.components.Tabs = Tabs;
  }
  if (!descriptor.components.Create) {
    const { Create } = createCRUDCreate(ctx);
    descriptor.components.Create = Create;
  }
  if (!descriptor.components.List) {
    const { List } = createCRUDList(ctx);
    descriptor.components.List = List;
  }
  if (!descriptor.components.Edit) {
    const { Edit } = createCRUDEdit(ctx);
    descriptor.components.Edit = Edit;
  }
  return ctx;
}

/**
 * Create CRUD admin.
 * @param {CRUDConfig} config
 */
export function createCRUD(config) {
  const ctx = getCrudContext(config);
  return createCRUDRoot(ctx);
}

export * from "./fields";
export * from "./filtersFields";
export * from "./FiltersToolbar";
export {
  CustomFieldFragment,
  CustomFieldValueFragment,
} from "../../custom-fields/CustomFieldsFragments";

export const CRUDEventFragment = gql`
  fragment CRUDEventFragment on Event {
    id
    ...EventsHistory_events
  }

  ${EventsHistory.fragments.events}
`;

export const CRUDNodeFragment = gql`
  fragment CRUDNodeFragment on Node {
    id
    globalId
  }
`;

export const CRUDConnectionFragment = ConnectionFragment;
