import { gql } from "@apollo/client";
import { memo } from "react";
import { Chip } from "swash/Chip";
import { Tooltip } from "swash/Tooltip";

import { useArticleAuditTrailTooltip } from "@/components/ArticleAuditTrail";

export type ArticleEditorialFormatLabelProps = {
  article: {
    editorialFormat: {
      id: number;
      label: string;
    };
  };
};

type SpreadArticleEditorialFormatLabelProps = {
  editorialFormat: ArticleEditorialFormatLabelProps["article"]["editorialFormat"];
};

const SpreadArticleEditorialFormatLabel =
  memo<SpreadArticleEditorialFormatLabelProps>(({ editorialFormat }) => {
    const tooltip = useArticleAuditTrailTooltip("editorialFormat");
    return (
      <Tooltip tooltip={tooltip}>
        <Chip className="uppercase" scale="sm">
          {editorialFormat.label}
        </Chip>
      </Tooltip>
    );
  });

export const ArticleEditorialFormatLabel = (
  props: ArticleEditorialFormatLabelProps,
) => {
  if (!props.article.editorialFormat) return null;
  return (
    <SpreadArticleEditorialFormatLabel
      editorialFormat={props.article.editorialFormat}
    />
  );
};

ArticleEditorialFormatLabel.fragments = {
  article: gql`
    fragment ArticleEditorialFormatLabel_article on Article {
      editorialFormat {
        id
        label
      }
    }
  `,
};
