/* eslint-disable react/prop-types */
import { forwardRef } from "react";

function getAriaLabelProps({ labelId, label, "aria-label": ariaLabel }) {
  if (label) {
    return { "aria-label": label };
  }

  if (ariaLabel) {
    return { "aria-label": ariaLabel };
  }

  if (labelId) {
    return { "aria-labelledby": labelId };
  }

  return {};
}

export const FieldControl = forwardRef(
  ({ state, children, as: As, ...props }, ref) => {
    const invalid = state.field.meta.touched
      ? state.field.meta.invalid
      : undefined;

    const controlProps = {
      ref,
      "aria-invalid": invalid,
      "data-field-control": true,
      id: state.id,
      minLength: state.minLength,
      maxLength: state.maxLength,
      required: state.required,
      disabled: state.disabled,
      ...getAriaLabelProps(state),
      ...state.field.input,
      ...props,
    };

    return typeof children === "function" ? (
      children(controlProps)
    ) : (
      <As {...controlProps}>{children}</As>
    );
  },
);
