/* eslint-disable graphql/template-strings */
import { gql, useSubscription } from "@apollo/client";
import { getIn } from "final-form";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useForm, useFormState } from "react-final-form";
import {
  DialogPanel,
  DialogPanelDisclosure,
  useDialogPanelState,
} from "swash/DialogPanel";
import { PageLoader } from "swash/Loader";
import {
  PanelBody,
  PanelFooter,
  PanelHeader,
  PanelSection,
  PanelSectionArrow,
  PanelSectionHeader,
  PanelSectionTitle,
} from "swash/Panel";
import { useLiveRef } from "swash/utils/useLiveRef";

import { FormSavingIndicator } from "@/components/forms/FormSavingIndicator";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import {
  mergeConnections,
  useSafeMutation,
  useSafeQuery,
} from "@/containers/Apollo";
import { DisabledAction } from "@/containers/DisabledAction";
import { useHasPermission } from "@/containers/User";
import { ArticlePrintSummary } from "@/containers/article/ArticlePrintSummary";
import { PrintMetaForm } from "@/containers/article/PrintMetaForm";
import { RichEventsHistory } from "@/containers/events/RichEventsHistory";
import {
  NAVBAR_WIDTH,
  PANEL_WIDTH,
} from "@/containers/routes/article/ArticleLayout";

const EventFragment = gql`
  fragment ArticleExportPrintSection_event on Event {
    status
    ...RichEventsHistory_event
  }
  ${RichEventsHistory.fragments.event}
`;

const ArticleFragment = gql`
  fragment ArticleExportPrintSection_article on Article {
    id
    periodical {
      id
      label
      freezeAtExport
    }
    periodicalSection {
      id
      label
    }
    periodicalSectionLayout {
      id
      label
    }
    periodicalRelease {
      id
      label
    }
    periodicalEditionDate
    ackArticleEventsCount: articleEvents(
      where: { type: { eq: "export:print:acknowledge" } }
    ) {
      totalCount
    }
    prePublishEvents: events(
      limit: $limit
      offset: $offset
      where: { name: { eq: "articleBranch" }, action: { eq: "prePublish" } }
    ) {
      nodes {
        id
        ...ArticleExportPrintSection_event
      }
      ...RichEventsHistory_events
    }
  }
  ${EventFragment}
  ${RichEventsHistory.fragments.events}
`;

const ExportArticleMutation = gql`
  mutation ArticleExportPrintSection_exportArticle(
    $articleId: Int!
    $limit: Int = 1
    $offset: Int = 0
  ) {
    exportArticle(id: $articleId) {
      id
      ...ArticleExportPrintSection_article
    }
  }
  ${ArticleFragment}
`;

const ArticleQuery = gql`
  query ArticleExportPrintSection_article(
    $articleId: Int!
    $offset: Int = 0
    $limit: Int = 3
  ) {
    article(id: $articleId) {
      id
      ...ArticleExportPrintSection_article
    }
  }
  ${ArticleFragment}
`;

const ArticleSubscription = gql`
  subscription ArticleExportPrintSection_articleUpdated(
    $articleId: Int!
    $offset: Int
    $limit: Int
  ) {
    articleUpdated(where: { id: { eq: $articleId } }) {
      id
      ...ArticleExportPrintSection_article
    }
  }
  ${ArticleFragment}
`;

const EventSubscription = gql`
  subscription ArticlePublishState_eventUpdated($articleId: Int!) {
    eventUpdated(
      where: {
        name: { eq: "articleBranch" }
        action: { eq: "prePublish" }
        articleId: { eq: $articleId }
      }
    ) {
      id
      ...ArticleExportPrintSection_event
    }
  }
  ${EventFragment}
`;

const useExportArticle = ({ articleId }) => {
  const { data, subscribeToMore, refetch, loading, fetchMore } = useSafeQuery(
    ArticleQuery,
    {
      variables: { articleId },
    },
  );
  useSubscription(ArticleSubscription, { variables: { articleId } });
  const refs = useLiveRef({
    getFetchMoreVariables: () => ({
      articleId,
      offset: data.article.prePublishEvents.nodes.length,
    }),
    getRefetchVariables: () => ({
      articleId,
      offset: 0,
      limit: data.article.prePublishEvents.nodes.length,
    }),
  });
  const loadMore = useCallback(() => {
    fetchMore({
      variables: refs.current.getFetchMoreVariables(),
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          ...previousResult,
          article: {
            ...previousResult.article,
            prePublishEvents: mergeConnections(
              previousResult.article.prePublishEvents,
              fetchMoreResult.article.prePublishEvents,
            ),
          },
        };
      },
    });
  }, [refs, fetchMore]);
  const [exportArticle, { loading: exporting }] = useSafeMutation(
    ExportArticleMutation,
    {
      variables: { articleId },
      update: (cache, result) => {
        const queryIdentifier = {
          query: ArticleQuery,
          variables: { articleId },
        };
        const previousResult = cache.readQuery(queryIdentifier);
        cache.writeQuery({
          ...queryIdentifier,
          data: {
            ...previousResult,
            article: {
              ...previousResult.article,
              prePublishEvents: {
                ...previousResult.article.prePublishEvents,
                nodes: [
                  ...result.data.exportArticle.prePublishEvents.nodes,
                  ...previousResult.article.prePublishEvents.nodes,
                ],
              },
            },
          },
        });
      },
    },
  );
  const status = (() => {
    if (exporting) return "in_progress";
    if (!data) return "loading";
    const [event] = data.article.prePublishEvents.nodes;
    if (!event) return "pending";
    return event.status;
  })();
  const inProgress = status === "queued" || status === "in_progress";
  // Use subscription for real-time
  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: EventSubscription,
      variables: { articleId },
      updateQuery: (previousResult, { subscriptionData: { data } }) => {
        if (!data) return previousResult;
        const event = data.eventUpdated;
        const previousNodes = previousResult.article.prePublishEvents?.nodes;
        if (!previousNodes) return; // polling will catch up
        const index = previousNodes.findIndex((node) => node.id === event.id);
        const nodes = [...previousNodes];
        if (index !== -1) {
          nodes.splice(index, 1, event);
        } else {
          nodes.unshift(event);
        }

        return {
          ...previousResult,
          article: {
            ...previousResult.article,
            prePublishEvents: {
              ...previousResult.article.prePublishEvents,
              nodes,
            },
          },
        };
      },
    });
    return () => unsubscribe();
  }, [subscribeToMore, articleId]);
  // Use polling for backup if subscription fails
  useEffect(() => {
    if (!inProgress) return undefined;
    const interval = setInterval(
      () => refetch(refs.current.getRefetchVariables()),
      1000,
    );
    return () => clearInterval(interval);
  }, [inProgress, refetch, refs]);
  return {
    exportArticle,
    article: data?.article ?? null,
    status,
    exported: data ? data?.article.ackArticleEventsCount.totalCount > 0 : null,
    prePublishEvents: data?.article.prePublishEvents ?? null,
    loading,
    loadMore,
  };
};

const ExportPrintDialogFields = ({
  visible,
  exported,
  freezeAtExport,
  children,
}) => {
  const { valid } = useFormState({
    subscription: { valid: true },
  });
  const validRef = useLiveRef(valid);
  const disabled = useMemo(
    () => visible && exported && freezeAtExport && validRef.current,
    [visible, exported, freezeAtExport, validRef],
  );
  return children({ disabled });
};

const PrintMetaFormSubmit = ({ disabled, exported }) => {
  return (
    <FormSubmit disabled={disabled} name="export-button">
      {exported ? "Exporter à nouveau" : "Exporter vers le print"}
    </FormSubmit>
  );
};

const useExportInvalidReason = () => {
  const { values } = useFormState({
    // We listen for touched fields to trigger re-render
    subscription: { values: true, touched: true },
  });
  const form = useForm();
  const registeredFields = form.getRegisteredFields();
  const valid = registeredFields.every((fieldName) => {
    const fieldValue = getIn(values, fieldName);
    return fieldValue != null;
  });
  if (valid) return null;
  return "Tous les champs sont requis afin de pouvoir exporter";
};

const useExportDisabledReason = (disabledPeriodicalIds) => {
  const periodicalId = useSubscribeFormValue("periodicalId");
  const exportDisabled = disabledPeriodicalIds.includes(periodicalId);
  if (!exportDisabled) return null;
  return "Les exports ont été désactivés pour cette publication par votre administrateur";
};

const useRestrictedDisabledReason = () => {
  const hasPermission = useHasPermission(["article:publish:methode"]);
  if (hasPermission) return null;
  return "Vous ne disposez pas des droits nécessaires";
};

const ExportPrintDisabledGuard = ({ children, disabledPeriodicalIds }) => {
  const restrictedDisabledReason = useRestrictedDisabledReason();
  const exportDisabledReason = useExportDisabledReason(disabledPeriodicalIds);
  const exportInvalidReason = useExportInvalidReason();
  return (
    <DisabledAction
      reason={
        restrictedDisabledReason || exportDisabledReason || exportInvalidReason
      }
    >
      {children}
    </DisabledAction>
  );
};

const ExportPrintDialog = ({
  articleId,
  exported,
  onExport,
  freezeAtExport,
  state,
  inFloatingPanel,
  defaultOpenPrintPanel,
}) => {
  const title = "Print";
  const visible = state.open || state.animating;

  const defaultOpenPrintPanelRef = useRef(false);

  useEffect(() => {
    if (defaultOpenPrintPanel && !defaultOpenPrintPanelRef.current)
      state.show();
    defaultOpenPrintPanelRef.current = true;
  }, [defaultOpenPrintPanel, state]);
  if (!visible) return null;

  return (
    <DialogPanel
      state={state}
      aria-label={title}
      animation="from-right-complete"
      style={{
        width: PANEL_WIDTH,
        right: inFloatingPanel ? 0 : NAVBAR_WIDTH,
      }}
    >
      <PrintMetaForm
        onExport={async () => {
          await onExport();
          state.hide();
        }}
        articleId={articleId}
      >
        {({ renderFields, periodicalExportDisabledIds }) => {
          return (
            <>
              <PanelHeader
                back="Exposition"
                title={title}
                actions={<FormSavingIndicator />}
                onClose={state.hide}
              />
              <PanelBody>
                <ExportPrintDialogFields
                  visible={state.open}
                  exported={exported}
                  freezeAtExport={freezeAtExport}
                >
                  {renderFields}
                </ExportPrintDialogFields>
              </PanelBody>
              <PanelFooter>
                <ExportPrintDisabledGuard
                  disabledPeriodicalIds={periodicalExportDisabledIds}
                >
                  {({ disabled }) => (
                    <PrintMetaFormSubmit
                      disabled={disabled}
                      exported={exported}
                    />
                  )}
                </ExportPrintDisabledGuard>
              </PanelFooter>
            </>
          );
        }}
      </PrintMetaForm>
    </DialogPanel>
  );
};

export const ArticleExportPrintSection = memo(
  ({ articleId, inFloatingPanel = false, defaultOpenPrintPanel }) => {
    const dialog = useDialogPanelState();
    const {
      exportArticle,
      exported,
      status,
      prePublishEvents,
      loading,
      loadMore,
      article,
    } = useExportArticle({ articleId });
    const waiting =
      status === "loading" || status === "in_progress" || status === "queued";
    const hasPermission = useHasPermission(
      ["article:metas:methode", "article:publish:methode"],
      { method: "some" },
    );
    const disabled = waiting;

    const renderPanelSection = (props) => (
      <PanelSection {...props}>
        <PanelSectionHeader>
          <PanelSectionTitle>Publication Print</PanelSectionTitle>
          <PanelSectionArrow />
        </PanelSectionHeader>
        {status === "loading" ? (
          <PageLoader />
        ) : (
          <>
            <div className="text-xs text-grey-on">
              <ArticlePrintSummary {...article} />
            </div>
            <RichEventsHistory
              events={prePublishEvents}
              loading={loading}
              onLoadMore={loadMore}
            />
          </>
        )}
      </PanelSection>
    );

    if (!hasPermission) return renderPanelSection();

    return (
      <>
        <DialogPanelDisclosure state={dialog} disabled={disabled}>
          {({ type, ...disclosureProps }) =>
            renderPanelSection({
              ...disclosureProps,
              "aria-label": "Éditer la publication print",
            })
          }
        </DialogPanelDisclosure>
        <ExportPrintDialog
          state={dialog}
          freezeAtExport={article?.periodical?.freezeAtExport ?? false}
          inFloatingPanel={inFloatingPanel}
          defaultOpenPrintPanel={defaultOpenPrintPanel}
          articleId={articleId}
          exported={exported}
          onExport={async () => {
            await exportArticle();
          }}
        />
      </>
    );
  },
);
