import React, { forwardRef, useCallback } from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";

// Fix MaskInput types
// --
type MaskedInputPatched = (
  props: MaskedInputProps & {
    ref: (component?: { inputElement?: HTMLInputElement }) => void;
  },
) => JSX.Element;

const MaskedInputPatched = MaskedInput as unknown as MaskedInputPatched;

// --

export type MaskInputProps = MaskedInputProps;

export const MaskInput = forwardRef<HTMLInputElement, MaskInputProps>(
  (props, ref) => {
    const getRef = useCallback(
      (component?: { inputElement?: HTMLInputElement }) => {
        const element = component?.inputElement ?? null;
        if (typeof ref === "function") {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      },
      [ref],
    );
    return <MaskedInputPatched ref={getRef} {...props} />;
  },
);

if (process.env["NODE_ENV"] !== "production") {
  MaskInput.displayName = "MaskInput";
}
