import { gql } from "@apollo/client";

export const getArticleTitle = (title: string | null) => {
  return title ?? "Sans titre";
};

export type SpreadArticleTitleProps = {
  title: string | null;
};

export const SpreadArticleTitle = (props: SpreadArticleTitleProps) => {
  return getArticleTitle(props.title) as any;
};

export type ArticleTitleProps = {
  article: {
    title: string | null;
  };
};

export const ArticleTitle = (props: ArticleTitleProps) => {
  return <SpreadArticleTitle title={props.article.title} />;
};

ArticleTitle.fragments = {
  article: gql`
    fragment ArticleTitle_article on Article {
      id
      title
    }
  `,
};
