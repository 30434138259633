import { gql, useMutation } from "@apollo/client";
import { useMemo } from "react";
import { useToaster } from "swash/Toast";

import { SnippetForm } from "@/containers/snippet/SnippetForm";
import { SnippetFragment } from "@/containers/snippet/Snippets";

export const SnippetQuery = gql`
  query SnippetQuery($id: Int!) {
    snippet(id: $id) {
      id
      ...SnippetEditFragment
    }
  }
  ${SnippetFragment}
`;

const UpdateSnippetMutation = gql`
  mutation UpdateSnippetMutation($input: UpdateSnippetInput!) {
    updateSnippet(input: $input) {
      id
      ...SnippetEditFragment
    }
  }
  ${SnippetFragment}
`;

export const SnippetEditForm = ({ snippet, ...props }) => {
  const toaster = useToaster();
  const [updateSnippet] = useMutation(UpdateSnippetMutation);

  const initialValues = useMemo(() => {
    return {
      title: snippet.title,
      code: snippet.code,
      darkMode: snippet.darkModeSupported ? "supported" : "notSupported",
      obsolete: snippet.obsolete,
      keywordIds: snippet.keywords.map((keyword) => keyword.id),
    };
  }, [snippet]);

  const handleSubmit = async (values) => {
    try {
      await updateSnippet({
        variables: {
          input: {
            id: snippet.id,
            title: values.title,
            code: values.code,
            keywordIds: values.keywordIds,
            darkModeSupported: values.darkMode === "supported",
            obsolete: values.obsolete,
          },
        },
      });
    } catch (error) {
      toaster.danger("L'édition du snippet a échoué");
    }
  };

  return (
    <SnippetForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      snippet={snippet}
      {...props}
    />
  );
};
