import { forwardRef } from "react";
import { IoClose, IoTrash } from "swash/Icon";

import {
  EditorBlockCapsule,
  EditorBlockCapsuleBody,
  EditorBlockCapsuleToolbar,
  EditorBlockCapsuleToolbarButton,
  EditorBlockCapsuleToolbarProvider,
} from "@/components/teleporters/EditorBlockCapsule";

export const MediaLayout = forwardRef(
  (
    {
      variant,
      draggable,
      onDelete,
      deletable,
      children,
      expanded,
      disabled,
      ...props
    },
    ref,
  ) => (
    <EditorBlockCapsuleToolbarProvider>
      <EditorBlockCapsule
        variant={variant}
        draggable={draggable}
        expanded={expanded}
        {...props}
      >
        {!disabled && (
          <EditorBlockCapsuleToolbar>
            {deletable && onDelete && (
              <EditorBlockCapsuleToolbarButton
                title="Supprimer"
                icon={expanded ? IoTrash : IoClose}
                onMouseDown={onDelete}
              />
            )}
          </EditorBlockCapsuleToolbar>
        )}
        <EditorBlockCapsuleBody fit ref={ref}>
          {children}
        </EditorBlockCapsuleBody>
      </EditorBlockCapsule>
    </EditorBlockCapsuleToolbarProvider>
  ),
);
