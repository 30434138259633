import moment from "moment-timezone";
import { forwardRef, useCallback, useMemo } from "react";
import { IoCalendarOutline } from "swash/Icon";
import {
  Popover,
  Select,
  SelectCaret,
  SelectClearButton,
  SelectDisclosure,
  SelectPlaceholder,
  SelectValue,
  getLabelProps,
  useSelectState,
} from "swash/Select";
import { RangeDatePicker, SingleDatePicker } from "swash/controls/DatePicker";

export { Select };

export const mTz = (value) => moment.tz(value, "Europe/Paris");

export const formatDate = (date) => {
  if (!date) {
    return null;
  }
  return mTz(date).format("YYYY-MM-DD");
};

export const formatRange = (range) => {
  if (!range) return null;
  return {
    from: formatDate(range.from),
    to: formatDate(range.to),
  };
};

export const parseDate = (date) => {
  return date ? mTz(date).toISOString() : null;
};

export const parseRange = (range) => {
  if (!range) return null;
  return {
    from: mTz(range.from).startOf("day").toISOString(),
    to: mTz(range.to ?? range.from)
      .endOf("day")
      .toISOString(),
  };
};

const formatDisplayDate = (value) => {
  return mTz(value).format("D MMMM");
};

export const RangeDatePickerLabel = ({ value }) => {
  if (!value.to || value.from === value.to) {
    return formatDisplayDate(value.from);
  }

  return `${mTz(value.from).format("D MMM")} → ${mTz(value.to).format(
    "D MMM",
  )}`;
};

export const SingleDatePickerLabel = ({ value }) => {
  return formatDisplayDate(value);
};

export const useSelectDatePickerState = ({
  range,
  modifiers,
  defaultMonth,
  ...options
}) => {
  const { state: selectState } = useSelectState(options);
  const state = useMemo(
    () => ({ ...selectState, range, modifiers, defaultMonth }),
    [range, modifiers, defaultMonth, selectState],
  );
  return { state };
};

export const SelectDatePickerDisclosure = SelectDisclosure;

export const SelectDatePickerPlaceholder = SelectPlaceholder;

export const SelectDatePickerValue = forwardRef(
  ({ children, "data-test-hidden": dataTestHidden, ...props }, ref) => {
    return (
      <SelectValue data-test-hidden={dataTestHidden} ref={ref} {...props}>
        {(() => {
          const selectValueProps = { value: props.state.value };
          if (typeof children === "function") {
            return children(selectValueProps);
          }
          const Label = props.state.range
            ? RangeDatePickerLabel
            : SingleDatePickerLabel;
          return <Label {...selectValueProps} />;
        })()}
      </SelectValue>
    );
  },
);

export const SelectDatePickerCaret = forwardRef((props, ref) => {
  return (
    <SelectCaret
      ref={ref}
      animated={false}
      display="inline-block"
      className="text-dusk-on-light/70"
      as={IoCalendarOutline}
      {...props}
    />
  );
});

export const SelectDatePickerClearButton = SelectClearButton;

export const SelectDatePickerPopover = forwardRef(
  ({ state, children, ...props }, ref) => {
    return (
      <Popover
        ref={ref}
        state={state.popover}
        portal
        className="overflow-hidden p-4"
        {...getLabelProps({ state, ...props })}
        {...props}
      >
        {state.popover.open ? children : null}
      </Popover>
    );
  },
);

export const SelectDatePicker = forwardRef(
  (
    {
      state: { value, change, range, modifiers, defaultMonth },
      children,
      numberOfMonths = 2,
      disabled,
      ...props
    },
    ref,
  ) => {
    const Picker = range ? RangeDatePicker : SingleDatePicker;

    const handleChange = useCallback(
      (newValue) => {
        change(
          !newValue && value && range
            ? {
                from: value.from ?? value.to,
                to: value.from ?? value.to,
              }
            : newValue,
        );
      },
      [change, range, value],
    );

    return (
      <div ref={ref} {...props}>
        {typeof children === "function" ? (
          children({ value, onChange: change })
        ) : (
          <Picker
            value={value}
            onChange={handleChange}
            numberOfMonths={numberOfMonths}
            disabled={disabled}
            modifiers={modifiers}
            defaultMonth={defaultMonth}
          />
        )}
      </div>
    );
  },
);
