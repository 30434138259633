import clsx from "clsx";
import { format as formatDate, parse as parseDate } from "date-fns";
import fr from "date-fns/locale/fr";
import React, { ComponentProps, useState } from "react";
import {
  DayPicker,
  DateRange as DayPickerDateRange,
  DayPickerRangeProps,
  DayPickerSingleProps,
} from "react-day-picker";
import "react-day-picker/dist/style.css";

import "./assets/datepicker.css";

export type DatePickerProps = ComponentProps<typeof DayPicker>;

export const DatePicker = ({
  numberOfMonths = 1,
  className,
  ...props
}: DatePickerProps) => {
  return (
    <DayPicker
      fixedWeeks
      numberOfMonths={numberOfMonths}
      showOutsideDays={numberOfMonths === 1}
      locale={fr}
      modifiersClassNames={{ hover: "rdp-modifier-hover" }}
      className={clsx(props.disabled && "x-rdp-disabled", className)}
      {...props}
    />
  );
};

if (process.env["NODE_ENV"] !== "production") {
  DatePicker.displayName = "DatePicker";
}

const formatSingle = (value: string | null) => {
  if (!value) return null;
  return parseDate(value, "yyyy-MM-dd", new Date());
};

const parseSingle = (value: Date | undefined) => {
  if (!value) return null;
  return formatDate(value, "yyyy-MM-dd");
};

export type SingleDatePickerProps = Omit<
  DayPickerSingleProps,
  "mode" | "selected"
> & {
  value: string | null;
  onChange: (value: string | null) => void;
};

export const SingleDatePicker = ({
  defaultMonth,
  value,
  onChange,
  ...props
}: SingleDatePickerProps) => {
  const selected = formatSingle(value);
  const onSelect: NonNullable<DayPickerSingleProps["onSelect"]> = (value) =>
    onChange(parseSingle(value));
  return (
    <DatePicker
      mode="single"
      defaultMonth={defaultMonth ?? selected ?? undefined}
      selected={selected ?? undefined}
      onSelect={onSelect}
      {...props}
    />
  );
};

if (process.env["NODE_ENV"] !== "production") {
  SingleDatePicker.displayName = "SingleDatePicker";
}

export type DateRange = null | { from: string | null; to: string | null };

const formatRange = (value: DateRange) => {
  if (!value) return null;
  return { from: formatSingle(value.from), to: formatSingle(value.to) };
};

const parseRange = (value: DayPickerDateRange | undefined): DateRange => {
  if (!value) return null;

  return {
    from: parseSingle(value.from),
    to: parseSingle(value.to ?? value.from),
  };
};

export type RangeDatePickerProps = Omit<
  DayPickerRangeProps,
  "mode" | "selected"
> & {
  value: DateRange;
  onChange: (value: DateRange) => void;
};

export const RangeDatePicker = (props: RangeDatePickerProps) => {
  const { value, onChange, defaultMonth, ...otherProps } = props;
  const selected = formatRange(value);
  const [defaultSelectedMonth] = useState(
    defaultMonth ?? selected?.from ?? null,
  );
  const onSelect: NonNullable<DayPickerRangeProps["onSelect"]> = (value) =>
    onChange(parseRange(value));

  return (
    <DatePicker
      mode="range"
      defaultMonth={defaultSelectedMonth ?? undefined}
      selected={
        selected
          ? { from: selected.from ?? undefined, to: selected.to ?? undefined }
          : undefined
      }
      onSelect={onSelect}
      {...otherProps}
    />
  );
};

if (process.env["NODE_ENV"] !== "production") {
  RangeDatePicker.displayName = "RangeDatePicker";
}

if (process.env["NODE_ENV"] !== "production") {
  DatePicker.displayName = "DatePicker";
}
