import { gql } from "@apollo/client";
import { useId } from "ariakit-react-utils/hooks";
import moment from "moment-timezone";
import { memo, useEffect, useMemo, useState } from "react";
import { useField, useForm, useFormState } from "react-final-form";
import { Checkbox } from "swash/controls/Checkbox";
import { RangeDatePicker, SingleDatePicker } from "swash/controls/DatePicker";
import {
  TimePickerInput,
  formatFullTime,
  parseFullTime,
} from "swash/controls/TimePickerInput";
import { FormCheckbox } from "swash/form/FormCheckbox";
import { FormLabel } from "swash/form/FormLabel";
import { useLiveRef } from "swash/utils/useLiveRef";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

import { DatedSwitch } from "@/components/controls/DatedSwitch";
import { mTz } from "@/components/controls/SelectDatePicker";
import {
  RadioField,
  RadioGroupField,
  useRadioField,
  useRadioGroupField,
} from "@/components/fields/RadioField";
import { Form } from "@/components/forms/Form";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import { useSlotItems } from "@/containers/article/util/publicationSlots";

const TimePickerField = ({ disabled, plannedDate }) => {
  const {
    input: { onChange, value },
  } = useField("publicationDate.plannedTime", {
    format: formatFullTime,
    parse: parseFullTime,
  });

  return (
    <TimePickerInput
      value={value}
      onChange={onChange}
      disabled={disabled}
      disablePast={moment(plannedDate).isSame(moment(), "day")}
      required={true}
    />
  );
};

const EmbargoField = ({ disabled }) => {
  const {
    input: { checked, onChange },
  } = useField("publicationDate.isEmbargo", { type: "checkbox" });
  const id = useId();
  return (
    <FormCheckbox>
      <Checkbox
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <FormLabel htmlFor={id}>Embargo</FormLabel>
    </FormCheckbox>
  );
};

const TimeRadioFields = ({
  disabled,
  plannedTime,
  plannedDate,
  useRangeOption,
}) => {
  const form = useForm();
  const { values } = useFormState({
    subscription: { values: true },
  });

  const field = useRadioGroupField("publicationDate.timingMode");

  const asapField = useRadioField(field, { value: "asap", disabled });
  const timeField = useRadioField(field, { value: "time", disabled });
  const rangeField = useRadioField(field, { value: "range", disabled });
  const dailyField = useRadioField(field, { value: "daily", disabled });

  useEffect(() => {
    if (values.publicationDate.timingMode !== "time") {
      form.change("publicationDate.plannedTime", null);
    }
    if (values.publicationDate.timingMode !== "range") {
      form.change("publicationDate.plannedRange", null);
    }
  }, [form, values]);

  return (
    <RadioGroupField className="flex flex-col">
      <RadioField {...asapField}>ASAP</RadioField>
      <RadioField {...timeField}>Heure</RadioField>
      {timeField.state.field.input.checked && (
        <div className="mb-2 pl-8">
          <div className="flex flex-col gap-1 pt-2">
            <TimePickerField disabled={disabled} plannedDate={plannedDate} />
            <EmbargoField disabled={disabled || !plannedTime} />
          </div>
        </div>
      )}
      {useRangeOption && (
        <>
          <RadioField {...rangeField}>Créneau horaire</RadioField>
          {rangeField.state.field.input.checked && (
            <div className=" mt-1 pl-8">
              <RangePickerField plannedDate={plannedDate} />
            </div>
          )}
        </>
      )}
      <RadioField {...dailyField}>Sans horaire</RadioField>
    </RadioGroupField>
  );
};

const DatedSwitchField = () => {
  const {
    input: { checked, onChange },
  } = useField("publicationDate.dated", { type: "checkbox" });

  return <DatedSwitch checked={checked} onChange={onChange} />;
};

const formatRange = (range) => {
  if (!range) return null;
  return {
    from: moment(range.from).format("YYYY-MM-DD"),
    to: moment(range.to).format("YYYY-MM-DD"),
  };
};

const getWeekRange = (date) => {
  if (!date) return null;
  return {
    from: moment(date).startOf("week").toDate(),
    to: moment(date).endOf("week").toDate(),
  };
};

const getMonthRange = (date) => {
  if (!date) return null;
  return {
    from: moment(date).startOf("month").toDate(),
    to: moment(date).endOf("month").toDate(),
  };
};

export const SingleOrRangeDatePicker = ({ planningMode, value, onChange }) => {
  const rangeValue = useMemo(() => {
    switch (planningMode) {
      case "week":
        return formatRange(getWeekRange(value));
      case "month":
        return formatRange(getMonthRange(value));
      default:
        return null;
    }
  }, [value, planningMode]);

  const [hoverModifier, setHoverModifier] = useState(null);

  const handleDayEnter = (date) => {
    setHoverModifier(
      planningMode === "week" ? getWeekRange(date) : getMonthRange(date),
    );
  };

  const handleDayLeave = () => {
    setHoverModifier(null);
  };

  const handleRangeDayClick = (date) => {
    onChange(
      planningMode === "week"
        ? moment(date).startOf("week").format("YYYY-MM-DD")
        : moment(date).startOf("month").format("YYYY-MM-DD"),
    );
  };

  switch (planningMode) {
    case "week":
    case "month": {
      return (
        <RangeDatePicker
          value={rangeValue}
          onChange={() => {}}
          modifiers={{
            hover: hoverModifier,
            selected: null,
            disabled: {
              before:
                planningMode === "week"
                  ? moment().startOf("week").toDate()
                  : moment().startOf("month").toDate(),
            },
          }}
          onDayClick={handleRangeDayClick}
          onDayMouseEnter={handleDayEnter}
          onDayMouseLeave={handleDayLeave}
        />
      );
    }
    default: {
      return (
        <SingleDatePicker
          value={value}
          onChange={onChange}
          modifiers={{
            disabled: {
              before: new Date(),
            },
          }}
        />
      );
    }
  }
};

const DatePickerField = ({ planningMode }) => {
  const {
    input: { onChange, value },
  } = useField("publicationDate.plannedDate");

  return (
    <SingleOrRangeDatePicker
      planningMode={planningMode}
      onChange={onChange}
      value={value}
    />
  );
};

const RangePickerField = ({ plannedDate }) => {
  const items = useSlotItems({ plannedDate });

  const {
    input: { value, onChange },
  } = useField("publicationDate.plannedRange", {
    format: (value) => items.find((range) => range.from === value) ?? null,
    parse: (value) => {
      return value?.from || null;
    },
  });

  const enumSelect = useEnumSelectState({
    value: value || null,
    onChange,
    items,
    labelSelector: (range) =>
      range ? `${range.from.split(":")[0]}h - ${range.to.split(":")[0]}h` : "",
    valueSelector: (range) => range?.from,
    required: true,
  });

  return (
    <EnumSelect
      state={enumSelect}
      aria-label="Sélecteur créneau horaire"
      placeholder="Sélectionner un créneau..."
    />
  );
};

const planningModes = [
  { name: "Jour", value: "day" },
  { name: "Semaine", value: "week" },
  { name: "Mois", value: "month" },
];

const PlanningModeSelectField = () => {
  const {
    input: { value, onChange },
  } = useField("publicationDate.planningMode", {
    format: (value) => planningModes.find((mode) => mode.value === value),
    parse: (value) => value.value,
  });

  const enumSelect = useEnumSelectState({
    value,
    onChange,
    items: planningModes,
    labelSelector: (mode) => mode.name,
    valueSelector: (mode) => mode.value,
    required: true,
  });

  return <EnumSelect state={enumSelect} aria-label="Mode de planification" />;
};

export const ArticleDateFormFields = () => {
  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });

  const { publicationSlots } = useRemoteConfig();

  const { dated, plannedDate, plannedTime, planningMode } =
    values.publicationDate;

  const disabledTime =
    !plannedDate || planningMode === "week" || planningMode === "month";

  return (
    <div style={{ width: 474 }}>
      <DatedSwitchField />
      {dated && (
        <div className="flex flex-row justify-between p-4">
          <div className="mt-1.5 flex flex-col gap-1">
            <DatePickerField planningMode={planningMode} />
            <PlanningModeSelectField />
          </div>
          <div className="w-60 pl-3 pr-1">
            <TimeRadioFields
              disabled={disabledTime}
              plannedTime={plannedTime}
              plannedDate={plannedDate}
              useRangeOption={Boolean(publicationSlots?.length)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const parseDateValues = ({
  publicationDate: {
    dated,
    plannedDate,
    plannedTime,
    plannedRange,
    planningMode,
    timingMode,
    isEmbargo,
  },
}) => {
  if (!dated || !plannedDate) {
    return {
      planning: {
        date: null,
        range: null,
      },
      isUrgent: false,
      isEmbargo: false,
    };
  }
  if (["week", "month"].includes(planningMode)) {
    return {
      planning: {
        date: mTz(plannedDate),
        range: planningMode,
      },
      isUrgent: false,
      isEmbargo: false,
    };
  }

  let date = mTz(plannedDate);
  if (plannedTime && timingMode === "time") {
    const [startHours, startMinutes] = plannedTime.split(":");
    date.set("hour", startHours);
    date.set("minute", startMinutes);
  }
  if (plannedRange && timingMode === "range") {
    const [startHours, startMinutes] = plannedRange.split(":");
    date.set("hour", startHours);
    date.set("minute", startMinutes);
  }

  const range = (() => {
    switch (timingMode) {
      case "daily":
        return "day";
      case "range":
        return "hour";
      case "time":
      case "asap":
      default:
        return null;
    }
  })();

  return {
    planning: {
      date,
      range,
    },
    isUrgent: timingMode === "asap",
    isEmbargo: timingMode === "time" ? isEmbargo : false,
  };
};

const isBefore = (values) => {
  if (values.publicationDate.planningMode !== "day") return false;
  if (!moment(values.publicationDate.plannedDate).isSame(moment(), "day")) {
    return false;
  }
  if (values.publicationDate.timingMode === "time") {
    return values.publicationDate.plannedTime
      ? moment(values.publicationDate.plannedTime, "HH:mm:ss").isBefore(
          moment(),
        )
      : false;
  }
  if (values.publicationDate.timingMode === "range") {
    return values.publicationDate.plannedRange
      ? moment(values.publicationDate.plannedRange, "HH:mm:ss").isBefore(
          moment(),
        )
      : false;
  }
  return false;
};

const ListenChanges = memo(({ onChange }) => {
  const { values, initialValues } = useFormState({
    subscription: {
      values: true,
      initialValues: true,
    },
  });

  const items = useSlotItems({
    plannedDate: values.publicationDate.plannedDate,
  });
  const form = useForm();
  const {
    publicationDate: { plannedDate: initialPlannedDate },
  } = initialValues;

  useEffect(() => {
    form.batch(() => {
      if (isBefore(values)) {
        form.change("publicationDate.plannedTime", null);
        if (
          !moment(initialPlannedDate).isSame(moment(), "day") &&
          values.publicationDate.timingMode === "range"
        ) {
          form.change("publicationDate.plannedRange", items[0].from);
        }
      }
    });
  }, [form, values, initialPlannedDate, items]);

  const onChangeRef = useLiveRef(onChange);
  useEffect(() => {
    onChangeRef.current(values);
  }, [values, onChangeRef]);

  return null;
});

const getPlanningMode = (article) => {
  if (!article.planning?.range) return "day";
  if (article.planning.range === "hour") return "day";
  return article.planning.range;
};

const getTimingMode = (article) => {
  if (article.isUrgent) {
    return "asap";
  }
  if (article.planning?.range === "hour") {
    return "range";
  }

  if (article.planning?.range === "day") {
    return "daily";
  }

  return "time";
};

const getPlannedTime = (article) => {
  if (!article.planning?.date) return null;
  if (article.isUrgent) return null;
  if (["day", "week", "month"].includes(article.planning?.range)) return null;
  return moment(article.planning.date).tz("Europe/Paris").format("HH:mm:ss");
};

export const formatDateValues = (article) => {
  const timingMode = getTimingMode(article);

  return {
    publicationDate: {
      plannedDate: article.planning?.date
        ? moment(article.planning.date).tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      dated: Boolean(article.planning?.date),
      planningMode: getPlanningMode(article),
      timingMode: getTimingMode(article),
      plannedTime: timingMode === "time" ? getPlannedTime(article) : null,
      plannedRange: timingMode === "range" ? getPlannedTime(article) : null,
      isEmbargo: article.isEmbargo,
    },
  };
};

export const ArticleDateForm = ({ article, onChange }) => {
  const initialValues = useMemo(() => formatDateValues(article), [article]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={() => {
        // Do nothing we don't have a submit button
        // we use form to continuously update using FormSpy
      }}
    >
      <ListenChanges
        onChange={(values) => {
          onChange(parseDateValues(values));
        }}
      />
      <ArticleDateFormFields />
    </Form>
  );
};

ArticleDateForm.fragments = {
  article: gql`
    fragment ArticleDateForm_article on Article {
      id
      isEmbargo
      isUrgent
      planning {
        date
        range
      }
    }
  `,
};
