import {
  DialogPanel,
  DialogPanelDisclosure,
  useDialogPanelState,
} from "swash/DialogPanel";
import {
  PanelBody,
  PanelHeader,
  PanelSection,
  PanelSectionArrow,
  PanelSectionHeader,
  PanelSectionTitle,
} from "swash/Panel";

import { ActivityHistory } from "../admin/CRUD/history/ActivityHistory";

export const ActivitySection = ({ title, ...props }) => {
  const dialog = useDialogPanelState();
  const activityTitle = title || "Activités";

  return (
    <>
      <DialogPanelDisclosure state={dialog}>
        {(disclosureProps) => (
          <PanelSection {...disclosureProps}>
            <PanelSectionHeader>
              <PanelSectionTitle>{activityTitle}</PanelSectionTitle>
              <PanelSectionArrow />
            </PanelSectionHeader>
          </PanelSection>
        )}
      </DialogPanelDisclosure>
      <ImageActivityDialog state={dialog} title={activityTitle} {...props} />
    </>
  );
};

const ImageActivityDialog = ({
  resource,
  resourceId,
  fieldsMap,
  state,
  title,
  noComment,
}) => {
  const visible = resourceId && (state.open || state.animating);
  if (!visible) return null;

  return (
    <DialogPanel
      className="absolute inset-y-0 right-0 z-dialog-panel"
      style={{ width: 424 }}
      fullScreen={false}
      state={state}
    >
      <PanelHeader title={title} back="Retour" onClose={state.hide} />
      <PanelBody>
        <ActivityHistory
          resource={resource}
          resourceId={resourceId}
          fieldsMap={fieldsMap}
          term={{
            article: "une",
            specificArticle: "l’",
            singular: "image",
            plural: "images",
          }}
          noComment={noComment}
        />
      </PanelBody>
    </DialogPanel>
  );
};
