import * as React from "react";

// This is a generic factory to create set from a linked state components
// See https://codesandbox.io/s/linked-state-component-set-9b96h
export function createSetFactory({ useState, components }) {
  return () => {
    const Context = React.createContext();

    function Provider({ options, children }) {
      const state = useState(options);
      return <Context.Provider value={state}>{children}</Context.Provider>;
    }

    function useContext() {
      return React.useContext(Context);
    }

    function connect(Component) {
      const ConnectedComponent = React.forwardRef((props, ref) => {
        const state = useContext();
        return <Component ref={ref} {...state} {...props} />;
      });
      return ConnectedComponent;
    }

    return {
      Provider,
      useContext,
      ...Object.entries(components).reduce(
        (obj, [name, Component]) => ({
          ...obj,
          [name]: connect(Component),
        }),
        {},
      ),
    };
  };
}

export function useDelayed({ skip = false } = {}) {
  const [ticked, setTicked] = React.useState(false);
  React.useEffect(() => {
    if (skip || ticked) return undefined;
    const id = requestAnimationFrame(() => setTicked(true));
    return () => cancelAnimationFrame(id);
  }, [skip, ticked]);
  return ticked;
}
