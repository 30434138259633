import { gql } from "@apollo/client";

import { PublishedLink, PublishedLinkProps } from "@/components/PublishedLink";

export type ArticlePublishedLinkProps = Omit<PublishedLinkProps, "url"> & {
  article: {
    url: string | null;
  };
};

export const ArticlePublishedLink = ({
  article,
  ...props
}: ArticlePublishedLinkProps) => {
  return <PublishedLink url={article.url} {...props} />;
};

ArticlePublishedLink.fragments = {
  article: gql`
    fragment ArticlePublishedLink_article on Article {
      url
    }
  `,
};
