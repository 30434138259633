// @ts-check

const type = "archives";
const ARCHIVE_REGEX = /^zip.\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])/i;

/** @type {import('.').MediaProvider["getData"]} */
function getData({ url }) {
  try {
    if (ARCHIVE_REGEX.test(url)) {
      return { url };
    }

    return null;
  } catch (error) {
    return null;
  }
}

/** @type {import('.').MediaProvider} */
export const provider = { type, getData };
