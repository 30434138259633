import clsx from "clsx";
import { HTMLAttributes, forwardRef } from "react";

export type CapsuleProps = HTMLAttributes<HTMLDivElement>;

export const Capsule = forwardRef<HTMLDivElement, CapsuleProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        data-testid="capsule"
        className={clsx(
          className,
          "group/capsule relative min-w-0 flex-1 shrink-0 select-none rounded border border-l-4 border-grey-border-light border-l-[var(--status-color)] bg-white p-2 shadow-card ring-primary-border/50 transition [[data-dragging=true]_&]:ring-2",
        )}
        {...props}
      />
    );
  },
);
