import { gql } from "@apollo/client";
import "moment/locale/fr";
import { memo } from "react";
import { createTeleporter } from "react-teleporter";
import { IoAlertCircle } from "swash/Icon";
import { Link } from "swash/Link";
import { useTabState } from "swash/Tab";
import { Tooltip } from "swash/Tooltip";

import { CopyButton } from "@/components/CopyButton";
import { WatchButton } from "@/components/WatchButton";
import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";
import { useConnection } from "@/containers/Ping";
import { useWatchState } from "@/containers/watch/WatchState";

import { RouteError } from "../Router";
import { ArticleImageIndicatorTeleporter } from "../article/ArticleImageEdit";
import { ImageFormContent } from "./ImageFormContent";
import { ImageFragment } from "./ImageFragments";

const ImageQuery = gql`
  query ImageForm_image($id: Int!) {
    image(id: $id) {
      id
      ...ImageForm_image
    }
  }
  ${ImageFragment}
`;

export const ImageNodeQuery = gql`
  query ImageNode_image($id: Int!) {
    node: image(id: $id) {
      id
      ...ImageForm_image
    }
  }
  ${ImageFragment}
`;

const UpdateImageMutation = gql`
  mutation ImageForm_updateImage($input: UpdateImageInput!) {
    updateImage(input: $input) {
      id
      ...ImageForm_image
    }
  }
  ${ImageFragment}
`;

const ExpiredIcon = memo(() => {
  return (
    <Tooltip tooltip="Droits expirés. Contactez le service photo.">
      <div className="inline-flex items-center" aria-label="Droits expirés">
        <IoAlertCircle className="inline-block text-danger-on-light" />
      </div>
    </Tooltip>
  );
});

export const IndicatorTeleporter = createTeleporter();

const ImageWatchButton = memo(({ imageId }) => {
  const watch = useWatchState(`image:${imageId}`);
  const connection = useConnection();
  return <WatchButton scale="sm" disabled={!connection.synced} {...watch} />;
});

const ImageForm = ({ imageId, data, children, ...props }) => {
  const [updateImage] = useSafeMutation(UpdateImageMutation);
  const tab = useTabState({
    defaultSelectedId: props.editor ? "article" : "image",
    variant: "bar",
  });
  const selectedId = tab.store.useState("selectedId");

  return (
    <div className="contents">
      {children({
        title: `Image #${imageId}`,
        titleLink: (
          <Link inherit href={data?.image.siriusUrl ?? "#"} target="_blank">
            Image #{imageId}
          </Link>
        ),
        actions: (
          <>
            <ImageWatchButton imageId={imageId} />
            <CopyButton
              value={data?.image.siriusUrl ?? ""}
              title="Copier l’URL de l’image"
              scale="sm"
              variant="secondary"
            />
            {data?.image.expired && <ExpiredIcon />}
            {selectedId === "article" ? (
              <ArticleImageIndicatorTeleporter.Target />
            ) : (
              <IndicatorTeleporter.Target />
            )}
          </>
        ),
        body: (
          <ImageFormContent
            imageId={imageId}
            data={data}
            updateImage={updateImage}
            tab={tab}
            {...props}
          />
        ),
      })}
    </div>
  );
};

const ImageFormDataLoader = ({ imageId, ...props }) => {
  const { data } = useSafeQuery(ImageQuery, {
    variables: { id: imageId },
  });

  if (!data) return null;

  if (!data.image) {
    throw new RouteError("Image not found", 404);
  }

  return <ImageForm imageId={imageId} data={data} {...props} />;
};

export { ImageFormDataLoader as ImageForm };
