import { gql } from "@apollo/client";
import clsx from "clsx";
import { memo } from "react";

export type ArticleConfidentialLabelProps = {
  article: {
    isConfidential: boolean;
  };
} & { className?: string };

type SpreadArticleConfidentialLabelProps =
  ArticleConfidentialLabelProps["article"] & { className?: string };

export const SpreadArticleConfidentialLabel =
  memo<SpreadArticleConfidentialLabelProps>(({ isConfidential, className }) => {
    if (!isConfidential) return null;
    return (
      <span
        className={clsx(
          className,
          "select-none font-accent font-semibold uppercase text-red-on",
        )}
      >
        Confidentiel
      </span>
    );
  });

export const ArticleConfidentialLabel = ({
  article,
  ...props
}: ArticleConfidentialLabelProps) => {
  return (
    <SpreadArticleConfidentialLabel
      isConfidential={article.isConfidential}
      {...props}
    />
  );
};

ArticleConfidentialLabel.fragments = {
  article: gql`
    fragment ArticleConfidentialLabel_article on Article {
      isConfidential
    }
  `,
};
