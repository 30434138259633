import { forwardRef } from "react";

import { Textarea } from "@/components/controls/Textarea";
import { FieldControl } from "@/components/fields/FieldControl";

import { useTextInputField } from "./TextInputField";

export const useTextareaField = useTextInputField;

export const TextareaField = forwardRef(({ ...props }, ref) => {
  return <FieldControl ref={ref} as={Textarea} {...props} />;
});
