import { gql } from "@apollo/client";

import {
  ArticleDateEditor,
  ArticleDateEditorProps,
} from "@/containers/article/ArticleDateEditor";
import type { CellType } from "@/containers/list/ListState";

export type DateCellArticle = ArticleDateEditorProps["article"];

export const dateCell: CellType<DateCellArticle> = {
  key: "date",
  title: "Date de publication",
  width: 110,
  cellPadding: "0.25rem 0",
  render: ({ node }) => <ArticleDateEditor article={node} />,
  fragment: gql`
    fragment dateCell_article on Article {
      ...ArticleDateEditor_article
    }

    ${ArticleDateEditor.fragments.article}
  `,
};
