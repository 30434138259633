import { gql } from "@apollo/client";
import moment from "moment";
import "moment/locale/fr";
import { PageLoader } from "swash/Loader";

import { useSafeQuery } from "@/containers/Apollo";

import { AnalyticsGroup, AnalyticsRow } from "./Group";

const SiriusAnalyticsQuery = gql`
  query SiriusAnalyticsQuery($articleId: Int!) {
    article(id: $articleId) {
      id
      alerts(limit: 1) {
        nodes {
          id
          title
          publishedAt
        }
      }
    }
    tags(where: { homepage: true }) {
      nodes {
        id
        title
        stagingAreas(activatedOnly: true, acceptsArticleOnly: true) {
          id
          label
          articleExposurePeriods(articleId: $articleId) {
            from
            to
          }
        }
      }
    }
  }
`;

function formatAlertTime(date) {
  if (!date) return null;
  return `Le ${moment(date).format("DD/MM [à] HH:mm")}`;
}

const currentYear = new Date().getFullYear();
const expositionTimeFormat = "DD/MM [à] HH[:]mm";
const expositionTimeFormatWithYear = "DD/MM/YY [à] HH[:]mm";

function getDisplayTimeFormat(time) {
  return currentYear === moment(time).year()
    ? expositionTimeFormat
    : expositionTimeFormatWithYear;
}

function formatExpositionsTime(articleExposurePeriod) {
  const events = articleExposurePeriod.map(({ to, from }) => {
    const fromFormat = getDisplayTimeFormat(from);
    if (!to) {
      return `Depuis le ${moment(from).format(fromFormat)}`;
    }
    const toFormat = getDisplayTimeFormat(to);
    return `Du ${moment(from).format(fromFormat)} ${moment(to).format(
      `[au] ${toFormat}`,
    )}`;
  });

  return events.map((event) => {
    return <div key={event}>{event}</div>;
  });
}

function Expositions({ lastAlert, tags }) {
  return (
    <AnalyticsGroup title="Expositions">
      <AnalyticsRow
        label="Alerte"
        value={formatAlertTime(lastAlert?.publishedAt)}
      />
      {tags?.map((tag) => {
        return tag.stagingAreas.map((stagingArea) => {
          return (
            <AnalyticsRow
              key={stagingArea.id}
              label={`${stagingArea.label} (${tag.title})`}
              value={
                stagingArea.articleExposurePeriods.length
                  ? formatExpositionsTime(stagingArea.articleExposurePeriods)
                  : null
              }
            />
          );
        });
      })}
    </AnalyticsGroup>
  );
}

export function SiriusAnalytics({ articleId }) {
  const { data } = useSafeQuery(SiriusAnalyticsQuery, {
    variables: { articleId },
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });
  if (!data) {
    return <PageLoader />;
  }

  return (
    <Expositions
      lastAlert={data.article.alerts.nodes[0] ?? null}
      tags={data.tags.nodes}
    />
  );
}
