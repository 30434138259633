import styled from "@xstyled/styled-components";

export const ModernCapsuleLimit = styled.hr`
  position: absolute;
  display: block;
  width: 100%;
  margin: 0;
  border: 0;
  border-bottom: 1px dashed;
  border-bottom-color: grey;

  &[data-position="top"] {
    top: -8px;
  }
  &[data-position="bottom"] {
    bottom: -9px;
  }
`;
