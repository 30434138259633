/* eslint-disable react/prop-types */
import styled, { keyframes, th } from "@xstyled/styled-components";
import { forwardRef, useEffect, useState } from "react";
import { usePrevious } from "swash/utils/usePrevious";

import { createSetFactory } from "./util";

const InnerList = styled.box`
  display: table;
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0;
`;

export const List = forwardRef(({ state, ...props }, ref) => {
  return <InnerList ref={ref} role="table" {...props} />;
});

const InnerListHeader = styled.box`
  display: table-row;
`;

export const ListHeader = forwardRef(({ state, ...props }, ref) => {
  return <InnerListHeader ref={ref} role="row" {...props} />;
});

const InnerListHeaderCell = styled.box`
  line-height: 1;
  padding-bottom: 2;
  padding-right: 1;
  font-family: accent;
  font-size: xs;
  display: table-cell;
`;

export const ListHeaderCell = forwardRef(({ state, colId, ...props }, ref) => {
  return (
    <InnerListHeaderCell
      ref={ref}
      role="columnheader"
      {...state?.columns[colId]?.props}
      {...props}
    />
  );
});

const appear = keyframes`
  from {
    opacity: 0.5;
    transform: translateX(-4px);
  }

  to {
    opacity: 1;
    transform: none;
  }
`;

const InnerListBody = styled.box`
  display: table-row-group;
  border-radius: base;
  box-shadow: card;
  transition: base;

  &[data-clickable] {
    cursor: pointer;

    &:hover {
      box-shadow:
        ${th.shadow("card")},
        0 0 1px 1px ${th.color("grey-lighter")};
    }
  }

  &[data-active] {
    background-color: primary-lightest;
    box-shadow:
      ${th.shadow("card")},
      0 0 0 1px ${th.color("primary")};
  }

  &[data-enter-active] {
    animation: ${appear} 250ms ease-in-out forwards;
  }

  &[aria-expanded="true"],
  &[aria-expanded="true"]:hover {
    box-shadow:
      ${th.shadow("card")},
      0 0 0 1px ${th.color("primary")};
  }
  border-top: ${({ version }) => (version === 2 ? "1px solid" : "0")};
  border-color: layout-border;
`;

export const ListBody = forwardRef(
  ({ state, active, onClick, ...props }, ref) => {
    // When activated, we animate it
    const [activated, setActivated] = useState(active);
    const previousActive = usePrevious(active);
    useEffect(() => {
      if (previousActive === undefined) return undefined;
      if (!previousActive && active) {
        setActivated(true);
        const timeout = setTimeout(() => setActivated(false), 300);
        return () => clearTimeout(timeout);
      }
      return undefined;
    }, [previousActive, active]);

    return (
      <InnerListBody
        ref={ref}
        role="rowgroup"
        tabIndex={onClick ? 0 : undefined}
        data-active={active ? "" : undefined}
        data-enter-active={activated ? "" : undefined}
        data-clickable={onClick ? "" : undefined}
        onClick={onClick}
        {...props}
      />
    );
  },
);

const InnerListRow = styled.box`
  display: table-row;
  transition: base;

  /* first row, first column, last cell */
  &:first-child,
  &:first-child > *:first-child {
    border-top-left-radius: ${th.radius("base")};
  }

  /* first row,  last column, last cell */
  &:first-child,
  &:first-child > *:last-child {
    border-top-right-radius: ${th.radius("base")};
  }

  /* last row, first column, last cell */
  &:last-child,
  &:last-child > *:first-child {
    border-bottom-left-radius: ${th.radius("base")};
  }

  /* last row, last column, last cell */
  &:last-child,
  &:last-child > *:last-child {
    border-bottom-right-radius: ${th.radius("base")};
  }

  &:last-child > * {
    border-bottom: ${({ version }) => (version === 2 ? "1px solid" : "0")};
    border-color: layout-border;
  }

  &:first-child > * {
    border-color: layout-border;
    border-top: ${({ version }) => (version === 2 ? "1px solid" : "0")};
  }

  &:nth-child(odd) {
    background-color: white;
  }

  &:nth-child(even) {
    background-color: grey-lightest;
  }

  ${InnerListBody}[data-active] & {
    background-color: primary-lightest;
  }

  ${InnerListBody}[data-clickable]:hover & {
    background-color: grey-lightest;
  }

  ${InnerListBody}[aria-expanded='true'] &,
  ${InnerListBody}[aria-expanded='true']:hover & {
    background-color: primary-lightest;
  }
`;

export const ListRow = forwardRef(({ state, active, ...props }, ref) => {
  return <InnerListRow ref={ref} role="row" {...props} />;
});

const InnerListCell = styled.box`
  display: table-cell;
  padding: 3 2;
  transition: base;

  border-bottom: 1;
  border-right: 1;
  border-color: layout-border;
  border-left: ${({ version }) => (version === 2 ? "1px solid" : "0")};

  &:first-child {
    border-left: ${({ version }) => (version === 2 ? "1px solid" : "0")};
  }

  &:last-child {
    border-right: ${({ version }) => (version === 2 ? "1px solid" : "0")};
    border-color: layout-border;
  }

  &[role="button"] {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
    box-shadow: cell-focus;
  }

  &[data-invalid="true"] {
    border: 1;
    border-color: danger-dark;
  }
`;

export const ListCell = forwardRef(
  ({ state, colId, children, invalid, ...props }, ref) => {
    return (
      <InnerListCell
        ref={ref}
        role="cell"
        data-invalid={invalid}
        {...state?.columns[colId]?.props}
        {...props}
      >
        {children}
      </InnerListCell>
    );
  },
);

export function useListState({ columns = {} } = {}) {
  return { state: { columns } };
}

export const createListSet = createSetFactory({
  useState: useListState,
  components: {
    List,
    ListHeader,
    ListHeaderCell,
    ListBody,
    ListRow,
    ListCell,
  },
});
