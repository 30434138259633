import clsx from "clsx";
import { forwardRef } from "react";
import { Switch } from "swash/controls/Switch";
import { FormLabel } from "swash/form/FormLabel";

export const DatedSwitch = forwardRef(({ checked, onChange }, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(
        "flex items-center gap-4 px-4 py-3",
        checked ? "bg-primary-bg-light" : "bg-grey-bg-light",
      )}
    >
      <Switch checked={checked} onChange={onChange} aria-label="Daté" />
      <FormLabel>{checked ? "Daté" : "Non daté"}</FormLabel>
    </div>
  );
});
