import { x } from "@xstyled/styled-components";
import { forwardRef } from "react";

export const FormFooter = forwardRef((props, ref) => (
  <x.div ref={ref} m={-2} row alignItems="center" {...props} />
));

export const FormFooterSide = forwardRef((props, ref) => (
  <x.div ref={ref} p={2} col {...props} />
));

export const FormFooterActions = forwardRef((props, ref) => (
  <x.div ref={ref} p={2} col="auto" {...props} />
));
