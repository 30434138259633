import { gql } from "@apollo/client";
import clsx from "clsx";
import { Fragment, ReactElement } from "react";
import { Link } from "swash/Link";

import { useUser } from "@/containers/User";
import {
  UserHoverCardAvatar,
  UserHoverCardTooltip,
  UserHoverCardUser,
} from "@/containers/user/UserHoverCard";

type MentionData = {
  id: number;
  __typename: string;
};

type Mention = {
  blockIndex: number;
  startOffset: number;
  endOffset: number;
  data: MentionData;
};

type Comment = {
  value: string;
  mentions: Mention[];
};

type CommentProps = {
  comment: Comment;
  className?: string;
};

const CommentMentionFragment = gql`
  fragment Comment_commentMention on CommentMention {
    blockIndex
    startOffset
    endOffset
    data {
      ... on User {
        id
        ...UserHoverCardAvatar_user
      }
    }
    mutability
  }
  ${UserHoverCardAvatar.fragments.user}
`;

const sliceUrl = (url: string) => {
  const start = url.slice(0, 25);
  const end = url.slice(-15);
  return `${start}[...]${end}`;
};

const URL_REGEX = /((?:http|https):\/\/(?:\S+))/g;

const LinkDecorator = ({ text }: { text: string }) => {
  if (!text) return null;
  const textArray = text.split(URL_REGEX);
  return (
    <>
      {textArray.map((str, index) => {
        if (URL_REGEX.test(str)) {
          const link = str.length > 46 ? sliceUrl(str) : str;
          return (
            <Link key={index} target="_blank" href={str}>
              {link}
            </Link>
          );
        }
        return index === textArray.length - 1 ? str : `${str} `;
      })}
    </>
  );
};

/**
 * Display comment with mentions
 */
export const Comment = ({ comment, className }: CommentProps): ReactElement => {
  const { value, mentions } = comment;
  const currentUser = useUser();

  return (
    <p className={clsx("whitespace-pre-wrap text-sm", className)}>
      {value.split("\n").map((block, index) => {
        const blockMentions = mentions.filter(
          ({ blockIndex }) => blockIndex === index,
        );

        if (!blockMentions.length) {
          return (
            <Fragment key={index}>
              <LinkDecorator text={block} />
              {"\n"}
            </Fragment>
          );
        }

        const textAfterMention = block.substring(
          // blockMentions[blockMentions.length - 1] should not be undefined here , make ts happy
          blockMentions[blockMentions.length - 1]?.endOffset ?? 0,
          block.length,
        );

        return (
          <Fragment key={index}>
            {blockMentions.map((mention, index) => {
              const { startOffset, endOffset, data } = mention;
              const text = block.substring(startOffset, endOffset);

              const textBeforeMention = block.substring(
                blockMentions[index - 1]?.endOffset ?? 0,
                startOffset,
              );

              return (
                <Fragment key={index}>
                  <LinkDecorator text={textBeforeMention} />
                  <UserHoverCardTooltip
                    user={data as unknown as UserHoverCardUser}
                  >
                    <span
                      className={clsx(
                        "rounded px-1 font-semibold",
                        data?.id
                          ? data.id === currentUser.id
                            ? "bg-orange-bg-light text-orange-on-strong"
                            : "bg-primary-bg-light text-primary-on-strong"
                          : "bg-secondary-bg-light text-secondary-on-strong line-through",
                      )}
                    >
                      {text}
                    </span>
                  </UserHoverCardTooltip>
                </Fragment>
              );
            })}
            <LinkDecorator text={textAfterMention} />
            {"\n"}
          </Fragment>
        );
      })}
    </p>
  );
};

Comment.fragments = {
  comment: gql`
    fragment Comment_comment on Comment {
      value
      mentions {
        ...Comment_commentMention
      }
    }
    ${CommentMentionFragment}
  `,
};
