import { DocumentNode, gql } from "@apollo/client";
import { FC } from "react";

import { Time } from "@/components/Time";
import {
  ArticlePublishStatusProps,
  ArticlePublishedStatus,
} from "@/containers/article/ArticlePublishStatus";

export type ArticleInitialPublishDateProps = {
  article: {
    initialFirstPublished: string;
    status: "published" | "published_as_new_content" | "unpublished";
    publishInfos: ArticlePublishStatusProps["article"]["publishInfos"];
  };
};

export const ArticleInitialPublishDate: FC<ArticleInitialPublishDateProps> & {
  fragments: { article: DocumentNode };
} = ({ article }) => {
  return (
    <ArticlePublishedStatus
      {...article}
      status={
        article.publishInfos.lastPublishedAsNewContentIn
          ? "published_as_new_content"
          : "published"
      }
      style={{ width: 12, height: 12 }}
    >
      <Time
        className="text-xs font-semibold capitalize text-secondary-on"
        date={article.initialFirstPublished}
        format="ddd D MMMM | HH:mm"
      />
    </ArticlePublishedStatus>
  );
};

ArticleInitialPublishDate.fragments = {
  article: gql`
    fragment ArticleInitialPublishDate_article on Article {
      initialFirstPublished
      ...ArticlePublishedStatus_article
    }
    ${ArticlePublishedStatus.fragments.article}
  `,
};
