import { Button } from "swash/Button";
import { DialogDisclosure, useDialogState } from "swash/Dialog";

import { ArticleEventDispatcherConfirmDialog } from "./ArticleEventDispatcherConfirmDialog";

export const EventDispatcherButton = ({
  eventDispatcher,
  disabled,
  label,
  articleId,
  scale,
}) => {
  const dialog = useDialogState();
  return (
    <>
      <DialogDisclosure as={Button} disabled={disabled} state={dialog}>
        {(disclosureProps) => (
          <Button {...disclosureProps} scale={scale}>
            {label}
          </Button>
        )}
      </DialogDisclosure>
      <ArticleEventDispatcherConfirmDialog
        state={dialog}
        eventDispatcher={eventDispatcher}
        disabled={disabled}
        articleId={articleId}
      />
    </>
  );
};
