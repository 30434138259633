import { forwardRef } from "react";
import { FormLabel } from "swash/form/FormLabel";

export const FieldLabel = forwardRef(
  ({ state, children, onClick, ...props }, ref) => {
    const htmlFor = state.id;
    return (
      <FormLabel
        ref={ref}
        data-field-label
        id={state.labelId}
        htmlFor={htmlFor}
        onClick={(event) => {
          if (onClick) onClick(event);
          if (event.defaultPrevented) return;
          if (htmlFor) {
            const element = document.getElementById(htmlFor);
            if (element) {
              element.focus();
            }
          }
        }}
        {...props}
      >
        {children}
        {state.required ? <span title="Requis">*</span> : null}
      </FormLabel>
    );
  },
);
